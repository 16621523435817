export type ScrollPosition = {
  x: number;
  y: number;
  timestamp: number;
};

export type ScrollStore = {
  savedPositions: Record<string, ScrollPosition>;
};

export const useScrollStore = defineStore('scrollStore', () => {
  const state = reactive<ScrollStore>({
    savedPositions: {},
  });

  const generateKey = (
    routeName: string,
    params?: Record<string, string>
  ): string => {
    if (!params || isEmpty(Object.keys(params).length)) {
      return routeName;
    }

    const sortedParams = Object.entries(params)
      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
      .map(([key, value]) => `${key}:${value}`)
      .join('|');

    return `${routeName}|${sortedParams}`;
  };

  const savePosition = (
    routeName: string,
    position: { x: number; y: number },
    params?: Record<string, string>
  ): void => {
    const key = generateKey(routeName, params);
    state.savedPositions[key] = {
      ...position,
      timestamp: Date.now(),
    };
  };

  const getPosition = (routeName: string, params?: Record<string, string>) => {
    const key = generateKey(routeName, params);
    const saved = state.savedPositions[key];

    if (saved && Date.now() - saved.timestamp < 3600000) {
      // 1 hour expiry
      return { x: saved.x, y: saved.y };
    }
    return null;
  };

  return {
    // state
    state,

    // actions
    savePosition,
    getPosition,
  };
});
