import { ImgProxyService } from '@imx/imgproxy-vue';
import type { ImgProxyOptions } from '@imx/imgproxy-vue/src/lib/models/imgproxy-options.model';
import { WhitelabelAppType } from '@models/WhitelabelAppType';
import type { WhitelabelAppConfig } from '@models/WhitelabelAppConfig';

const determineImageProxyBase = (appType: WhitelabelAppType): string => {
  switch (appType) {
    case WhitelabelAppType.PORTAL:
      // portal is rendered on domains, where we can assume the /images-path is configured as proxy to imx.Image Cloud
      return '/images/';
    case WhitelabelAppType.WIDGET:
      // in widget mode, we need to read the base url from the environment (or fallback to a default [development mode])

      // --> development mode: https://whitelabel.infomax.dev
      // --> production mode: read from environment

      // eslint-disable-next-line no-case-declarations
      const whitelabelBaseUrl =
        window?.__IMXPLATFORM__WIDGET__?.widgetBaseUrl ??
        'https://images.infomax.online/'; /* safe default for images */
      return whitelabelBaseUrl + 'images/';
  }
};

export const createImageProxyService = () => {
  // TODO why is useAppConfig not available within (statically compiled) storybook? why is nuxt not initialized?
  const appConfig = useAppConfig() as WhitelabelAppConfig;

  const options: ImgProxyOptions = {
    imageProxyBase: determineImageProxyBase(appConfig.appType),
    signingKey:
      'efc16fc4504f40ff17eaca8abd639e81d313c7a3957d522cbecffda3c57cee088713e78a8ea573a9d4c6fbfb83d3f6bd703c769be0db9c30fc33db736691e716',
    signingSalt:
      'daacd4c60f7c80590d926c8629f1b55b4895dba0b1bb9c2987a9f349df092199415c657adbb44a3720621b93ae2f5304074492f11c6a2cabac30b1d4017c3edc',
  };

  return new ImgProxyService(options);
};
