/**
 * whitelabel
 *
 * LICENSE
 *
 * This software and its source code is protected by copyright law (Sec. 69a ff. UrhG).
 * It is not allowed to make any kinds of modifications, nor must it be copied,
 * or published without explicit permission. Misuse will lead to persecution.
 *
 * @copyright  2025 infomax websolutions GmbH
 * @link       https://www.infomax-online.de
 * @author     Florian Müller <mueller@infomax-it.de>
 * @since      24.03.25
 */

export function loadScript(src: string): Promise<void> {
  return new Promise((resolve, reject) => {
    let script = document.querySelector(`script[src="${src}"]`);

    if (script?.hasAttribute('data-loaded')) {
      resolve();
      return;
    } else if (!script) {
      script = document.createElement('script');
      (script as HTMLScriptElement).src = src;
      document.head.appendChild(script);
    }

    script.addEventListener('error', reject);
    script.addEventListener('abort', reject);
    script.addEventListener('load', () => {
      script.setAttribute('data-loaded', '');
      resolve();
    });
  });
}

export function injectInlineScript(scriptSrc: string): Promise<void> {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    (script as HTMLScriptElement).text = scriptSrc;
    document.head.appendChild(script);
    resolve();
  });
}
