<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { WhlConsentManagerType } from '../../models/WhlConsentManagerType';
import {
  ConsentManagerSymbol,
  createConsentManager,
} from '../../utils/consent-manager';

// initialize consent management
const instanceConfig = useWhlInstanceConfig();
const consentManager = createConsentManager(
  toValue(instanceConfig).portalConsentManager ?? WhlConsentManagerType.NULL,
  toValue(instanceConfig).consentManagerConfiguration
);
provide(ConsentManagerSymbol, consentManager);

if (import.meta.client) {
  await consentManager.init();
}
</script>

<style lang="css">
/* beware: transition styles must be global and do not work, if they are scoped */

.page-enter-active,
.page-leave-active {
  transition: all 70ms;
}
.page-enter-from,
.page-leave-to {
  opacity: 0.1;
}
</style>
