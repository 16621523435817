export enum BaseLocationType {
  ADDRESS_POI_GROUP = 'AddressPoiGroup',
  LOCATION = 'Location',
  REGION = 'Region',
}

export type BaseLocation = {
  _entityType: string;
  id: number;
  i18nName: string;
};

export type Location = BaseLocation & {
  name: string;
};
