import { WhlConsentManagerType } from '@models/WhlConsentManagerType';
import { injectInlineScript, loadScript } from '@utils/dom/script-util';

// Extend the Window interface to include RND property
declare global {
  interface Window {
    RND?: {
      CMP?: {
        initialize: (config: unknown) => void;
        openPrivacyManager: (id?: number) => void;
        consentedVendors: string[];
      };
    };
    __tcfapi?: (
      command: string,
      version: number,
      callback: (result: unknown) => void,
      parameter?: unknown
    ) => void;
  }
}

export const ConsentManagerSymbol: unique symbol = Symbol('ConsentManager');

export enum Vendor {
  MAPTOOLKIT,
  YOUTUBE,
}

const vendorData: Record<Vendor, { name: string; id: string; policy: string }> =
  {
    [Vendor.MAPTOOLKIT]: {
      name: 'Toursprung GmbH (Maptoolkit)',
      id: '6798a28f7964a505ba6893a8',
      policy: '',
    },
    [Vendor.YOUTUBE]: {
      name: 'YouTube',
      id: '5e7ac3fae30e7d1bc1ebf5e8',
      policy: 'https://policies.google.com/privacy',
    },
  };

export interface ConsentManager {
  init(): Promise<void>;

  /** Checks if the vendor is approved by the user */
  isVendorAvailable(vendor: Vendor): Promise<boolean>;

  /** Waits until the vendor is approved by the user */
  waitForVendor(vendor: Vendor): Promise<void>;

  /** Asks the user to approve the vendor. In most cases just the overlay will
   * be opened. The method itself should not change anything, but may trigger
   * the waiting promisses from waitForVendor (depend on implementation) */
  askForVendor(vendor: Vendor): void;
}

export type ConsentManagerConfig = RNDConsentManagerConfig;

/**
 * Null Consent Manager used for widgets (or by default).
 */
class NullConsentManager implements ConsentManager {
  init(): Promise<void> {
    // do nothing
    return Promise.resolve();
  }

  isVendorAvailable(): Promise<boolean> {
    // every vendor is always available
    return Promise.resolve(true);
  }

  waitForVendor(): Promise<void> {
    // every vendor is always available
    return Promise.resolve();
  }

  askForVendor(vendor: Vendor): void {
    console.log('Ask for vendor', vendorData[vendor].name);
  }
}

/**
 * Internal Consent Manager used e.g. in storybook.
 */
class InMemoryConsentManager implements ConsentManager {
  private readonly enabledVendors: Vendor[] = [];
  private readonly vendorPromieses: {
    [key: string]: ((value: void | PromiseLike<void>) => void)[];
  } = {};

  init(): Promise<void> {
    // do nothing
    return Promise.resolve();
  }

  isVendorAvailable(vendor: Vendor): Promise<boolean> {
    console.log(this.enabledVendors);
    console.log(this.enabledVendors.includes(vendor));
    return Promise.resolve(this.enabledVendors.includes(vendor));
  }

  waitForVendor(vendor: Vendor): Promise<void> {
    const vendorId: string = vendorData[vendor].id;
    if (this.enabledVendors.includes(vendor)) {
      return Promise.resolve();
    }
    const promise = new Promise<void>((resolve) => {
      if (!this.vendorPromieses[vendorId]) {
        this.vendorPromieses[vendorId] = [];
      }
      this.vendorPromieses[vendorId].push(resolve);
    });
    return promise;
  }

  askForVendor(vendor: Vendor): void {
    const vendorId: string = vendorData[vendor].id;
    const vendorName: string = vendorData[vendor].name;
    if (confirm('Ask for vendor ' + vendorName)) {
      this.enabledVendors.push(vendor);
      this.vendorPromieses[vendorId]?.forEach(
        (p: (value: void | PromiseLike<void>) => void) => p()
      );
    }
  }
}

/**
 * Customer specific consent manager (RND).
 */
export interface RNDConsentManagerConfig {
  debug?: boolean;
  enableEmbedConsent?: boolean;
  privacyLink?: string;
  privacyManagerId?: number;
  sp?: {
    config?: {
      accountId?: number;
      baseEndpoint?: string;
      joinHref?: boolean;
      gdpr?: Record<string, unknown>;
      targetingParams?: Record<string, string>;
      propertyHref?: string;
      propertyId?: number;
    };
  };
  allowList?: string[];
}

class RNDConsentManagementPlatform implements ConsentManager {
  constructor(private readonly config: RNDConsentManagerConfig) {}

  async init(): Promise<void> {
    try {
      await this.setup();
    } catch (error) {
      console.error('Error during setup:', error);
    }
    return Promise.resolve();
  }

  isVendorAvailable(vendor: Vendor): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const vendorId = vendorData[vendor].id;

      if (typeof window !== 'undefined' && window.RND && window.RND.CMP) {
        const isAvailable = window.RND.CMP.consentedVendors.includes(vendorId);
        resolve(isAvailable);
      }
    });
  }

  waitForVendor(vendor: Vendor): Promise<void> {
    return new Promise((resolve) => {
      const vendorId = vendorData[vendor].id;
      if (window?.RND?.CMP?.consentedVendors.includes(vendorId)) {
        resolve();
      }

      document.addEventListener(`cmp-consent-given-${vendorId}`, (e: Event) => {
        console.log('Consent event received:', e);
        resolve();
      });
    });
  }

  /** wrapper method to call the vendor specific consent manager ui */
  askForVendor(vendor: Vendor): void {
    console.log('Asking for vendor:', vendorData[vendor].name);
    if (window?.RND?.CMP) {
      window.RND.CMP.openPrivacyManager(this.config.privacyManagerId);
    }
  }

  private async setup(): Promise<void> {
    console.log('xxx RND ConsentManager SETUP');
    console.log('xxx RND ConsentManager inject scripts');
    await this.injectScripts();
    console.log('xxx RND ConsentManager apply consent attributes');
    this.applyConsentAttributes([Vendor.YOUTUBE, Vendor.MAPTOOLKIT]);
    console.log('xxx RND ConsentManager SETUP complete');
  }

  private injectScripts(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      injectInlineScript(
        `function _typeof(t){return(_typeof="function"==typeof Symbol&&"symbol"==typeof Symbol.iterator?function(t){return typeof t}:function(t){return t&&"function"==typeof Symbol&&t.constructor===Symbol&&t!==Symbol.prototype?"symbol":typeof t})(t)}!function(){for(var t,e,o=[],n=window,r=n;r;){try{if(r.frames.__tcfapiLocator){t=r;break}}catch(t){}if(r===n.top)break;r=n.parent}t||(function t(){var e=n.document,o=!!n.frames.__tcfapiLocator;if(!o)if(e.body){var r=e.createElement("iframe");r.style.cssText="display:none",r.name="__tcfapiLocator",e.body.appendChild(r)}else setTimeout(t,5);return!o}(),n.__tcfapi=function(){for(var t=arguments.length,n=new Array(t),r=0;r<t;r++)n[r]=arguments[r];if(!n.length)return o;"setGdprApplies"===n[0]?n.length>3&&2===parseInt(n[1],10)&&"boolean"==typeof n[3]&&(e=n[3],"function"==typeof n[2]&&n[2]("set",!0)):"ping"===n[0]?"function"==typeof n[2]&&n[2]({gdprApplies:e,cmpLoaded:!1,cmpStatus:"stub"}):o.push(n)},n.addEventListener("message",(function(t){var e="string"==typeof t.data,o={};if(e)try{o=JSON.parse(t.data)}catch(t){}else o=t.data;var n="object"===_typeof(o)?o.__tcfapiCall:null;n&&window.__tcfapi(n.command,n.version,(function(o,r){var a={__tcfapiReturn:{returnValue:o,success:r,callId:n.callId}};t&&t.source&&t.source.postMessage&&t.source.postMessage(e?JSON.stringify(a):a,"*")}),n.parameter)}),!1))}();`
      )
        .then(loadScript.bind(null, 'https://static.rndtech.de/cmp/2.x.x.js'))
        .then(() => {
          return new Promise<void>((resolve) => {
            if (typeof window !== 'undefined' && window.RND && window.RND.CMP) {
              window.RND.CMP.initialize({
                debug: this.config.debug,
                enableEmbedConsent: this.config.enableEmbedConsent,
                privacyLink: this.config.privacyLink,
                privacyManagerId: this.config.privacyManagerId,
                sp: {
                  config: {
                    // wird mit der Standard-Konfiguration zusammengeführt
                    baseEndpoint: this.config.sp?.config?.baseEndpoint, // überschreibt required Standard-Wert 'https://cdn.privacy-mgmt.com'
                    propertyHref: this.config.sp?.config?.propertyHref, // optional: nur für Testing
                    propertyId: this.config.sp?.config?.propertyId, // optional: Alternative zu propertyHref, direkt die ID der property in SourcePoint
                  },
                },
                allowList: this.config.allowList,
              });
            }
            resolve();
          });
        })
        .then(
          loadScript.bind(
            null,
            'https://cmp-sp.rnd.de/unified/wrapperMessagingWithoutDetection.js'
          )
        )
        .then(resolve)
        .catch(reject);
    });
  }

  private applyConsentAttributes(vendors: Vendor[]): void {
    vendors.forEach((vendor) => {
      if (vendor === Vendor.YOUTUBE) {
        this.applyEmbedConsentAttributes(vendor);
      }
    });
  }

  private applyScriptConsentAttributes(
    src: string,
    vendor: Vendor
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      let script = document.querySelector(`script[src="${src}"]`);

      if (!script) {
        script = document.createElement('script');
        (script as HTMLScriptElement).src = src;
        document.head.appendChild(script);
      }

      (script as HTMLScriptElement).type = 'cmp-managed';
      script.setAttribute('data-vendor-id', vendorData[vendor].id);
      script.setAttribute('data-vendor-name', vendorData[vendor].name);
      script.setAttribute('data-block-until-interaction', '');

      (script as HTMLScriptElement).onload = () => resolve();
      (script as HTMLScriptElement).onerror = () =>
        reject(new Error(`Failed to load script: ${src}`));
    });
  }

  private applyEmbedConsentAttributes(vendor: Vendor): void {
    const embeds = document.querySelectorAll('div[data-cmp-embed-consent]');

    embeds.forEach((embed) => {
      // Set the necessary attributes
      embed.setAttribute('data-cmp-vendor-id', vendorData[vendor].id);
      embed.setAttribute('data-cmp-vendor-name', vendorData[vendor].name);
      embed.setAttribute('data-cmp-vendor-policy', vendorData[vendor].policy);
    });
  }
}

// create consent manager based on type and config
export const createConsentManager = (
  type: WhlConsentManagerType,
  config?: ConsentManagerConfig
): ConsentManager => {
  switch (type) {
    case WhlConsentManagerType.NULL:
      return new NullConsentManager();
    case WhlConsentManagerType.IN_MEMORY:
      return new InMemoryConsentManager();
    case WhlConsentManagerType.RND_CONSENT_MANAGER:
      return new RNDConsentManagementPlatform(config ?? {});
    default:
      throw 'Unknown consent manager type';
  }
};
