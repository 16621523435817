import type { Ref } from 'vue';
import { DOMAIN_TO_CONFIG_MAP } from '../domains.config';
import { INSTANCE_TO_CONFIGS } from '../instances.config';
import type { BaseInstanceConfig } from '@models/BaseInstanceConfig';

const STATE_KEY = 'whl-instance-config';

export default function (): Ref<BaseInstanceConfig> {
  return useState<BaseInstanceConfig>(STATE_KEY, (): BaseInstanceConfig => {
    const currentHost = useWhlDomain();
    const domainConfig = DOMAIN_TO_CONFIG_MAP.get(toValue(currentHost));

    if (
      !domainConfig &&
      import.meta.client &&
      window.__IMXPLATFORM__WIDGET__?.widgetCurrentHost
    ) {
      throw new Error(`Unknown domain: ${toValue(currentHost)}`);
    }

    const { instanceId: __instanceId, ...overrides } = domainConfig ?? {};

    const instanceId = __instanceId ?? 'demo';
    const baseConfig = INSTANCE_TO_CONFIGS[instanceId];

    return {
      instanceId,
      ...baseConfig,
      ...overrides,
    };
  });
}
