import { WhlConsentManagerType } from '../models/WhlConsentManagerType';
import { BaseLocationType } from '../models/BaseLocation';
import { MediaTypeEnum } from '../gql/schema';
import {
  type BaseInstanceConfig,
  ContactType,
} from '../models/BaseInstanceConfig';

enum RND_CATEGORIES {
  SPORT = 3,
  KIRCHE = 14,
  BUEHNE_UND_KUNST = 6,
  MUSIK_UND_KONZERTE = 8,
  MUSEUM = 45,
}

export const CONFIG: Omit<BaseInstanceConfig, 'instanceId'> = {
  consentManagerConfiguration: {
    debug: false,
    enableEmbedConsent: true,
    privacyLink: '/datenschutz',
    privacyManagerId: 712594,
    sp: {
      config: {
        baseEndpoint: 'https://cmp-sp.rnd.de',
        propertyHref: 'https://events.rnd.de',
      },
    },
    allowList: [],
  } as RNDConsentManagerConfig,
  defaultWidgetId: 5,
  defaults: {
    hero: {
      filter: { mediaType: [MediaTypeEnum.Image], imageType: { eq: 1 } },
    },
    gallery: {
      filter: {
        or: [
          { mediaType: [MediaTypeEnum.Image], imageType: { eq: 1 } },
          { mediaType: [MediaTypeEnum.Video] },
        ],
      },
    },
    list: {
      filter: {
        mediaType: [MediaTypeEnum.Image],
        imageType: { eq: 1 },
      },
      fallback: [{ mediaType: [MediaTypeEnum.Image], imageType: { eq: 2 } }],
    },
    article: {
      contact: ContactType.CONTACT,
    },
  },
  enabledLanguages: ['de', 'en'],
  event: {
    detail: {
      hero: {
        displayFallbackImage: false,
      },
    },
    customCategoryFallbackIcons: {
      [RND_CATEGORIES.SPORT]: `/assets/images/instances/rnd/fallbacks/event/images/${RND_CATEGORIES.SPORT}.jpg`,
      [RND_CATEGORIES.KIRCHE]: `/assets/images/instances/rnd/fallbacks/event/images/${RND_CATEGORIES.KIRCHE}.jpg`,
      [RND_CATEGORIES.BUEHNE_UND_KUNST]: `/assets/images/instances/rnd/fallbacks/event/images/${RND_CATEGORIES.BUEHNE_UND_KUNST}.jpg`,
      [RND_CATEGORIES.MUSIK_UND_KONZERTE]: `/assets/images/instances/rnd/fallbacks/event/images/${RND_CATEGORIES.MUSIK_UND_KONZERTE}.jpg`,
      [RND_CATEGORIES.MUSEUM]: `/assets/images/instances/rnd/fallbacks/event/images/${RND_CATEGORIES.MUSEUM}.jpg`,
    },
  },
  footerContact: {
    name: 'RedaktionsNetzwerk Deutschland',
    street: 'August-Madsack-Str.',
    streetNo: '1',
    zipcode: '30559',
    city: 'Hannover',
    email: 'info@madsack.de',
  },
  footerLogo: '/assets/images/instances/rnd/logo.white.svg',
  graphqlBearerToken:
    'eyJraWQiOiJpbXgtY2RhIiwidHlwIjoiSldUIiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJodHRwczpcL1wvcm5kLmlteHBsYXRmb3JtLmRlXC9vYXV0aCIsInN1YiI6IjIxIiwibG5hbWUiOiJ3cy53aGl0ZWxhYmVsLXdpZGdldHMifQ.X19fG8eKjNB3_yTi_-6MlFeEp7i_U9SnFnsknXFAUx8k7TLndXbTWg11cj5TPDtOoAioeWKjJE2sRKiMtGofr6XaMWltqME3xGxwHV7xSEIkXUT4znvaBBSPJKcR2PGWRfBfsLLSsMUzPylJMXA3WTDjgGltp2OV_w1GNDmayzxkjT1EVK8fvqXwFrnLNpEddDRMYnI9QfG8BmSQsXoOOYykr4Um20GTx9gMyUL2i9clJkuQPAFVSr1kbr44Vk0aU9M2_MozNl9Iv4CQNnzXoJDk71j9hiWYlR_6LWinONMvUWJ-sMmZSpploCV4BRCpFBheVEegooVUH7y09d2GRA',
  graphqlEndpoint: `https://content-delivery.imxplatform.de/rnd/imxplatform`,
  headerLogo: '/assets/images/instances/rnd/logo.colored.svg',
  map: {
    defaultCoords: {
      // freiburg
      latitude: 52.3671713044339,
      longitude: 9.73699056030826,
    },
    smartScroll: true,
  },
  pageheader: {
    search: {
      locationSearchWhitelist: [
        BaseLocationType.ADDRESS_POI_GROUP,
        BaseLocationType.LOCATION,
        BaseLocationType.REGION,
      ],
    },
  },
  poi: {
    openingHours: {
      hideDateRangeInfo: true,
    },
  },
  portalConsentManager: WhlConsentManagerType.RND_CONSENT_MANAGER,
};
