export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: Date; output: Date; }
  /**
   * A string representing a duration conforming to the ISO8601 standard,
   * such as: P1W1DT13H23M34S
   * P is the duration designator (for period) placed at the start of the duration representation.
   * Y is the year designator that follows the value for the number of years.
   * M is the month designator that follows the value for the number of months.
   * W is the week designator that follows the value for the number of weeks.
   * D is the day designator that follows the value for the number of days.
   * T is the time designator that precedes the time components of the representation.
   * H is the hour designator that follows the value for the number of hours.
   * M is the minute designator that follows the value for the number of minutes.
   * S is the second designator that follows the value for the number of seconds.
   *
   * Note the time designator, T, that precedes the time value.
   *
   * Matches moment.js, Luxon and DateFns implementations
   * ,/. is valid for decimal places and +/- is a valid prefix
   */
  Duration: { input: string; output: string; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: unknown; output: unknown; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: object; output: object; }
  /** A field whose value is a valid decimal degrees latitude number (53.471): https://en.wikipedia.org/wiki/Latitude */
  Latitude: { input: number; output: number; }
  /** A local date string (i.e., with no associated timezone) in `YYYY-MM-DD` format, e.g. `2020-01-01`. */
  LocalDate: { input: string; output: string; }
  /** A local time string (i.e., with no associated timezone) in 24-hr `HH:mm[:ss[.SSS]]` format, e.g. `14:25` or `14:25:06` or `14:25:06.123`. */
  LocalTime: { input: string; output: string; }
  /** A field whose value is a valid decimal degrees longitude number (53.471): https://en.wikipedia.org/wiki/Longitude */
  Longitude: { input: number; output: number; }
  /** Floats that will have a value of 0 or more. */
  NonNegativeFloat: { input: number; output: number; }
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: { input: number; output: number; }
  /** Floats that will have a value greater than 0. */
  PositiveFloat: { input: number; output: number; }
  /** Integers that will have a value greater than 0. */
  PositiveInt: { input: number; output: number; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: never; output: never; }
};

export type AccommodationAddressbase = {
  __typename?: 'AccommodationAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  accommodationLanguages?: Maybe<Array<AccommodationLanguage>>;
  accommodationTypes?: Maybe<Array<AccommodationType>>;
  /** Returns the first attribute definition of this accommodation addressbase. Can be filtered. */
  attributeDefinition?: Maybe<ExtendedAttributeDefinition>;
  /** Returns the attribute definitions of this accommodation addressbase. Can be filtered. */
  attributeDefinitions?: Maybe<Array<ExtendedAttributeDefinition>>;
  businessTypes?: Maybe<Array<BusinessType>>;
  category?: Maybe<AccommodationCategory>;
  certificateClassifications?: Maybe<Array<CertificateClassification>>;
  certificates?: Maybe<Array<Certificate>>;
  endowments?: Maybe<Array<Endowment>>;
  id?: Maybe<Scalars['Int']['output']>;
  onlineBookable?: Maybe<Scalars['Boolean']['output']>;
  surveyId?: Maybe<Scalars['String']['output']>;
  topics?: Maybe<Array<Topic>>;
  trustYouId?: Maybe<Scalars['String']['output']>;
  trustYouReviewsCount?: Maybe<Scalars['String']['output']>;
  trustYouScore?: Maybe<Scalars['String']['output']>;
  trustYouScoreDescription?: Maybe<Scalars['String']['output']>;
  trustYouSourcesCount?: Maybe<Scalars['String']['output']>;
};


export type AccommodationAddressbaseAttributeDefinitionArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};


export type AccommodationAddressbaseAttributeDefinitionsArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};

export type AccommodationCategory = {
  __typename?: 'AccommodationCategory';
  _entityType?: Maybe<Scalars['String']['output']>;
  dehoga?: Maybe<Scalars['Int']['output']>;
  dehogaGasthof?: Maybe<Scalars['Int']['output']>;
  dtvCamping?: Maybe<Scalars['Int']['output']>;
  dtvFewMax?: Maybe<Scalars['Int']['output']>;
  dtvFewMin?: Maybe<Scalars['Int']['output']>;
  dtvPrivat?: Maybe<Scalars['Int']['output']>;
  hrs?: Maybe<Scalars['Int']['output']>;
  superior?: Maybe<Scalars['Boolean']['output']>;
};

export type AccommodationCertificationClassificationFilter = {
  /**
   * Filter results by their assigned certificate values.
   *
   * Tip: Possible values are defined by the data object "CertificateClassValue".
   */
  certificateClassificationIds?: InputMaybe<IntArrayPredicate>;
  /**
   * Deprecated: Use 'certificateIds' instead.
   *
   * Filter results by their assigned certificate.
   *
   * Tip: Possible values are defined by the data object "Certificate".
   */
  certificateId?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Filter results by their assigned certificates.
   *
   * Tip: Possible values are defined by the data object "Certificate".
   */
  certificateIds?: InputMaybe<IntArrayPredicate>;
};

export type AccommodationLanguage = {
  __typename?: 'AccommodationLanguage';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type AccommodationModuleFilter = {
  /**
   * Filter results by their type. These are similar to business types, but more detailed, e.g. business hotel, farm, etc.
   *
   * Tip: Possible values are defined by the data object "AccommodationType".
   */
  accommodationTypes?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their business type, e.g. hotel, guesthouse, holiday home, etc.
   *
   * Tip: Possible values are defined by the data object "BusinessType".
   */
  businessTypes?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their certification and values.
   *
   * Tip: Possible values are defined by the data objects "Certificate" and "CertificateClassValue".
   */
  certificationClassification?: InputMaybe<Array<AccommodationCertificationClassificationFilter>>;
  /**
   * Filter results by their available endowments. These are not based on the rooms of the accommodation, but on the whole accommodation, e.g. pool, sauna, etc.
   *
   * Tip: Possible values are defined by the data object "Endowment".
   */
  endowments?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their topic.
   *
   * Tip: Possible values are defined by the data object "Topic".
   */
  topics?: InputMaybe<IntArrayPredicate>;
};

export type AccommodationType = {
  __typename?: 'AccommodationType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type AddRyddleTicketCheckpointGenericErrorResponse = AddRyddleTicketCheckpointResponse & {
  __typename?: 'AddRyddleTicketCheckpointGenericErrorResponse';
  errorMessage: Scalars['String']['output'];
  /** As checkpoint could not be added, result will be false. */
  result: Scalars['Boolean']['output'];
};

/** Generic response for ryddle ticket invalidation. */
export type AddRyddleTicketCheckpointResponse = {
  /** Determines state of ryddle ticket. Will be true, if process id is a valid ryddle ticket and it had not more than one checkpoint before calling the mutation. */
  result: Scalars['Boolean']['output'];
};

export type AddRyddleTicketCheckpointSuccessResponse = AddRyddleTicketCheckpointResponse & {
  __typename?: 'AddRyddleTicketCheckpointSuccessResponse';
  /** Returns all ticket checkpoints (including the new one). */
  checkpoints: Array<Scalars['DateTime']['output']>;
  /** Checkpoint added, result will be true. */
  result: Scalars['Boolean']['output'];
};

export type AddressComponent = {
  __typename?: 'AddressComponent';
  _entityType?: Maybe<Scalars['String']['output']>;
  addition?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  homepage?: Maybe<Scalars['String']['output']>;
  homepageLinkText?: Maybe<Scalars['String']['output']>;
  phone1?: Maybe<Scalars['String']['output']>;
  phone2?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNo?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type AddressPoi = {
  __typename?: 'AddressPoi';
  _entityType?: Maybe<Scalars['String']['output']>;
  addressbaseUser?: Maybe<User>;
  alternateLanguage?: Maybe<Scalars['String']['output']>;
  attributes?: Maybe<Array<AddressbaseAttribute>>;
  author?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  contact1?: Maybe<ContactData>;
  /**
   * Returns the first contactList of this object. Can be filtered.
   *
   * The returned nodes will be filtered by the given language and appearance or fall back to the default filters of the api key (which may define a certain delivery channel for all your requests).
   */
  contactList?: Maybe<EntityListContact>;
  /**
   * Returns the contactLists of this object. Can be filtered.
   *
   * The returned nodes will be filtered by the given language and appearance or fall back to the default filters of the api key (which may define a certain delivery channel for all your requests).
   */
  contactLists?: Maybe<Array<EntityListContact>>;
  contentScore?: Maybe<Scalars['Float']['output']>;
  creationTime?: Maybe<Scalars['String']['output']>;
  entityState?: Maybe<EntityState>;
  geoInfo?: Maybe<GeoInfo>;
  groups?: Maybe<Array<AddressPoiGroup>>;
  id?: Maybe<Scalars['Int']['output']>;
  importIdentifier?: Maybe<Scalars['String']['output']>;
  languages?: Maybe<Array<Language>>;
  lastChangeTime?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Location>;
  masterAddressPoiId?: Maybe<Scalars['Int']['output']>;
  /** Returns the assigned media for this POI. */
  media?: Maybe<Array<AddressbaseMedia>>;
  mediaLicense?: Maybe<MediaLicense>;
  /** Returns the first assigned medium for this POI. Can be filtered and sorted. */
  medium?: Maybe<AddressbaseMedia>;
  regions?: Maybe<Array<Region>>;
  relevanceScore?: Maybe<Scalars['Float']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  socialMedia?: Maybe<Array<SocialMedia>>;
  subTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  types?: Maybe<Array<AddressPoiType>>;
};


export type AddressPoiContactListArgs = {
  filter?: InputMaybe<ContactListFilter>;
};


export type AddressPoiContactListsArgs = {
  filter?: InputMaybe<ContactListFilter>;
};


export type AddressPoiMediaArgs = {
  fallback?: InputMaybe<Array<MediaFilter>>;
  filter?: InputMaybe<MediaFilter>;
  limit?: InputMaybe<Limits>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type AddressPoiMediumArgs = {
  fallback?: InputMaybe<Array<MediaFilter>>;
  filter?: InputMaybe<MediaFilter>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type AddressPoiGroup = {
  __typename?: 'AddressPoiGroup';
  _entityType?: Maybe<Scalars['String']['output']>;
  addressPois?: Maybe<Array<AddressPoi>>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  leadAddressPoi?: Maybe<AddressPoi>;
  type?: Maybe<AddressPoiType>;
};

export type AddressPoiOccupancy = {
  __typename?: 'AddressPoiOccupancy';
  _entityType?: Maybe<Scalars['String']['output']>;
  absolute?: Maybe<Scalars['Boolean']['output']>;
  addressPoiId?: Maybe<Scalars['Int']['output']>;
  forecast?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  measurementTime?: Maybe<Scalars['String']['output']>;
  sensor?: Maybe<OccupancySensor>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type AddressPoiType = {
  __typename?: 'AddressPoiType';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Addressbase = {
  __typename?: 'Addressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  additionalInfo?: Maybe<Scalars['String']['output']>;
  addressbaseUser?: Maybe<User>;
  alternateLanguage?: Maybe<Scalars['String']['output']>;
  appearances?: Maybe<Array<Appearance>>;
  /** Returns the first attribute definition of this POI. Can be filtered. */
  attributeDefinition?: Maybe<ExtendedAttributeDefinition>;
  /** Returns the attribute definitions of this POI. Can be filtered. */
  attributeDefinitions?: Maybe<Array<ExtendedAttributeDefinition>>;
  attributes?: Maybe<Array<AddressbaseAttribute>>;
  author?: Maybe<Scalars['String']['output']>;
  bookingLink?: Maybe<Scalars['String']['output']>;
  bookingLinkText?: Maybe<Scalars['String']['output']>;
  canonicalUrl?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  contact1?: Maybe<ContactData>;
  contact2?: Maybe<ContactData>;
  /** Returns all assigned contact POIs. */
  contactAddressbases?: Maybe<Array<AddressbaseContact>>;
  /**
   * Returns the first contactList of this object. Can be filtered.
   *
   * The returned nodes will be filtered by the given language and appearance or fall back to the default filters of the api key (which may define a certain delivery channel for all your requests).
   */
  contactList?: Maybe<EntityListContact>;
  /**
   * Returns the contactLists of this object. Can be filtered.
   *
   * The returned nodes will be filtered by the given language and appearance or fall back to the default filters of the api key (which may define a certain delivery channel for all your requests).
   */
  contactLists?: Maybe<Array<EntityListContact>>;
  contentScore?: Maybe<Scalars['Float']['output']>;
  creationTime?: Maybe<Scalars['String']['output']>;
  deliveryChannels?: Maybe<Array<DeliveryChannel>>;
  distances?: Maybe<Array<AddressbaseDistance>>;
  entityState?: Maybe<EntityState>;
  geoInfo?: Maybe<GeoInfo>;
  htmlHeadMetaDescription?: Maybe<Scalars['String']['output']>;
  htmlHeadTitle?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  importIdentifier?: Maybe<Scalars['String']['output']>;
  importInfos?: Maybe<Array<ImportInfo>>;
  /** Returns the first inputfield of this POI. Can be filtered and sorted. */
  inputField?: Maybe<AddressbaseInputField>;
  /** Returns the inputfields of this POI. Can be filtered and sorted. */
  inputFields?: Maybe<Array<AddressbaseInputField>>;
  languages?: Maybe<Array<Language>>;
  lastChangeTime?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Location>;
  /** Returns the locations of this POI. Can be filtered. */
  locations?: Maybe<Array<AddressbaseLocation>>;
  longDescription?: Maybe<Scalars['String']['output']>;
  masterAddressPoiId?: Maybe<Scalars['Int']['output']>;
  /** Returns the assigned media for this POI. */
  media?: Maybe<Array<AddressbaseMedia>>;
  mediaLicense?: Maybe<MediaLicense>;
  /** Returns the first assigned medium for this POI. Can be filtered and sorted. */
  medium?: Maybe<AddressbaseMedia>;
  /** Checks if metainfos of this POI meet the provided productline predicate. */
  meetsProductlinePredicate: Scalars['Boolean']['output'];
  /** Returns the first metainfo of this POI. Can be filtered. */
  metainfo?: Maybe<MetainfoAddressbase>;
  /** Returns the metainfos of this POI. Can be filtered. */
  metainfos?: Maybe<Array<MetainfoAddressbase>>;
  metasearchIntegration?: Maybe<Scalars['Boolean']['output']>;
  offers?: Maybe<Array<Offer>>;
  /** Returns the opening hours of this POI. Can be filtered. */
  openingHoursInformations?: Maybe<Array<AddressbaseOpeningHoursInformation>>;
  paymentOptions?: Maybe<Array<PaymentOption>>;
  /** Return the permaLink of the POI. */
  permaLink?: Maybe<Scalars['String']['output']>;
  regions?: Maybe<Array<Region>>;
  relevanceScore?: Maybe<Scalars['Float']['output']>;
  /**
   * Get a schema.org representation.
   *
   * By using the optional context parameter you are able to get a specific schema.org representation (e.g. for a specific productline like gastronomy). By default only a generic schema.org (e.g. "Place") representation is returned.
   *
   * Hint: As this is an expensive operation, do not use it in a list query. Use it only for a single object request.
   */
  schemaOrg: SchemaOrgRepresentation;
  searchKeywords?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  socialMedia?: Maybe<Array<SocialMedia>>;
  subTitle?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Tag>>;
  title?: Maybe<Scalars['String']['output']>;
  types?: Maybe<Array<AddressPoiType>>;
  /**
   * Weather data for the POI/addressbase.
   *
   * It is retrieved from an external source, which may be selected by providing the source parameter. It depends
   * on the customer´s configuration, if there are multiple sources available.
   */
  weather?: Maybe<Weather>;
};


export type AddressbaseAttributeDefinitionArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};


export type AddressbaseAttributeDefinitionsArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};


export type AddressbaseContactAddressbasesArgs = {
  sortOrder?: InputMaybe<SortOrder>;
};


export type AddressbaseContactListArgs = {
  filter?: InputMaybe<ContactListFilter>;
};


export type AddressbaseContactListsArgs = {
  filter?: InputMaybe<ContactListFilter>;
};


export type AddressbaseInputFieldArgs = {
  filter?: InputMaybe<InputFieldFilter>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type AddressbaseInputFieldsArgs = {
  filter?: InputMaybe<InputFieldFilter>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type AddressbaseLocationsArgs = {
  filter?: InputMaybe<LocationsAddressbaseFilter>;
};


export type AddressbaseMediaArgs = {
  fallback?: InputMaybe<Array<MediaFilter>>;
  filter?: InputMaybe<MediaFilter>;
  limit?: InputMaybe<Limits>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type AddressbaseMediumArgs = {
  fallback?: InputMaybe<Array<MediaFilter>>;
  filter?: InputMaybe<MediaFilter>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type AddressbaseMeetsProductlinePredicateArgs = {
  predicate: ProductlinePredicate;
};


export type AddressbaseMetainfoArgs = {
  filter?: InputMaybe<MetainfoAddressbaseFilter>;
};


export type AddressbaseMetainfosArgs = {
  filter?: InputMaybe<MetainfoAddressbaseFilter>;
};


export type AddressbaseOpeningHoursInformationsArgs = {
  filter?: InputMaybe<OpeningHourInformationFilter>;
};


export type AddressbasePermaLinkArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};


export type AddressbaseSchemaOrgArgs = {
  context?: InputMaybe<SchemaOrgAddressbaseContext>;
};


export type AddressbaseWeatherArgs = {
  source?: WeatherSource;
};

export type AddressbaseAttribute = {
  __typename?: 'AddressbaseAttribute';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type AddressbaseContact = {
  __typename?: 'AddressbaseContact';
  _entityType?: Maybe<Scalars['String']['output']>;
  contactAddressbase?: Maybe<ContactAddressbase>;
  contextProductline?: Maybe<Productline>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  purpose?: Maybe<ContactPurpose>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
};

export type AddressbaseDistance = {
  __typename?: 'AddressbaseDistance';
  _entityType?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  distanceDefinition?: Maybe<DistanceDefinition>;
  id?: Maybe<Scalars['Int']['output']>;
};

export enum AddressbaseFacetField {
  /** Build facets for assigned location entity. */
  Location = 'LOCATION',
  /** Build facets for poi group. */
  PoiGroup = 'POI_GROUP',
  /** Build facets for productlines. */
  Productline = 'PRODUCTLINE',
  /** Build facets for assigned regions. */
  Region = 'REGION',
  /**
   * Build facets of tour attributes.
   *
   * Facets of this type can only count objects, which have tour module/metainfo assigned.
   */
  TourAttribute = 'TOUR_ATTRIBUTE',
  /**
   * Build facets of tour categories.
   *
   * Facets of this type can only count objects, which have tour module/metainfo assigned.
   */
  TourCategory = 'TOUR_CATEGORY'
}

export type AddressbaseFacetResult = {
  __typename?: 'AddressbaseFacetResult';
  fields?: Maybe<Array<AddressbaseFieldFacetResult>>;
  stats?: Maybe<Array<AddressbaseFieldStatsResult>>;
};

export type AddressbaseFacets = {
  fields?: InputMaybe<Array<AddressbaseFieldFacet>>;
  stats?: InputMaybe<Array<AddressbaseFieldStats>>;
};

export type AddressbaseFieldFacet = {
  /** Identifies the field that should bet treated as facet. */
  field: AddressbaseFacetField;
  /** Specifies predicates to filter out returned facets */
  filter?: InputMaybe<FacetFilterPredicate>;
  /** Provide an identifier for facet results. */
  identifier?: InputMaybe<Scalars['String']['input']>;
  /** Limits the number of returned facet counts. Default: 100 */
  limit?: Scalars['Int']['input'];
  /** Specifies the minimum count required for a facet to be included in the response. Default: 0 */
  mincount?: Scalars['Int']['input'];
};

export type AddressbaseFieldFacetData = AddressPoiGroup | Location | Productline | Region | TourAttribute | TourCategory;

export type AddressbaseFieldFacetResult = {
  __typename?: 'AddressbaseFieldFacetResult';
  facets: Array<AddressbaseFieldFacetValue>;
  field: AddressbaseFacetField;
  identifier: Scalars['String']['output'];
};

export type AddressbaseFieldFacetValue = {
  __typename?: 'AddressbaseFieldFacetValue';
  count: Scalars['Int']['output'];
  data: AddressbaseFieldFacetData;
};

export type AddressbaseFieldStats = {
  /** Identifies the field that should bet treated as stats. */
  field: AddressbaseStatField;
  /** Provide an identifier for stats results. */
  identifier?: InputMaybe<Scalars['String']['input']>;
};

export type AddressbaseFieldStatsResult = FieldStatsResult & {
  __typename?: 'AddressbaseFieldStatsResult';
  count: Scalars['NonNegativeInt']['output'];
  field: AddressbaseStatField;
  identifier: Scalars['String']['output'];
  value: FieldStatsResultValue;
};

export type AddressbaseFile = {
  __typename?: 'AddressbaseFile';
  _entityType?: Maybe<Scalars['String']['output']>;
  annually?: Maybe<Scalars['Boolean']['output']>;
  attributes?: Maybe<Array<MediaAttribute>>;
  deactivated?: Maybe<Scalars['Boolean']['output']>;
  deeplink?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['String']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  fromDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  identification?: Maybe<Scalars['String']['output']>;
  languages?: Maybe<Array<Language>>;
  pooledMedium?: Maybe<PooledAddressbaseMedium>;
  productlines?: Maybe<Array<Productline>>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
  toDate?: Maybe<Scalars['String']['output']>;
};

export type AddressbaseFilter = {
  /** Add one or more filters to the query; if you provide several items to this field, they will be combined with a logical AND. */
  and?: InputMaybe<Array<AddressbaseFilter>>;
  /** Restrict results to a specific client. */
  client?: InputMaybe<ClientPredicate>;
  /**
   * Constrain results by fulltext search query. Will be used as hard filter criterion, not as a ranking criterion.
   *
   * If you need a ranking criterion, use the 'query' parameter of the 'pois' query instead.
   */
  fulltext?: InputMaybe<Scalars['String']['input']>;
  /** Constrain results by their geo location. */
  geoFilter?: InputMaybe<GeoFilter>;
  /** Filter results by their assigned poi group. */
  group?: InputMaybe<IntArrayPredicate>;
  /** Constrain results by their ids. */
  id?: InputMaybe<IntPredicate>;
  /** Constrain results by their import ids. */
  importId?: InputMaybe<StringPredicate>;
  /** Constrain location based on the referenced location ID (= "Ort") */
  location?: InputMaybe<IntPredicate>;
  /** Filter results by module specific criteria (e.g. accommodation or tour  specific filters). */
  moduleFilter?: InputMaybe<AddressbaseModuleFilter>;
  /** Add one or more inverted filters to the query. */
  not?: InputMaybe<Array<AddressbaseFilter>>;
  /** Filter results by their opening hours. */
  openingHours?: InputMaybe<OpeningHoursFilter>;
  /** Add one or more filter to the query; if you provide several items to this field, they will be combined with a logical OR. */
  or?: InputMaybe<Array<AddressbaseFilter>>;
  /**
   * Filter results by their usable payment options, e.g. debit card, PayPal, etc.
   *
   * Tip: Possible values are defined by the data object "PaymentOption".
   */
  paymentOptions?: InputMaybe<IntArrayPredicate>;
  /** Constrain results by exact match of permalink (within the current language). */
  permaLink?: InputMaybe<StringPredicate>;
  /** Constrain results by their productline assignments. */
  productlines?: InputMaybe<IntArrayPredicate>;
  /** Constrain location based on the referenced region IDs */
  regions?: InputMaybe<IntArrayPredicate>;
  /** Constrain results by poi´s contact1 zipcode. */
  zipcode?: InputMaybe<StringPredicate>;
};

export type AddressbaseImage = {
  __typename?: 'AddressbaseImage';
  _entityType?: Maybe<Scalars['String']['output']>;
  annually?: Maybe<Scalars['Boolean']['output']>;
  attributes?: Maybe<Array<MediaAttribute>>;
  deactivated?: Maybe<Scalars['Boolean']['output']>;
  deeplink?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['String']['output']>;
  focalPointX?: Maybe<Scalars['Int']['output']>;
  focalPointY?: Maybe<Scalars['Int']['output']>;
  fromDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  imageHeight?: Maybe<Scalars['String']['output']>;
  imageType?: Maybe<ImageType>;
  imageWidth?: Maybe<Scalars['String']['output']>;
  languages?: Maybe<Array<Language>>;
  link?: Maybe<Scalars['String']['output']>;
  pooledMedium?: Maybe<PooledAddressbaseMedium>;
  productlines?: Maybe<Array<Productline>>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
  toDate?: Maybe<Scalars['String']['output']>;
};

export type AddressbaseInputField = {
  __typename?: 'AddressbaseInputField';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inputFieldType?: Maybe<InputFieldType>;
  productline?: Maybe<Productline>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type AddressbaseLocation = {
  __typename?: 'AddressbaseLocation';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  locationDefinition?: Maybe<LocationDefinition>;
  locationInfo?: Maybe<Scalars['String']['output']>;
};

export type AddressbaseMedia = AddressbaseFile | AddressbaseImage | AddressbaseVideo;

export type AddressbaseModuleFilter = {
  accommodation?: InputMaybe<AccommodationModuleFilter>;
  beach?: InputMaybe<BeachModuleFilter>;
  cnvCenter?: InputMaybe<CnvCenterModuleFilter>;
  cnvEventLocation?: InputMaybe<CnvEventLocationModuleFilter>;
  cnvHotel?: InputMaybe<CnvHotelModuleFilter>;
  gastronomy?: InputMaybe<GastronomyModuleFilter>;
  /** Filter results by their hamburg card partner status. */
  hhcPartner?: InputMaybe<HamburgCardPartnerModuleFilter>;
  lodge?: InputMaybe<LodgeModuleFilter>;
  rfa?: InputMaybe<RfaModuleFilter>;
  tour?: InputMaybe<TourModuleFilter>;
};

export type AddressbaseOpeningHoursInformation = {
  __typename?: 'AddressbaseOpeningHoursInformation';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  openingHoursInformation?: Maybe<OpeningHoursInformation>;
  productline?: Maybe<Productline>;
};

export type AddressbaseOrder = {
  additional?: InputMaybe<AddressbaseOrderArguments>;
  field: AddressbaseSortFields;
  order?: InputMaybe<SortOrder>;
};

export type AddressbaseOrderArguments = {
  /** If provided results will not be ordered by exact geo distance but by distance buckets. The provided values will be the boundary distance between the buckets (in meters). */
  distanceFromPointBucketBoundaries?: InputMaybe<Array<Scalars['PositiveInt']['input']>>;
  /**
   * If provided the pseudo random order will be initialized by this value; the sort order will be the same for subsequent calls, whenn providing the same initializer value.
   * This is especially important when paging through your results.
   */
  pseudoRandomInitializer?: InputMaybe<Scalars['PositiveInt']['input']>;
};

export type AddressbaseProcessing = {
  applyProductlineContext?: InputMaybe<Array<Scalars['PositiveInt']['input']>>;
};

export enum AddressbaseSortFields {
  /**
   * Sort results by distance to a point. Filters are required to contain a distanceFromPoint constraint, which point will be used for sorting.
   *
   * As the distance from point will be an absolute order (as it is very unlikely that to results have exact the same distance), you can also pass bucket boundaries, which are used to group results by distance intervals.
   * Thus you have the ability to pass a further order field after DISTANCE_FROM_POINT which actually influences the result order. Just provide interval boundaries by passing AddressbaseOrder.additional.distanceFromPointBucketBoundaries.
   */
  DistanceFromPoint = 'DISTANCE_FROM_POINT',
  /**
   * Sort results randomly.
   *
   * To support a stable order for following pagination calls, we encourage you to provide a initializer value via AddressbaseOrder.additional.pseudoRandomInitializer
   */
  PseudoRandom = 'PSEUDO_RANDOM',
  /** Sort results by title */
  Title = 'TITLE'
}

export enum AddressbaseStatField {
  /**
   * Build statistics over the duration of tours.
   *
   * Facets of this type can only evaluated on objects, which have tour module/metainfo assigned.
   */
  TourDuration = 'TOUR_DURATION',
  /**
   * Build statistics over the length of tours.
   *
   * Facets of this type can only evaluated on objects, which have tour module/metainfo assigned.
   */
  TourLength = 'TOUR_LENGTH'
}

export type AddressbaseVideo = {
  __typename?: 'AddressbaseVideo';
  _entityType?: Maybe<Scalars['String']['output']>;
  annually?: Maybe<Scalars['Boolean']['output']>;
  attributes?: Maybe<Array<MediaAttribute>>;
  deactivated?: Maybe<Scalars['Boolean']['output']>;
  deeplink?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['String']['output']>;
  fromDate?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  languages?: Maybe<Array<Language>>;
  pooledMedium?: Maybe<PooledAddressbaseMedium>;
  productlines?: Maybe<Array<Productline>>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
  toDate?: Maybe<Scalars['String']['output']>;
  videoIdentification?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type Announcement = {
  __typename?: 'Announcement';
  _entityType?: Maybe<Scalars['String']['output']>;
  addressPoi?: Maybe<AddressPoi>;
  client?: Maybe<Client>;
  creationTime?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  generated?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  importIdentifier?: Maybe<Scalars['String']['output']>;
  lastChangeTime?: Maybe<Scalars['String']['output']>;
  occupancies?: Maybe<Array<AddressPoiOccupancy>>;
  organisation?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<AnnouncementType>;
  validityTime?: Maybe<Scalars['String']['output']>;
};

export type AnnouncementFilter = {
  /** Filter results by its announcement ids. */
  id?: InputMaybe<IntPredicate>;
  /** Filter results by its poi ids. */
  poiId?: InputMaybe<IntPredicate>;
  /** Filter results by announcement type. */
  type?: InputMaybe<IntPredicate>;
};

export type AnnouncementOrder = {
  field: AnnouncementSortFields;
  order?: InputMaybe<SortOrder>;
};

export enum AnnouncementSortFields {
  /** Sort results by last modification of announcement. */
  LastModificationTime = 'LAST_MODIFICATION_TIME',
  /** Sort results by announcement´s title. */
  Title = 'TITLE'
}

export type AnnouncementType = {
  __typename?: 'AnnouncementType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Appearance = {
  __typename?: 'Appearance';
  _entityType?: Maybe<Scalars['String']['output']>;
  deliveryChannel?: Maybe<DeliveryChannel>;
  fromDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  languages?: Maybe<Array<Language>>;
  prioLevel?: Maybe<PrioLevel>;
  productline?: Maybe<Productline>;
  toDate?: Maybe<Scalars['String']['output']>;
};

export type AppearanceFilter = {
  /** When using this, the appearance of an object does not get checked and even unpublished ones might be returned. */
  bypass?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Restrict results to items, which have a publication for the given delivery channel.
   *
   * Hint: Your result set may already be restricted to a certain delivery channel by the api token you are using.
   */
  deliveryChannel?: InputMaybe<Scalars['PositiveInt']['input']>;
};

export type Article = {
  __typename?: 'Article';
  _entityType?: Maybe<Scalars['String']['output']>;
  appearances?: Maybe<Array<BasicAppearance>>;
  articleUser?: Maybe<User>;
  /** Returns the first attribute definition of this article. Can be filtered. */
  attributeDefinition?: Maybe<ExtendedAttributeDefinition>;
  /** Returns the attribute definitions of this article. Can be filtered. */
  attributeDefinitions?: Maybe<Array<ExtendedAttributeDefinition>>;
  author?: Maybe<Scalars['String']['output']>;
  canonicalUrl?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  /**
   * Returns the first contactList of this object. Can be filtered.
   *
   * The returned nodes will be filtered by the given language and appearance or fall back to the default filters of the api key (which may define a certain delivery channel for all your requests).
   */
  contactList?: Maybe<EntityListContact>;
  /**
   * Returns the contactLists of this object. Can be filtered.
   *
   * The returned nodes will be filtered by the given language and appearance or fall back to the default filters of the api key (which may define a certain delivery channel for all your requests).
   */
  contactLists?: Maybe<Array<EntityListContact>>;
  creationTime?: Maybe<Scalars['String']['output']>;
  deliveryChannels?: Maybe<Array<DeliveryChannel>>;
  entityState?: Maybe<EntityState>;
  geoInfo?: Maybe<GeoInfo>;
  htmlHeadMetaDescription?: Maybe<Scalars['String']['output']>;
  htmlHeadTitle?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  importIdentifier?: Maybe<Scalars['String']['output']>;
  languages?: Maybe<Array<Language>>;
  lastChangeTime?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  linkText?: Maybe<Scalars['String']['output']>;
  longDescription?: Maybe<Scalars['String']['output']>;
  /** Returns the assigned media for this article. */
  media?: Maybe<Array<ArticleMedia>>;
  mediaLicense?: Maybe<MediaLicense>;
  /** Returns the first assigned medium for this article. Can be filtered and sorted. */
  medium?: Maybe<ArticleMedia>;
  /** Checks if metainfos of this article meet the provided productline predicate. */
  meetsProductlinePredicate: Scalars['Boolean']['output'];
  /** Returns the first metainfo of this article. Can be filtered. */
  metainfo?: Maybe<MetainfoArticle>;
  /** Returns the metainfos of this article. Can be filtered. */
  metainfos?: Maybe<Array<MetainfoArticle>>;
  /** Return the permaLink of the article. */
  permaLink?: Maybe<Scalars['String']['output']>;
  regions?: Maybe<Array<Region>>;
  /**
   * Get a schema.org representation.
   *
   * Hint: As this is an expensive operation, do not use it in a list query. Use it only for a single object request.
   */
  schemaOrg: SchemaOrgRepresentation;
  searchKeywords?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  socialMedia?: Maybe<Array<SocialMedia>>;
  subTitle?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Tag>>;
  title?: Maybe<Scalars['String']['output']>;
};


export type ArticleAttributeDefinitionArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};


export type ArticleAttributeDefinitionsArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};


export type ArticleContactListArgs = {
  filter?: InputMaybe<ContactListFilter>;
};


export type ArticleContactListsArgs = {
  filter?: InputMaybe<ContactListFilter>;
};


export type ArticleMediaArgs = {
  fallback?: InputMaybe<Array<MediaFilter>>;
  filter?: InputMaybe<MediaFilter>;
  limit?: InputMaybe<Limits>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type ArticleMediumArgs = {
  fallback?: InputMaybe<Array<MediaFilter>>;
  filter?: InputMaybe<MediaFilter>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type ArticleMeetsProductlinePredicateArgs = {
  predicate: ProductlinePredicate;
};


export type ArticleMetainfoArgs = {
  filter?: InputMaybe<MetainfoArticleFilter>;
};


export type ArticleMetainfosArgs = {
  filter?: InputMaybe<MetainfoArticleFilter>;
};


export type ArticlePermaLinkArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export enum ArticleFacetField {
  /** Build facets for productlines. */
  Productline = 'PRODUCTLINE'
}

export type ArticleFacetResult = {
  __typename?: 'ArticleFacetResult';
  fields?: Maybe<Array<ArticleFieldFacetResult>>;
};

export type ArticleFacets = {
  fields?: InputMaybe<Array<ArticleFieldFacet>>;
};

export type ArticleFieldFacet = {
  /** Identifies the field that should bet treated as facet. */
  field: ArticleFacetField;
  /** Specifies predicates to filter out returned facets */
  filter?: InputMaybe<FacetFilterPredicate>;
  /** Provide an identifier for facet results. */
  identifier?: InputMaybe<Scalars['String']['input']>;
  /** Limits the number of returned facet counts. Default: 100 */
  limit?: Scalars['Int']['input'];
  /** Specifies the minimum count required for a facet to be included in the response. Default: 0 */
  mincount?: Scalars['Int']['input'];
};

export type ArticleFieldFacetData = Productline;

export type ArticleFieldFacetResult = {
  __typename?: 'ArticleFieldFacetResult';
  facets: Array<ArticleFieldFacetValue>;
  field: ArticleFacetField;
  identifier: Scalars['String']['output'];
};

export type ArticleFieldFacetValue = {
  __typename?: 'ArticleFieldFacetValue';
  count: Scalars['Int']['output'];
  data: ArticleFieldFacetData;
};

export type ArticleFile = {
  __typename?: 'ArticleFile';
  _entityType?: Maybe<Scalars['String']['output']>;
  annually?: Maybe<Scalars['Boolean']['output']>;
  attributes?: Maybe<Array<MediaAttribute>>;
  deactivated?: Maybe<Scalars['Boolean']['output']>;
  deeplink?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['String']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  fromDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  identification?: Maybe<Scalars['String']['output']>;
  languages?: Maybe<Array<Language>>;
  pooledMedium?: Maybe<PooledDataObjectMedium>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
  toDate?: Maybe<Scalars['String']['output']>;
};

export type ArticleFilter = {
  /** Add one or more filters to the query; if you provide several items to this field, they will be combined with a logical AND. */
  and?: InputMaybe<Array<ArticleFilter>>;
  /** Restrict results to a specific client. */
  client?: InputMaybe<ClientPredicate>;
  /**
   * Constrain results by fulltext search query. Will be used as hard filter criterion, not as a ranking criterion.
   *
   * If you need a ranking criterion, use the 'query' parameter of the 'articles' query instead.
   */
  fulltext?: InputMaybe<Scalars['String']['input']>;
  /** Constrain results by their geo location. */
  geoFilter?: InputMaybe<GeoFilter>;
  /** Constrain results by their ids. */
  id?: InputMaybe<IntPredicate>;
  /** Add one or more inverted filters to the query. */
  not?: InputMaybe<Array<ArticleFilter>>;
  /** Add one or more filter to the query; if you provide several items to this field, they will be combined with a logical OR. */
  or?: InputMaybe<Array<ArticleFilter>>;
  /** Constrain results by exact match of permalink (within the current language). */
  permaLink?: InputMaybe<StringPredicate>;
  /** Constrain results by their productline assignments. */
  productlines?: InputMaybe<IntArrayPredicate>;
};

export type ArticleImage = {
  __typename?: 'ArticleImage';
  _entityType?: Maybe<Scalars['String']['output']>;
  annually?: Maybe<Scalars['Boolean']['output']>;
  attributes?: Maybe<Array<MediaAttribute>>;
  deactivated?: Maybe<Scalars['Boolean']['output']>;
  deeplink?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['String']['output']>;
  focalPointX?: Maybe<Scalars['Int']['output']>;
  focalPointY?: Maybe<Scalars['Int']['output']>;
  fromDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  imageHeight?: Maybe<Scalars['String']['output']>;
  imageType?: Maybe<ImageType>;
  imageWidth?: Maybe<Scalars['String']['output']>;
  languages?: Maybe<Array<Language>>;
  link?: Maybe<Scalars['String']['output']>;
  pooledMedium?: Maybe<PooledDataObjectMedium>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
  toDate?: Maybe<Scalars['String']['output']>;
};

export type ArticleMedia = ArticleFile | ArticleImage | ArticleVideo;

export type ArticleOrder = {
  additional?: InputMaybe<ArticleOrderArguments>;
  field: ArticleSortFields;
  order?: InputMaybe<SortOrder>;
};

export type ArticleOrderArguments = {
  /** If provided results will not be ordered by exact geo distance but by distance buckets. The provided values will be the boundary distance between the buckets (in meters). */
  distanceFromPointBucketBoundaries?: InputMaybe<Array<Scalars['PositiveInt']['input']>>;
  /**
   * If provided the pseudo random order will be initialized by this value; the sort order will be the same for subsequent calls, whenn providing the same initializer value.
   * This is especially important when paging through your results.
   */
  pseudoRandomInitializer?: InputMaybe<Scalars['PositiveInt']['input']>;
};

export type ArticleProcessing = {
  applyProductlineContext?: InputMaybe<Array<Scalars['PositiveInt']['input']>>;
};

export enum ArticleSortFields {
  /**
   * Sort results by distance to a point. Filters are required to contain a distanceFromPoint constraint, which point will be used for sorting.
   *
   * As the distance from point will be an absolute order (as it is very unlikely that to results have exact the same distance), you can also pass bucket boundaries, which are used to group results by distance intervals.
   * Thus you have the ability to pass a further order field after DISTANCE_FROM_POINT which actually influences the result order. Just provide interval boundaries by passing AddressbaseOrder.additional.distanceFromPointBucketBoundaries.
   */
  DistanceFromPoint = 'DISTANCE_FROM_POINT',
  /**
   * Sort results randomly.
   *
   * To support a stable order for following pagination calls, we encourage you to provide a initializer value via AddressbaseOrder.additional.pseudoRandomInitializer
   */
  PseudoRandom = 'PSEUDO_RANDOM',
  /** Sort results by title */
  Title = 'TITLE'
}

export type ArticleVideo = {
  __typename?: 'ArticleVideo';
  _entityType?: Maybe<Scalars['String']['output']>;
  annually?: Maybe<Scalars['Boolean']['output']>;
  attributes?: Maybe<Array<MediaAttribute>>;
  deactivated?: Maybe<Scalars['Boolean']['output']>;
  deeplink?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['String']['output']>;
  fromDate?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  languages?: Maybe<Array<Language>>;
  pooledMedium?: Maybe<PooledDataObjectMedium>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
  toDate?: Maybe<Scalars['String']['output']>;
  videoIdentification?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type Artist = {
  __typename?: 'Artist';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Define which attribute definitions should be included in the result. */
export type AttributeDefinitionsFilter = {
  /** Define the attribute IDs to be included. */
  attributeIds?: InputMaybe<IntPredicate>;
};

export type Availability = {
  __typename?: 'Availability';
  _entityType?: Maybe<Scalars['String']['output']>;
  fromDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  info?: Maybe<Scalars['String']['output']>;
  languages?: Maybe<Array<Language>>;
  toDate?: Maybe<Scalars['String']['output']>;
};

export type AvailableDate = {
  __typename?: 'AvailableDate';
  /** Local date (yyyy-MM-dd) on which there may be vacancies */
  date: Scalars['LocalDate']['output'];
  /** Minimum price of product per unit/adult (in EUR). */
  minPrice?: Maybe<Scalars['Float']['output']>;
};

export type BasicAppearance = {
  __typename?: 'BasicAppearance';
  _entityType?: Maybe<Scalars['String']['output']>;
  deliveryChannel?: Maybe<DeliveryChannel>;
  fromDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  languages?: Maybe<Array<Language>>;
  productline?: Maybe<Productline>;
  toDate?: Maybe<Scalars['String']['output']>;
};

export type BasicEventDateFilter = {
  fromDate?: InputMaybe<Scalars['LocalDate']['input']>;
  id?: InputMaybe<IntPredicate>;
  startTime?: InputMaybe<TimeRangeFilter>;
  toDate?: InputMaybe<Scalars['LocalDate']['input']>;
};

export type BasicInputFieldType = {
  __typename?: 'BasicInputFieldType';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  typeKey?: Maybe<Scalars['String']['output']>;
  usedForAddressbases?: Maybe<Scalars['Boolean']['output']>;
  usedForEvents?: Maybe<Scalars['Boolean']['output']>;
  usedForOffers?: Maybe<Scalars['Boolean']['output']>;
};

export type BeachAdditionalInfoDefinition = {
  __typename?: 'BeachAdditionalInfoDefinition';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type BeachAddressbase = {
  __typename?: 'BeachAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  addInfos?: Maybe<Array<BeachAddressbaseAdditionalInfo>>;
  /** Returns the first attribute definition of this article. Can be filtered. */
  attributeDefinition?: Maybe<ExtendedAttributeDefinition>;
  /** Returns the attribute definitions of this article. Can be filtered. */
  attributeDefinitions?: Maybe<Array<ExtendedAttributeDefinition>>;
  bathEndowments?: Maybe<Array<BeachBathEndowment>>;
  character?: Maybe<Array<BeachCharacter>>;
  childrenOffers?: Maybe<Array<BeachChildrenOffer>>;
  endowments?: Maybe<Array<BeachEndowment>>;
  id?: Maybe<Scalars['Int']['output']>;
  services?: Maybe<Array<BeachService>>;
  sportOffers?: Maybe<Array<BeachSportOffer>>;
  types?: Maybe<Array<BeachType>>;
};


export type BeachAddressbaseAttributeDefinitionArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};


export type BeachAddressbaseAttributeDefinitionsArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};

export type BeachAddressbaseAdditionalInfo = {
  __typename?: 'BeachAddressbaseAdditionalInfo';
  _entityType?: Maybe<Scalars['String']['output']>;
  attributeDef?: Maybe<BeachAdditionalInfoDefinition>;
  id?: Maybe<Scalars['Int']['output']>;
  info?: Maybe<Scalars['String']['output']>;
};

export type BeachBathEndowment = {
  __typename?: 'BeachBathEndowment';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type BeachCharacter = {
  __typename?: 'BeachCharacter';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type BeachChildrenOffer = {
  __typename?: 'BeachChildrenOffer';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type BeachEndowment = {
  __typename?: 'BeachEndowment';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type BeachModuleFilter = {
  /**
   * Filter results by their beach bath endowments ("Badeausstattung")
   *
   * Tip: Possible values are defined by the data object "BeachBathEndowments".
   */
  bathEndowments?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their beach character ("Strandarten")
   *
   * Tip: Possible values are defined by the data object "BeachCharacter".
   */
  characters?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their beach children offers ("Kinderangebote")
   *
   * Tip: Possible values are defined by the data object "BeachChildrenOffer".
   */
  childrenOffers?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their beach endowments ("Ausstattung")
   *
   * Tip: Possible values are defined by the data object "BeachEndowment".
   */
  endowments?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their beach services ("Services")
   *
   * Tip: Possible values are defined by the data object "BeachService".
   */
  services?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their beach sport offers ("Sportangebote")
   *
   * Tip: Possible values are defined by the data object "BeachSportOffer".
   */
  sportOffers?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their beach types ("Strandarten")
   *
   * Tip: Possible values are defined by the data object "BeachType".
   */
  types?: InputMaybe<IntArrayPredicate>;
};

export type BeachService = {
  __typename?: 'BeachService';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type BeachSportOffer = {
  __typename?: 'BeachSportOffer';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type BeachType = {
  __typename?: 'BeachType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** Filter for bookable cluster product specific criteria. */
export type BookableClusterProductModuleFilter = {
  /** Restrict results by their assigned hamburg cards. */
  hamburgCards?: InputMaybe<IntArrayPredicate>;
};

export type Booking = {
  __typename?: 'Booking';
  /** Access token for the booking. */
  accessToken: Scalars['String']['output'];
  /** If true, this booking was made through a sales channel, were we have correct data from customer (i.e. no reseller booking). */
  isUpgradable: Scalars['Boolean']['output'];
  /** Booking items, which are already in the booking. */
  items: Array<BookingItem>;
  /** Process identifier (aka "Buchungsnummer" from my.IRS TOMAS) */
  processId?: Maybe<Scalars['String']['output']>;
  /** Total price of booking (in EUR). */
  totalPrice: Scalars['Float']['output'];
};

/**
 * A booking is identifier by either its (processId AND lastname) OR by an access token. If both is provided, a valid access token wins.
 *
 * Thus please ensure you provide the necessary data. Otherwise you won't be able to retrieve the booking.
 */
export type BookingIdentifier = {
  /** Access token (like emarsys webaccesstoken) */
  accessToken?: InputMaybe<Scalars['String']['input']>;
  /** Lastname of customer within the specified booking. */
  lastname?: InputMaybe<Scalars['String']['input']>;
  /** Process identifier (aka "Buchungsnummer" from my.IRS TOMAS) */
  processId?: InputMaybe<Scalars['String']['input']>;
};

export type BookingItem = {
  /** Date from which the booking item is valid. */
  fromDate: Scalars['LocalDate']['output'];
  /**
   * ID of booking item.
   *
   * (Hint: it is not the id of the underlying service)
   */
  id: Scalars['String']['output'];
  /** Flag indicating if the booking item is still active. */
  isActive: Scalars['Boolean']['output'];
  /** Name of the booking item */
  name: Scalars['String']['output'];
  /** Internal service id of the booking item. */
  serviceId: Scalars['String']['output'];
  /** State of the booking item (i.e. ACTIVE, CANCELLED, ...) */
  state: BookingState;
  /** Total price of the booking item. */
  totalPrice: Scalars['Float']['output'];
};

export type BookingLink = {
  __typename?: 'BookingLink';
  _entityType?: Maybe<Scalars['String']['output']>;
  bookingLink?: Maybe<Scalars['String']['output']>;
  bookingLinkText?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export enum BookingState {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Test = 'TEST'
}

export type BoundingBoxFilter = {
  /** Geo point in the north west corner of the bounding box. */
  northWest?: InputMaybe<GeoPoint>;
  /** Geo point in the south east corner of the bounding box. */
  southEast?: InputMaybe<GeoPoint>;
};

export type BusinessType = {
  __typename?: 'BusinessType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Category = {
  __typename?: 'Category';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  parent?: Maybe<Category>;
};

export type CenterAddressbase = {
  __typename?: 'CenterAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  additionalExhibitionRooms?: Maybe<Scalars['Boolean']['output']>;
  capacity?: Maybe<CenterCapacity>;
  city?: Maybe<CityAddressbase>;
  cityDescription?: Maybe<Scalars['String']['output']>;
  cityHomepage?: Maybe<Scalars['String']['output']>;
  endowments?: Maybe<Array<CenterEndowment>>;
  eventTypes?: Maybe<Array<CenterEventType>>;
  forLanguages?: Maybe<Array<Language>>;
  gastronomy?: Maybe<CenterGastronomy>;
  hotels?: Maybe<Array<CenterHotel>>;
  id?: Maybe<Scalars['Int']['output']>;
  informationOrder?: Maybe<Scalars['Boolean']['output']>;
  infrastructure?: Maybe<CenterInfrastructure>;
  locationTypes?: Maybe<Array<ConventionLocationType>>;
  outdoorDescription?: Maybe<Scalars['String']['output']>;
  partnerMember?: Maybe<Scalars['Boolean']['output']>;
  roomDescription?: Maybe<Scalars['String']['output']>;
  rooms?: Maybe<Array<CenterRoom>>;
  specialEventType?: Maybe<Scalars['String']['output']>;
  specialLocationType?: Maybe<Scalars['String']['output']>;
  testimonial?: Maybe<Scalars['String']['output']>;
  topics?: Maybe<Array<CenterTopic>>;
};

export type CenterCapacity = {
  __typename?: 'CenterCapacity';
  _entityType?: Maybe<Scalars['String']['output']>;
  areaExhibition?: Maybe<Scalars['Int']['output']>;
  areaInside?: Maybe<Scalars['Int']['output']>;
  areaLargest?: Maybe<Scalars['Int']['output']>;
  areaOutside?: Maybe<Scalars['Int']['output']>;
  areaTotal?: Maybe<Scalars['Int']['output']>;
  areaWellness?: Maybe<Scalars['Int']['output']>;
  numberConferenceRooms?: Maybe<Scalars['Int']['output']>;
  numberGroupRooms?: Maybe<Scalars['String']['output']>;
  numberParkspaceGarage?: Maybe<Scalars['Int']['output']>;
  numberParkspaceOutdoor?: Maybe<Scalars['Int']['output']>;
  numberParkspaceOverall?: Maybe<Scalars['Int']['output']>;
  numberPax?: Maybe<Scalars['Int']['output']>;
  numberPersonsOverall?: Maybe<Scalars['Int']['output']>;
};

export type CenterDistance = {
  __typename?: 'CenterDistance';
  _entityType?: Maybe<Scalars['String']['output']>;
  distanceDestination?: Maybe<ConventionDistanceDestination>;
  distanceValue?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type CenterEndowment = {
  __typename?: 'CenterEndowment';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type CenterEventType = {
  __typename?: 'CenterEventType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type CenterGastronomy = {
  __typename?: 'CenterGastronomy';
  _entityType?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<ContactData>;
  gastronomyAttributes?: Maybe<Array<CenterGastronomyAttribute>>;
  restaurants?: Maybe<Array<CenterRestaurant>>;
};

export type CenterGastronomyAttribute = {
  __typename?: 'CenterGastronomyAttribute';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type CenterHotel = {
  __typename?: 'CenterHotel';
  _entityType?: Maybe<Scalars['String']['output']>;
  classification?: Maybe<Scalars['Int']['output']>;
  classificationSuperior?: Maybe<Scalars['Boolean']['output']>;
  distanceCongressCenter?: Maybe<Scalars['String']['output']>;
  homepage?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  numberDoubleRooms?: Maybe<Scalars['Int']['output']>;
  numberSingleRooms?: Maybe<Scalars['Int']['output']>;
  numberSuiteRooms?: Maybe<Scalars['Int']['output']>;
  roomCapacity?: Maybe<Scalars['Int']['output']>;
};

export type CenterInfrastructure = {
  __typename?: 'CenterInfrastructure';
  _entityType?: Maybe<Scalars['String']['output']>;
  distances?: Maybe<Array<CenterDistance>>;
  locationAttributes?: Maybe<Array<ConventionLocationAttribute>>;
};

export type CenterRestaurant = {
  __typename?: 'CenterRestaurant';
  _entityType?: Maybe<Scalars['String']['output']>;
  classification?: Maybe<Scalars['Int']['output']>;
  classificationSuperior?: Maybe<Scalars['Boolean']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  rsize?: Maybe<Scalars['Int']['output']>;
  seats?: Maybe<Scalars['Int']['output']>;
};

export type CenterRoom = {
  __typename?: 'CenterRoom';
  _entityType?: Maybe<Scalars['String']['output']>;
  banquet?: Maybe<Scalars['Int']['output']>;
  blockSeating?: Maybe<Scalars['Int']['output']>;
  combinations?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<ImageComponent>;
  length?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  parliament?: Maybe<Scalars['Int']['output']>;
  roomAttributes?: Maybe<Array<ConventionRoomAttribute>>;
  rsize?: Maybe<Scalars['Float']['output']>;
  seatsInCircle?: Maybe<Scalars['Int']['output']>;
  seatsInRows?: Maybe<Scalars['Int']['output']>;
  standupReception?: Maybe<Scalars['Int']['output']>;
  ushape?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type CenterTopic = {
  __typename?: 'CenterTopic';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Certificate = {
  __typename?: 'Certificate';
  _entityType?: Maybe<Scalars['String']['output']>;
  additionalInfo?: Maybe<Scalars['Boolean']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  logoImageUrl?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  modules?: Maybe<Array<Productline>>;
  requiresValidity?: Maybe<Scalars['Boolean']['output']>;
  valueType?: Maybe<Scalars['Int']['output']>;
};

export type CertificateClassValue = {
  __typename?: 'CertificateClassValue';
  _entityType?: Maybe<Scalars['String']['output']>;
  assignedCertificate?: Maybe<Certificate>;
  description?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  intValue?: Maybe<Scalars['Int']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  logoImageUrl?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
};

export type CertificateClassification = {
  __typename?: 'CertificateClassification';
  _entityType?: Maybe<Scalars['String']['output']>;
  certificate?: Maybe<Certificate>;
  enumValue?: Maybe<CertificateClassValue>;
  id?: Maybe<Scalars['Int']['output']>;
  information?: Maybe<Scalars['String']['output']>;
  intValue?: Maybe<Scalars['Int']['output']>;
  validityFromDate?: Maybe<Scalars['String']['output']>;
  validityToDate?: Maybe<Scalars['String']['output']>;
};

export type CertificationAddressbase = {
  __typename?: 'CertificationAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  certificateClassifications?: Maybe<Array<CertificateClassification>>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type CharterAddressbase = {
  __typename?: 'CharterAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  charterBoat?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  licenceFreeMotorBoats?: Maybe<Scalars['Boolean']['output']>;
  tandemSailing?: Maybe<Scalars['Boolean']['output']>;
};

export type CityAddressbase = {
  __typename?: 'CityAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  addressbase?: Maybe<Addressbase>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Client = {
  __typename?: 'Client';
  _entityType?: Maybe<Scalars['String']['output']>;
  address?: Maybe<AddressComponent>;
  id?: Maybe<Scalars['Int']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  logoImageUrl?: Maybe<Scalars['String']['output']>;
  mediaLicense?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Client>;
  reliabilityClassification?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
};

export type ClientPredicate = {
  /**
   * Predicate matching the client id.
   *
   * Hint: allOf semantic of IntArrayPredicate does not make sense in this context, as records are always associated with exactly one client.
   */
  id: IntArrayPredicate;
  /**
   * If true, the predicate will operate on all sub-clients of the given clients (for both oneOf or noneOf semantics).
   *
   * Default: false
   */
  includeSubClients?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClosedHoursDefinition = {
  __typename?: 'ClosedHoursDefinition';
  _entityType?: Maybe<Scalars['String']['output']>;
  additionalInformation?: Maybe<Scalars['String']['output']>;
  dateFrom?: Maybe<Scalars['String']['output']>;
  dateTo?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /**
   * A list of time intervals for the opening hour definition. There may be more intervals for a single day.
   *
   * The list is sorted by weekday and timeFrom by default.
   */
  timeIntervals?: Maybe<Array<TimeIntervalDefinition>>;
  yearly?: Maybe<Scalars['Boolean']['output']>;
};


export type ClosedHoursDefinitionTimeIntervalsArgs = {
  filter?: InputMaybe<TimeIntervalDefinitionFilter>;
};

export type CnvCenterModuleFilter = {
  /** Filter results by the amount of persons overall. */
  numberPersonsOverall?: InputMaybe<IntPredicate>;
};

export type CnvEventLocationModuleFilter = {
  /** Filter results by the amount of persons overall. */
  numberPersonsOverall?: InputMaybe<IntPredicate>;
};

export type CnvHotelAddressbase = {
  __typename?: 'CnvHotelAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  capacity?: Maybe<CnvHotelCapacity>;
  city?: Maybe<CityAddressbase>;
  classification?: Maybe<Scalars['Int']['output']>;
  classificationSuperior?: Maybe<Scalars['Boolean']['output']>;
  endowments?: Maybe<Array<CnvHotelEndowment>>;
  eventTypes?: Maybe<Array<CnvHotelEventType>>;
  forLanguages?: Maybe<Array<Language>>;
  gastronomy?: Maybe<CnvHotelGastronomy>;
  generalEndowments?: Maybe<Array<CnvHotelGeneralEndowment>>;
  id?: Maybe<Scalars['Int']['output']>;
  informationOrder?: Maybe<Scalars['Boolean']['output']>;
  infrastructure?: Maybe<CnvHotelInfrastructure>;
  locationTypes?: Maybe<Array<ConventionLocationType>>;
  outdoorDescription?: Maybe<Scalars['String']['output']>;
  partnerMember?: Maybe<Scalars['Boolean']['output']>;
  roomDescription?: Maybe<Scalars['String']['output']>;
  roomEndowments?: Maybe<Array<CnvHotelRoomEndowment>>;
  rooms?: Maybe<Array<CnvHotelRoom>>;
  specialEventType?: Maybe<Scalars['String']['output']>;
  specialLocationType?: Maybe<Scalars['String']['output']>;
  sportEndowments?: Maybe<Array<CnvHotelSportEndowment>>;
  testimonial?: Maybe<Scalars['String']['output']>;
  topics?: Maybe<Array<CnvHotelTopic>>;
};

export type CnvHotelCapacity = {
  __typename?: 'CnvHotelCapacity';
  _entityType?: Maybe<Scalars['String']['output']>;
  areaExhibition?: Maybe<Scalars['Int']['output']>;
  areaLargest?: Maybe<Scalars['Int']['output']>;
  areaWellness?: Maybe<Scalars['Int']['output']>;
  numberAccessibleRooms?: Maybe<Scalars['Int']['output']>;
  numberConferenceRooms?: Maybe<Scalars['Int']['output']>;
  numberDoubleRooms?: Maybe<Scalars['Int']['output']>;
  numberGroupRooms?: Maybe<Scalars['String']['output']>;
  numberParkspaceGarage?: Maybe<Scalars['Int']['output']>;
  numberParkspaceOutdoor?: Maybe<Scalars['Int']['output']>;
  numberParkspaceOverall?: Maybe<Scalars['Int']['output']>;
  numberPax?: Maybe<Scalars['Int']['output']>;
  numberPersons?: Maybe<Scalars['Int']['output']>;
  numberPersonsRestaurant?: Maybe<Scalars['Int']['output']>;
  numberRooms?: Maybe<Scalars['Int']['output']>;
  numberSharedRooms?: Maybe<Scalars['Int']['output']>;
  numberSingleRooms?: Maybe<Scalars['Int']['output']>;
  numberSuiteRooms?: Maybe<Scalars['Int']['output']>;
};

export type CnvHotelDistance = {
  __typename?: 'CnvHotelDistance';
  _entityType?: Maybe<Scalars['String']['output']>;
  distanceDestination?: Maybe<ConventionDistanceDestination>;
  distanceValue?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type CnvHotelEndowment = {
  __typename?: 'CnvHotelEndowment';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type CnvHotelEventType = {
  __typename?: 'CnvHotelEventType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type CnvHotelGastronomy = {
  __typename?: 'CnvHotelGastronomy';
  _entityType?: Maybe<Scalars['String']['output']>;
  restaurants?: Maybe<Array<CnvHotelRestaurant>>;
};

export type CnvHotelGeneralEndowment = {
  __typename?: 'CnvHotelGeneralEndowment';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type CnvHotelInfrastructure = {
  __typename?: 'CnvHotelInfrastructure';
  _entityType?: Maybe<Scalars['String']['output']>;
  distances?: Maybe<Array<CnvHotelDistance>>;
  locationAttributes?: Maybe<Array<ConventionLocationAttribute>>;
};

export type CnvHotelModuleFilter = {
  /** Filter results by the amount of persons. */
  numberPersons?: InputMaybe<IntPredicate>;
  /** Filter results by the amount of rooms. */
  numberRooms?: InputMaybe<IntPredicate>;
};

export type CnvHotelRestaurant = {
  __typename?: 'CnvHotelRestaurant';
  _entityType?: Maybe<Scalars['String']['output']>;
  classification?: Maybe<Scalars['Int']['output']>;
  classificationSuperior?: Maybe<Scalars['Boolean']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  rsize?: Maybe<Scalars['Int']['output']>;
  seats?: Maybe<Scalars['Int']['output']>;
};

export type CnvHotelRoom = {
  __typename?: 'CnvHotelRoom';
  _entityType?: Maybe<Scalars['String']['output']>;
  banquet?: Maybe<Scalars['Int']['output']>;
  blockSeating?: Maybe<Scalars['Int']['output']>;
  combinations?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<ImageComponent>;
  length?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  parliament?: Maybe<Scalars['Int']['output']>;
  roomAttributes?: Maybe<Array<ConventionRoomAttribute>>;
  rsize?: Maybe<Scalars['Float']['output']>;
  seatsInCircle?: Maybe<Scalars['Int']['output']>;
  seatsInRows?: Maybe<Scalars['Int']['output']>;
  standupReception?: Maybe<Scalars['Int']['output']>;
  ushape?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type CnvHotelRoomEndowment = {
  __typename?: 'CnvHotelRoomEndowment';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type CnvHotelSportEndowment = {
  __typename?: 'CnvHotelSportEndowment';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type CnvHotelTopic = {
  __typename?: 'CnvHotelTopic';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type ContactAddressPoi = {
  __typename?: 'ContactAddressPoi';
  _entityType?: Maybe<Scalars['String']['output']>;
  contact1?: Maybe<ContactData>;
  id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ContactAddressbase = {
  __typename?: 'ContactAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  contact1?: Maybe<ContactData>;
  id?: Maybe<Scalars['Int']['output']>;
  productlines?: Maybe<Array<Productline>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ContactData = {
  __typename?: 'ContactData';
  _entityType?: Maybe<Scalars['String']['output']>;
  address?: Maybe<AddressComponent>;
  contactName?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  salutation?: Maybe<Scalars['String']['output']>;
};

export type ContactListFilter = {
  entityType?: InputMaybe<EntityListEntityTypePredicate>;
  listTypes?: InputMaybe<IntArrayPredicate>;
  productlines?: InputMaybe<IntArrayPredicate>;
  purposes?: InputMaybe<IntArrayPredicate>;
};

export type ContactPurpose = {
  __typename?: 'ContactPurpose';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type ConventionDestinationType = {
  __typename?: 'ConventionDestinationType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  staticDestination?: Maybe<Scalars['Boolean']['output']>;
};

export type ConventionDistanceDestination = {
  __typename?: 'ConventionDistanceDestination';
  _entityType?: Maybe<Scalars['String']['output']>;
  destinationType?: Maybe<ConventionDestinationType>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type ConventionLocationAttribute = {
  __typename?: 'ConventionLocationAttribute';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type ConventionLocationType = {
  __typename?: 'ConventionLocationType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type ConventionRoomAttribute = {
  __typename?: 'ConventionRoomAttribute';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  _entityType?: Maybe<Scalars['String']['output']>;
  geoCenter?: Maybe<GeoObject>;
  geoCoords?: Maybe<GeoObject>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  maxLatitude?: Maybe<Scalars['Float']['output']>;
  maxLongitude?: Maybe<Scalars['Float']['output']>;
  minLatitude?: Maybe<Scalars['Float']['output']>;
  minLongitude?: Maybe<Scalars['Float']['output']>;
};

export type Criterion = {
  __typename?: 'Criterion';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type CurrentWeather = {
  /** Current overall weather condition. */
  condition: WeatherCondition;
  /** Date of the weather data (local time of requested location). */
  date: Scalars['LocalDate']['output'];
  /** Temperature in °C. */
  temperature: Scalars['Float']['output'];
  /** Time of the weather data (local time of requested location). */
  time: Scalars['LocalTime']['output'];
  /** Current wind conditions. */
  wind: WindCondition;
};

export type DailyWeatherForecast = {
  /** Overall weather condition. */
  condition: WeatherCondition;
  /** Date of the weather data (local time of requested location). */
  date: Scalars['LocalDate']['output'];
  /** Temperature range for the day. */
  temperature: TemperatureRange;
  /** Predominant Wind conditions for the day. */
  wind: WindCondition;
};

export type DataObjects = {
  __typename?: 'DataObjects';
  accommodationLanguages: Array<AccommodationLanguage>;
  accommodationTypes: Array<AccommodationType>;
  addressPoiGroups: Array<AddressPoiGroup>;
  addressbaseAttributes: Array<AddressbaseAttribute>;
  announcementTypes: Array<AnnouncementType>;
  basicInputFieldTypes: Array<BasicInputFieldType>;
  businessTypes: Array<BusinessType>;
  categories: Array<Category>;
  centerEndowments: Array<CenterEndowment>;
  centerEventTypes: Array<CenterEventType>;
  centerGastronomyAttributes: Array<CenterGastronomyAttribute>;
  centerTopics: Array<CenterTopic>;
  certificateClassValues: Array<CertificateClassValue>;
  certificates: Array<Certificate>;
  clients: Array<Client>;
  cnvHotelEndowments: Array<CnvHotelEndowment>;
  cnvHotelEventTypes: Array<CnvHotelEventType>;
  cnvHotelGeneralEndowments: Array<CnvHotelGeneralEndowment>;
  cnvHotelRoomEndowments: Array<CnvHotelRoomEndowment>;
  cnvHotelSportEndowments: Array<CnvHotelSportEndowment>;
  cnvHotelTopics: Array<CnvHotelTopic>;
  contactPurposes: Array<ContactPurpose>;
  conventionDestinationTypes: Array<ConventionDestinationType>;
  conventionDistanceDestinations: Array<ConventionDistanceDestination>;
  conventionLocationAttributes: Array<ConventionLocationAttribute>;
  conventionLocationTypes: Array<ConventionLocationType>;
  conventionRoomAttributes: Array<ConventionRoomAttribute>;
  criteria: Array<Criterion>;
  deliveryChannels: Array<DeliveryChannel>;
  distanceDefinitionGroups: Array<DistanceDefinitionGroup>;
  distanceDefinitions: Array<DistanceDefinition>;
  endowmentGroups: Array<EndowmentGroup>;
  endowments: Array<Endowment>;
  entityListContacts: Array<EntityListContact>;
  entityLists: Array<EntityList>;
  eventDateTags: Array<EventDateTag>;
  eventSeries: Array<EventSeries>;
  eventlocationEndowments: Array<EventlocationEndowment>;
  eventlocationEventTypes: Array<EventlocationEventType>;
  eventlocationGastronomyAttributes: Array<EventlocationGastronomyAttribute>;
  eventlocationTopics: Array<EventlocationTopic>;
  extendedAttributeDefinitionValues: Array<ExtendedAttributeDefinitionValue>;
  extendedAttributes: Array<ExtendedAttribute>;
  externalDataProviders: Array<ExternalDataProvider>;
  gastro2Ambiences: Array<Gastro2Ambience>;
  gastro2BusinessTypes: Array<Gastro2BusinessType>;
  gastro2CookingStyles: Array<Gastro2CookingStyle>;
  gastro2CuisineCharacteristics: Array<Gastro2CuisineCharacteristic>;
  gastro2CuisineTypes: Array<Gastro2CuisineType>;
  gastro2Endowments: Array<Gastro2Endowment>;
  gastro2EventSeries: Array<Gastro2EventSerie>;
  gastro2GuestGroups: Array<Gastro2GuestGroup>;
  gastro2OperationalFeatures: Array<Gastro2OperationalFeature>;
  gastro2Organizations: Array<Gastro2Organization>;
  gastro2PaymentOptions: Array<Gastro2PaymentOption>;
  gastro2PriceSegments: Array<Gastro2PriceSegment>;
  gastro2Products: Array<Gastro2Product>;
  gastro2ServiceTypes: Array<Gastro2ServiceType>;
  healthfinderCertifications: Array<HealthfinderCertification>;
  healthfinderCompetenceTypes: Array<HealthfinderCompetenceType>;
  healthfinderCureServices: Array<HealthfinderCureService>;
  healthfinderDiseaseICD10s: Array<HealthfinderDiseaseIcd10>;
  healthfinderEffectTypes: Array<HealthfinderEffectType>;
  healthfinderExerciseServices: Array<HealthfinderExerciseService>;
  healthfinderHealthServices: Array<HealthfinderHealthService>;
  healthfinderIndicationTypes: Array<HealthfinderIndicationType>;
  healthfinderInfrastructureTypes: Array<HealthfinderInfrastructureType>;
  healthfinderLocalCures: Array<HealthfinderLocalCure>;
  healthfinderLocationEndowments: Array<HealthfinderLocationEndowment>;
  healthfinderLocationRatings: Array<HealthfinderLocationRating>;
  healthfinderLocations: Array<HealthfinderLocation>;
  healthfinderRelaxationServices: Array<HealthfinderRelaxationService>;
  healthfinderSymptomTypes: Array<HealthfinderSymptomType>;
  healthfinderThalassoServices: Array<HealthfinderThalassoService>;
  healthfinderWellnessBeautyServices: Array<HealthfinderWellnessBeautyService>;
  hhtBathTypes: Array<HhtBathType>;
  hhtChurchTypes: Array<HhtChurchType>;
  hhtEventAddrAttributes: Array<HhtEventAddrAttribute>;
  hhtGastronomyLocationTypes: Array<HhtGastronomyLocationType>;
  hhtHamburgCardPartnerCategories: Array<HhtHamburgCardPartnerCategory>;
  hhtHamburgCards: Array<HhtHamburgCard>;
  hhtMaritimTypes: Array<HhtMaritimType>;
  hhtMuseumTypes: Array<HhtMuseumType>;
  hhtNightlifeLocationTypes: Array<HhtNightlifeLocationType>;
  hhtPoiActivityCategories: Array<HhtPoiActivityCategory>;
  hhtShoppingCategoryAttributes: Array<HhtShoppingCategoryAttribute>;
  hhtShoppingProductTypes: Array<HhtShoppingProductType>;
  hhtShoppingTargetGroups: Array<HhtShoppingTargetGroup>;
  hhtTheatreAttributes: Array<HhtTheatreAttribute>;
  hhtToursAttributes: Array<HhtToursAttribute>;
  ibeDeskline3ArtAndCultureInfrastructureTopic1s: Array<IbeDeskline3ArtAndCultureInfrastructureTopic1>;
  ibeDeskline3ArtAndCultureInfrastructureTopic2s: Array<IbeDeskline3ArtAndCultureInfrastructureTopic2>;
  ibeDeskline3FoodAndBeverageInfrastructureTopic1s: Array<IbeDeskline3FoodAndBeverageInfrastructureTopic1>;
  ibeDeskline3FoodAndBeverageInfrastructureTopic2s: Array<IbeDeskline3FoodAndBeverageInfrastructureTopic2>;
  ibeDeskline3InfrastructureHolidayThemes: Array<IbeDeskline3InfrastructureHolidayTheme>;
  ibeDeskline3InfrastructureTopic1s: Array<IbeDeskline3InfrastructureTopic1>;
  ibeDeskline3InfrastructureTopic2s: Array<IbeDeskline3InfrastructureTopic2>;
  ibeDeskline3MobilityAndTrafficInfrastructureTopic1s: Array<IbeDeskline3MobilityAndTrafficInfrastructureTopic1>;
  ibeDeskline3MobilityAndTrafficInfrastructureTopic2s: Array<IbeDeskline3MobilityAndTrafficInfrastructureTopic2>;
  ibeDeskline3PoiInfrastructureTopic1s: Array<IbeDeskline3PoiInfrastructureTopic1>;
  ibeDeskline3PoiInfrastructureTopic2s: Array<IbeDeskline3PoiInfrastructureTopic2>;
  ibeDeskline3RouteAndTourInfrastructureTopic1s: Array<IbeDeskline3RouteAndTourInfrastructureTopic1>;
  ibeDeskline3RouteAndTourInfrastructureTopic2s: Array<IbeDeskline3RouteAndTourInfrastructureTopic2>;
  ibeDeskline3SportAndLeisureInfrastructureTopic1s: Array<IbeDeskline3SportAndLeisureInfrastructureTopic1>;
  ibeDeskline3SportAndLeisureInfrastructureTopic2s: Array<IbeDeskline3SportAndLeisureInfrastructureTopic2>;
  ibeDeskline3WellnessAndHealthInfrastructureTopic1s: Array<IbeDeskline3WellnessAndHealthInfrastructureTopic1>;
  ibeDeskline3WellnessAndHealthInfrastructureTopic2s: Array<IbeDeskline3WellnessAndHealthInfrastructureTopic2>;
  imageTypes: Array<ImageType>;
  inputFieldTypes: Array<InputFieldType>;
  integrityEntityClassifications: Array<IntegrityEntityClassification>;
  locationDefinitions: Array<LocationDefinition>;
  locations: Array<Location>;
  mediaContents: Array<MediaContent>;
  mediaLicenses: Array<MediaLicense>;
  natureExperienceOfferCategories: Array<NatureExperienceOfferCategory>;
  occupancySensors: Array<OccupancySensor>;
  paymentOptions: Array<PaymentOption>;
  priceCategoryTypes: Array<PriceCategoryType>;
  priceCurrencies: Array<PriceCurrency>;
  priceDefinitionAttributes: Array<PriceDefinitionAttribute>;
  priceItemCategories: Array<PriceItemCategory>;
  priceValueTypes: Array<PriceValueType>;
  prioLevels: Array<PrioLevel>;
  producerTypeDefinitions: Array<ProducerTypeDefinition>;
  productGroups: Array<ProductGroup>;
  productlines: Array<Productline>;
  regions: Array<Region>;
  reliabilityEntityClassifications: Array<ReliabilityEntityClassification>;
  sailingAreas: Array<SailingArea>;
  sailingCourses: Array<SailingCourse>;
  sailingSchoolCertificates: Array<SailingSchoolCertificate>;
  socialMediaProviders: Array<SocialMediaProvider>;
  specialExperiences: Array<SpecialExperience>;
  surfKiteAdditionals: Array<SurfKiteAdditional>;
  surfKiteCertificates: Array<SurfKiteCertificate>;
  surfKiteCourses: Array<SurfKiteCourse>;
  tags: Array<Tag>;
  targetGroups: Array<TargetGroup>;
  topics: Array<Topic>;
  tourAttributes: Array<TourAttribute>;
  tourCategories: Array<TourCategory>;
  tourConditions: Array<TourCondition>;
  tourDifficulties: Array<TourDifficulty>;
  tourDifficultyGroups: Array<TourDifficultyGroup>;
  tourTrackTypes: Array<TourTrackType>;
  widgetConfigs: Array<WidgetConfig>;
};


export type DataObjectsAccommodationLanguagesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsAccommodationTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsAddressPoiGroupsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsAddressbaseAttributesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsAnnouncementTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsBasicInputFieldTypesArgs = {
  orderBy?: InputMaybe<Array<NameOrder>>;
};


export type DataObjectsBusinessTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsCategoriesArgs = {
  filter?: InputMaybe<HierarchicDataObjectFilter>;
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsCenterEndowmentsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsCenterEventTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsCenterGastronomyAttributesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsCenterTopicsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsCertificateClassValuesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsCertificatesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsClientsArgs = {
  filter?: InputMaybe<HierarchicDataObjectFilter>;
  orderBy?: InputMaybe<Array<NameOrder>>;
};


export type DataObjectsCnvHotelEndowmentsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsCnvHotelEventTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsCnvHotelGeneralEndowmentsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsCnvHotelRoomEndowmentsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsCnvHotelSportEndowmentsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsCnvHotelTopicsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsContactPurposesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsConventionDestinationTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<NameAndI18nOrder>>;
};


export type DataObjectsConventionDistanceDestinationsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsConventionLocationAttributesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsConventionLocationTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsConventionRoomAttributesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsCriteriaArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsDeliveryChannelsArgs = {
  orderBy?: InputMaybe<Array<NameOrder>>;
};


export type DataObjectsDistanceDefinitionGroupsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsDistanceDefinitionsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsEndowmentGroupsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsEndowmentsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsEntityListsArgs = {
  orderBy?: InputMaybe<Array<NameOrder>>;
};


export type DataObjectsEventDateTagsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsEventSeriesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsEventlocationEndowmentsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsEventlocationEventTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsEventlocationGastronomyAttributesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsEventlocationTopicsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsExtendedAttributeDefinitionValuesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsExtendedAttributesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsExternalDataProvidersArgs = {
  orderBy?: InputMaybe<Array<NameOrder>>;
};


export type DataObjectsGastro2AmbiencesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsGastro2BusinessTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsGastro2CookingStylesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsGastro2CuisineCharacteristicsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsGastro2CuisineTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsGastro2EndowmentsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsGastro2EventSeriesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsGastro2GuestGroupsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsGastro2OperationalFeaturesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsGastro2OrganizationsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsGastro2PaymentOptionsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsGastro2PriceSegmentsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsGastro2ProductsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsGastro2ServiceTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHealthfinderCertificationsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHealthfinderCompetenceTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHealthfinderCureServicesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHealthfinderDiseaseIcd10sArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHealthfinderEffectTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHealthfinderExerciseServicesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHealthfinderHealthServicesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHealthfinderIndicationTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHealthfinderInfrastructureTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHealthfinderLocalCuresArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHealthfinderLocationEndowmentsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHealthfinderLocationRatingsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHealthfinderLocationsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHealthfinderRelaxationServicesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHealthfinderSymptomTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHealthfinderThalassoServicesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHealthfinderWellnessBeautyServicesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHhtBathTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHhtChurchTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHhtEventAddrAttributesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHhtGastronomyLocationTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHhtHamburgCardPartnerCategoriesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHhtHamburgCardsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<NameAndI18nOrder>>;
};


export type DataObjectsHhtMaritimTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHhtMuseumTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHhtNightlifeLocationTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHhtPoiActivityCategoriesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHhtShoppingCategoryAttributesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHhtShoppingProductTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHhtShoppingTargetGroupsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHhtTheatreAttributesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsHhtToursAttributesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsIbeDeskline3ArtAndCultureInfrastructureTopic1sArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsIbeDeskline3ArtAndCultureInfrastructureTopic2sArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsIbeDeskline3FoodAndBeverageInfrastructureTopic1sArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsIbeDeskline3FoodAndBeverageInfrastructureTopic2sArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsIbeDeskline3InfrastructureHolidayThemesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsIbeDeskline3InfrastructureTopic1sArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsIbeDeskline3InfrastructureTopic2sArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsIbeDeskline3MobilityAndTrafficInfrastructureTopic1sArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsIbeDeskline3MobilityAndTrafficInfrastructureTopic2sArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsIbeDeskline3PoiInfrastructureTopic1sArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsIbeDeskline3PoiInfrastructureTopic2sArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsIbeDeskline3RouteAndTourInfrastructureTopic1sArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsIbeDeskline3RouteAndTourInfrastructureTopic2sArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsIbeDeskline3SportAndLeisureInfrastructureTopic1sArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsIbeDeskline3SportAndLeisureInfrastructureTopic2sArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsIbeDeskline3WellnessAndHealthInfrastructureTopic1sArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsIbeDeskline3WellnessAndHealthInfrastructureTopic2sArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsImageTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsInputFieldTypesArgs = {
  orderBy?: InputMaybe<Array<NameOrder>>;
};


export type DataObjectsIntegrityEntityClassificationsArgs = {
  orderBy?: InputMaybe<Array<NameOrder>>;
};


export type DataObjectsLocationDefinitionsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsLocationsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<NameAndI18nOrder>>;
};


export type DataObjectsMediaContentsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsMediaLicensesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsNatureExperienceOfferCategoriesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsOccupancySensorsArgs = {
  orderBy?: InputMaybe<Array<NameOrder>>;
};


export type DataObjectsPaymentOptionsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsPriceCategoryTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsPriceCurrenciesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsPriceDefinitionAttributesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsPriceItemCategoriesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsPriceValueTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsPrioLevelsArgs = {
  orderBy?: InputMaybe<Array<NameOrder>>;
};


export type DataObjectsProducerTypeDefinitionsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsProductGroupsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsProductlinesArgs = {
  filter?: InputMaybe<HierarchicDataObjectFilter>;
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsRegionsArgs = {
  filter?: InputMaybe<HierarchicDataObjectFilter>;
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsReliabilityEntityClassificationsArgs = {
  orderBy?: InputMaybe<Array<NameOrder>>;
};


export type DataObjectsSailingAreasArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsSailingCoursesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsSailingSchoolCertificatesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsSocialMediaProvidersArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsSpecialExperiencesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsSurfKiteAdditionalsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsSurfKiteCertificatesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsSurfKiteCoursesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsTagsArgs = {
  orderBy?: InputMaybe<Array<NameOrder>>;
};


export type DataObjectsTargetGroupsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsTopicsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsTourAttributesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<NameAndI18nOrder>>;
};


export type DataObjectsTourCategoriesArgs = {
  filter?: InputMaybe<HierarchicDataObjectFilter>;
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<NameAndI18nOrder>>;
};


export type DataObjectsTourConditionsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsTourDifficultiesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsTourDifficultyGroupsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};


export type DataObjectsTourTrackTypesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<I18nOrder>>;
};

export type DateRange = {
  __typename?: 'DateRange';
  max?: Maybe<Scalars['LocalDate']['output']>;
  min?: Maybe<Scalars['LocalDate']['output']>;
};

export type DateRangePredicate = {
  from?: InputMaybe<Scalars['LocalDate']['input']>;
  to?: InputMaybe<Scalars['LocalDate']['input']>;
};

export type DateTimeRangeFilter = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DayDefinition = {
  __typename?: 'DayDefinition';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DeliveryChannel = {
  __typename?: 'DeliveryChannel';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DistanceDefinition = {
  __typename?: 'DistanceDefinition';
  _entityType?: Maybe<Scalars['String']['output']>;
  group?: Maybe<DistanceDefinitionGroup>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type DistanceDefinitionGroup = {
  __typename?: 'DistanceDefinitionGroup';
  _entityType?: Maybe<Scalars['String']['output']>;
  distances?: Maybe<Array<DistanceDefinition>>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type DistanceFromPointFilter = {
  /** Distance in meters. */
  maxDistance: Scalars['NonNegativeInt']['input'];
  /** Geo point to search around. */
  point: GeoPoint;
};

export type Endowment = {
  __typename?: 'Endowment';
  _entityType?: Maybe<Scalars['String']['output']>;
  attributeGroup?: Maybe<EndowmentGroup>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type EndowmentGroup = {
  __typename?: 'EndowmentGroup';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
};

export type EntityList = {
  __typename?: 'EntityList';
  _entityType?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /**
   * Returns a paginated list of items in this entity list.
   *
   * The returned items will be filtered by the given language and appearance or fall back to the default filters of the api key (which may define a certain delivery channel for all your requests).
   */
  items?: Maybe<PaginatedEntityList>;
  lastChangeTime?: Maybe<Scalars['String']['output']>;
  listInfo?: Maybe<Scalars['String']['output']>;
  listInfoClient?: Maybe<Scalars['String']['output']>;
  listType?: Maybe<EntityListType>;
  name?: Maybe<Scalars['String']['output']>;
  shared?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<User>;
};


export type EntityListItemsArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type EntityListContact = {
  __typename?: 'EntityListContact';
  _entityType?: Maybe<Scalars['String']['output']>;
  contactList?: Maybe<EntityList>;
  contactPurpose?: Maybe<ContactPurpose>;
  contextProductline?: Maybe<Productline>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type EntityListEntityTypePredicate = {
  noneOf?: InputMaybe<Array<EntityListEntityTypes>>;
  oneOf?: InputMaybe<Array<EntityListEntityTypes>>;
};

export enum EntityListEntityTypes {
  Article = 'ARTICLE',
  Event = 'EVENT',
  Offer = 'OFFER',
  /** Addressbase or AddressPoi */
  Poi = 'POI'
}

export type EntityListNodeTypes = AddressPoi | Addressbase | Article | Event | Offer;

export type EntityListType = {
  __typename?: 'EntityListType';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum EntityState {
  Deactivated = 'DEACTIVATED',
  InProgress = 'IN_PROGRESS',
  Published = 'PUBLISHED'
}

export enum EntityType {
  Addressbase = 'Addressbase',
  Announcement = 'Announcement',
  Article = 'Article',
  Event = 'Event',
  EventDate = 'EventDate',
  Offer = 'Offer',
  Typo3Document = 'Typo3Document'
}

export type EntityTypePredicate = {
  noneOf?: InputMaybe<Array<EntityType>>;
  oneOf?: InputMaybe<Array<EntityType>>;
};

export type Event = {
  __typename?: 'Event';
  _entityType?: Maybe<Scalars['String']['output']>;
  additionalBookingLinks?: Maybe<Array<BookingLink>>;
  additionalInfo?: Maybe<Scalars['String']['output']>;
  advanceBookingStartDate?: Maybe<Scalars['String']['output']>;
  advanceBookingStartTime?: Maybe<Scalars['String']['output']>;
  alternateLanguage?: Maybe<Scalars['String']['output']>;
  appBookingLink?: Maybe<Scalars['String']['output']>;
  appearances?: Maybe<Array<BasicAppearance>>;
  artist?: Maybe<Artist>;
  /** Returns the first attribute definition of this event. Can be filtered. */
  attributeDefinition?: Maybe<ExtendedAttributeDefinition>;
  /** Returns the attribute definitions of this event. Can be filtered. */
  attributeDefinitions?: Maybe<Array<ExtendedAttributeDefinition>>;
  author?: Maybe<Scalars['String']['output']>;
  bookingLink?: Maybe<Scalars['String']['output']>;
  bookingLinkText?: Maybe<Scalars['String']['output']>;
  cancelled?: Maybe<Scalars['Boolean']['output']>;
  cancelledEventDates?: Maybe<Array<EventDate>>;
  canonicalUrl?: Maybe<Scalars['String']['output']>;
  categories?: Maybe<Array<Category>>;
  client?: Maybe<Client>;
  /**
   * Returns the first contactList of this object. Can be filtered.
   *
   * The returned nodes will be filtered by the given language and appearance or fall back to the default filters of the api key (which may define a certain delivery channel for all your requests).
   */
  contactList?: Maybe<EntityListContact>;
  /**
   * Returns the contactLists of this object. Can be filtered.
   *
   * The returned nodes will be filtered by the given language and appearance or fall back to the default filters of the api key (which may define a certain delivery channel for all your requests).
   */
  contactLists?: Maybe<Array<EntityListContact>>;
  contentScore?: Maybe<Scalars['Float']['output']>;
  contributor?: Maybe<AddressPoi>;
  creationTime?: Maybe<Scalars['String']['output']>;
  criteria?: Maybe<Array<Criterion>>;
  dateInfo?: Maybe<Scalars['String']['output']>;
  entityState?: Maybe<EntityState>;
  eventContacts?: Maybe<Array<EventContact>>;
  eventDateType?: Maybe<EventDateType>;
  /** List of all event dates of this event. Will only contain future event dates (if no filter is provided). */
  eventDates?: Maybe<Array<EventDate>>;
  eventUser?: Maybe<User>;
  geoInfo?: Maybe<GeoInfo>;
  htmlHeadMetaDescription?: Maybe<Scalars['String']['output']>;
  htmlHeadTitle?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  importIdentifier?: Maybe<Scalars['String']['output']>;
  importInfos?: Maybe<Array<ImportInfo>>;
  information?: Maybe<Scalars['String']['output']>;
  /** Returns the first inputfield of this event. Can be filtered and sorted. */
  inputField?: Maybe<EventInputField>;
  /** Returns the inputfields of this event. Can be filtered and sorted. */
  inputFields?: Maybe<Array<EventInputField>>;
  integrityClassification?: Maybe<IntegrityEntityClassification>;
  languages?: Maybe<Array<Language>>;
  lastChangeTime?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  linkText?: Maybe<Scalars['String']['output']>;
  location?: Maybe<AddressPoi>;
  longDescription?: Maybe<Scalars['String']['output']>;
  /** Returns the assigned media for this event. */
  media?: Maybe<Array<EventMedia>>;
  mediaLicense?: Maybe<MediaLicense>;
  /** Returns the first assigned medium for this event. Can be filtered and sorted. */
  medium?: Maybe<EventMedia>;
  /** Checks if categories of this POI meet the provided id predicate. */
  meetsCategoryPredicate: Scalars['Boolean']['output'];
  /** Checks if criteria of this POI meet the provided id predicate. */
  meetsCriterionPredicate: Scalars['Boolean']['output'];
  noInternetPublication?: Maybe<Scalars['Boolean']['output']>;
  online?: Maybe<Scalars['String']['output']>;
  onlineParticipationLink?: Maybe<Scalars['String']['output']>;
  onlineParticipationLinkText?: Maybe<Scalars['String']['output']>;
  periodicalEvent?: Maybe<Scalars['Boolean']['output']>;
  /** Return the permaLink of the event. */
  permaLink?: Maybe<Scalars['String']['output']>;
  priceCategories?: Maybe<Array<PriceCategory>>;
  pricing?: Maybe<Pricing>;
  relevance?: Maybe<Relevance>;
  reliabilityClassification?: Maybe<ReliabilityEntityClassification>;
  /**
   * Get a schema.org representation.
   *
   * Hint: As this is an expensive operation, do not use it in a list query. Use it only for a single object request.
   */
  schemaOrg: SchemaOrgRepresentation;
  searchKeywords?: Maybe<Scalars['String']['output']>;
  series?: Maybe<Array<EventSeries>>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  subTitle?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Tag>>;
  targetGroups?: Maybe<Array<TargetGroup>>;
  ticketOnline?: Maybe<TicketOnline>;
  title?: Maybe<Scalars['String']['output']>;
};


export type EventAttributeDefinitionArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};


export type EventAttributeDefinitionsArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};


export type EventContactListArgs = {
  filter?: InputMaybe<ContactListFilter>;
};


export type EventContactListsArgs = {
  filter?: InputMaybe<ContactListFilter>;
};


export type EventEventDatesArgs = {
  filter?: InputMaybe<BasicEventDateFilter>;
  limit?: InputMaybe<Limits>;
};


export type EventInputFieldArgs = {
  filter?: InputMaybe<InputFieldFilter>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type EventInputFieldsArgs = {
  filter?: InputMaybe<InputFieldFilter>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type EventMediaArgs = {
  fallback?: InputMaybe<Array<MediaFilter>>;
  filter?: InputMaybe<MediaFilter>;
  limit?: InputMaybe<Limits>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type EventMediumArgs = {
  fallback?: InputMaybe<Array<MediaFilter>>;
  filter?: InputMaybe<MediaFilter>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type EventMeetsCategoryPredicateArgs = {
  predicate: IdPredicate;
};


export type EventMeetsCriterionPredicateArgs = {
  predicate: IdPredicate;
};


export type EventPermaLinkArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type EventContact = {
  __typename?: 'EventContact';
  _entityType?: Maybe<Scalars['String']['output']>;
  contactAddressPoi?: Maybe<ContactAddressPoi>;
  id?: Maybe<Scalars['Int']['output']>;
  purpose?: Maybe<ContactPurpose>;
};

export type EventDate = {
  __typename?: 'EventDate';
  _entityType?: Maybe<Scalars['String']['output']>;
  bookingLink?: Maybe<Scalars['String']['output']>;
  cancelled?: Maybe<Scalars['Boolean']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  event?: Maybe<Event>;
  id?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  online?: Maybe<Scalars['Boolean']['output']>;
  soldout?: Maybe<Scalars['Boolean']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<EventDateTag>>;
};

export enum EventDateFacetField {
  /** Build facets for event categories. */
  Category = 'CATEGORY',
  /** Build facets for event criteria. */
  Criterion = 'CRITERION'
}

export type EventDateFacetResult = {
  __typename?: 'EventDateFacetResult';
  fields?: Maybe<Array<EventDateFieldFacetResult>>;
  stats?: Maybe<Array<EventDateFieldStatsResult>>;
};

export type EventDateFacets = {
  fields?: InputMaybe<Array<EventDateFieldFacet>>;
  stats?: InputMaybe<Array<EventDateFieldStats>>;
};

export type EventDateFieldFacet = {
  /** Identifies the field that should bet treated as facet. */
  field: EventDateFacetField;
  /** Specifies predicates to filter out returned facets */
  filter?: InputMaybe<FacetFilterPredicate>;
  /** Provide an identifier for facet results. */
  identifier?: InputMaybe<Scalars['String']['input']>;
  /** Limits the number of returned facet counts. Default: 100 */
  limit?: Scalars['Int']['input'];
  /** Specifies the minimum count required for a facet to be included in the response. Default: 0 */
  mincount?: Scalars['Int']['input'];
};

export type EventDateFieldFacetData = Category | Criterion;

export type EventDateFieldFacetResult = {
  __typename?: 'EventDateFieldFacetResult';
  facets: Array<EventDateFieldFacetValue>;
  field: EventDateFacetField;
  identifier: Scalars['String']['output'];
};

export type EventDateFieldFacetValue = {
  __typename?: 'EventDateFieldFacetValue';
  count: Scalars['Int']['output'];
  data: EventDateFieldFacetData;
};

export type EventDateFieldStats = {
  /** Identifies the field that should bet treated as stats. */
  field: EventDateStatField;
  /** Provide an identifier for stats results. */
  identifier?: InputMaybe<Scalars['String']['input']>;
};

export type EventDateFieldStatsResult = FieldStatsResult & {
  __typename?: 'EventDateFieldStatsResult';
  count: Scalars['NonNegativeInt']['output'];
  field: EventDateStatField;
  identifier: Scalars['String']['output'];
  value: FieldStatsResultValue;
};

export type EventDateFilter = {
  /** Add one or more filters to the query; if you provide several items to this field, they will be combined with a logical AND. */
  and?: InputMaybe<Array<EventDateFilter>>;
  /** Restricts results by event´s categories */
  categories?: InputMaybe<IntArrayPredicate>;
  /** Restrict results to a specific client. */
  client?: InputMaybe<ClientPredicate>;
  /** Constrain results by their contributor. */
  contributor?: InputMaybe<IntPredicate>;
  /** Restricts results by event´s criteria */
  criteria?: InputMaybe<IntArrayPredicate>;
  /** Restrict results by datetime range. */
  dateTimeRange?: InputMaybe<DateTimeRangeFilter>;
  /** Restrict results by event dates of events specified by their ids. */
  eventId?: InputMaybe<IntPredicate>;
  /** Constrain results by their details of their event location. */
  eventLocation?: InputMaybe<EventLocationFilter>;
  /** Restrict results event dates by date (from) */
  fromDate?: InputMaybe<Scalars['LocalDate']['input']>;
  /**
   * Constrain results by fulltext search query. Will be used as hard filter criterion, not as a ranking criterion.
   *
   * If you need a ranking criterion, use the 'query' parameter of the 'eventDates' query instead.
   */
  fulltext?: InputMaybe<Scalars['String']['input']>;
  /** Restricts results by geo bounds. */
  geoFilter?: InputMaybe<GeoFilter>;
  /**
   * Include event dates in result that meet the following criteria:
   * - Start time is before requested start datetime
   * - Have only one category
   * - Are of a special category (e.g. permanent exhibitions, ...)
   *
   * Caution: Constraint will only be evaluated if you filter also for fromDate and startTime.from! Requested start datetime will be defined by those constraints!
   */
  includeRunning?: InputMaybe<Scalars['Boolean']['input']>;
  /** Deprecated: Use 'eventLocation' filter instead */
  location?: InputMaybe<IntPredicate>;
  /** Add one or more inverted filters to the query. */
  not?: InputMaybe<Array<EventDateFilter>>;
  /** Add one or more filter to the query; if you provide several items to this field, they will be combined with a logical OR. */
  or?: InputMaybe<Array<EventDateFilter>>;
  /** Constrain results by their pricing. */
  pricingFilter?: InputMaybe<PricingFilter>;
  /** Constrain results by its region. */
  regions?: InputMaybe<IntArrayPredicate>;
  /** Constrain results by their event series. */
  series?: InputMaybe<IntArrayPredicate>;
  /**
   * startTime filter will be applied, on all event dates.
   *
   * Example: When you query a range of 3 days (with fromDate/toDate) and also apply a startTime predicate, you will get event dates, which take place in one of the three days and also match the startTime criterion
   *
   * Hint: If you want to implement a DateTimeRange predicate, use dateTimeRange predicate
   */
  startTime?: InputMaybe<TimeRangeFilter>;
  /** Restrict results event dates by date (to) */
  toDate?: InputMaybe<Scalars['LocalDate']['input']>;
};

export type EventDateOrder = {
  additional?: InputMaybe<EventDateOrderArguments>;
  field: EventDateSortFields;
  order?: InputMaybe<SortOrder>;
};

export type EventDateOrderArguments = {
  /** If provided results will not be ordered by exact geo distance but by distance buckets. The provided values will be the boundary distance between the buckets (in meters). */
  distanceFromPointBucketBoundaries?: InputMaybe<Array<Scalars['PositiveInt']['input']>>;
};

export enum EventDateSortFields {
  /**
   * Sort results by distance to a point. Filters are required to contain a distanceFromPoint constraint, which point will be used for sorting.
   *
   * As the distance from point will be an absolute order (as it is very unlikely that to results have exact the same distance), you can also pass bucket boundaries, which are used to group results by distance intervals.
   * Thus you have the ability to pass a further order field after DISTANCE_FROM_POINT which actually influences the result order. Just provide interval boundaries by passing EventDateOrder.additional.distanceFromPointBucketBoundaries.
   */
  DistanceFromPoint = 'DISTANCE_FROM_POINT',
  /** Sort results by event´s title */
  EventTitle = 'EVENT_TITLE',
  /** Sort results by from date. */
  Fromdate = 'FROMDATE',
  /** Sort results by start time. */
  Starttime = 'STARTTIME',
  /** Sort results to the front/back, to which the tip productline is assigned */
  Tip = 'TIP'
}

export enum EventDateStatField {
  Date = 'DATE',
  Starttime = 'STARTTIME'
}

export type EventDateTag = {
  __typename?: 'EventDateTag';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type EventDateType = {
  __typename?: 'EventDateType';
  _entityType?: Maybe<Scalars['String']['output']>;
  dayOfMonth?: Maybe<Scalars['Int']['output']>;
  dayOfWeek?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  gap?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  monthGap?: Maybe<Scalars['Int']['output']>;
  specificDayOfMonth?: Maybe<Scalars['Boolean']['output']>;
  specificDayOfWeek?: Maybe<Scalars['Boolean']['output']>;
  specificEventDates?: Maybe<Array<SpecificEventDate>>;
  startDate?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  startTimeDurations?: Maybe<Array<EventStartTimeDuration>>;
  type?: Maybe<EventDateTypeType>;
  weekDays?: Maybe<Array<EventDateTypeDay>>;
  weekdaysOnly?: Maybe<Scalars['Boolean']['output']>;
};

export type EventDateTypeDay = {
  __typename?: 'EventDateTypeDay';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type EventDateTypeType = {
  __typename?: 'EventDateTypeType';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum EventFacetField {
  /** Build facets for event categories. */
  Category = 'CATEGORY',
  /** Build facets for event criteria. */
  Criterion = 'CRITERION',
  /** Build facets for assigned location entity. */
  Location = 'LOCATION',
  /** Build facets for poi group. */
  PoiGroup = 'POI_GROUP',
  /** Build facets for assigned regions. */
  Region = 'REGION'
}

export type EventFacetResult = {
  __typename?: 'EventFacetResult';
  fields?: Maybe<Array<EventFieldFacetResult>>;
  stats?: Maybe<Array<EventFieldStatsResult>>;
};

export type EventFacets = {
  fields?: InputMaybe<Array<EventFieldFacet>>;
  stats?: InputMaybe<Array<EventFieldStats>>;
};

export type EventFieldFacet = {
  /** Identifies the field that should bet treated as facet. */
  field: EventFacetField;
  /** Specifies predicates to filter out returned facets */
  filter?: InputMaybe<FacetFilterPredicate>;
  /** Provide an identifier for facet results. */
  identifier?: InputMaybe<Scalars['String']['input']>;
  /** Limits the number of returned facet counts. Default: 100 */
  limit?: Scalars['Int']['input'];
  /** Specifies the minimum count required for a facet to be included in the response. Default: 0 */
  mincount?: Scalars['Int']['input'];
};

export type EventFieldFacetData = AddressPoiGroup | Category | Criterion | Location | Region;

export type EventFieldFacetResult = {
  __typename?: 'EventFieldFacetResult';
  facets: Array<EventFieldFacetValue>;
  field: EventFacetField;
  identifier: Scalars['String']['output'];
};

export type EventFieldFacetValue = {
  __typename?: 'EventFieldFacetValue';
  count: Scalars['Int']['output'];
  data: EventFieldFacetData;
};

export type EventFieldStats = {
  /** Identifies the field that should bet treated as stats. */
  field: EventStatField;
  /** Provide an identifier for stats results. */
  identifier?: InputMaybe<Scalars['String']['input']>;
};

export type EventFieldStatsResult = FieldStatsResult & {
  __typename?: 'EventFieldStatsResult';
  count: Scalars['NonNegativeInt']['output'];
  field: EventStatField;
  identifier: Scalars['String']['output'];
  value: FieldStatsResultValue;
};

export type EventFile = {
  __typename?: 'EventFile';
  _entityType?: Maybe<Scalars['String']['output']>;
  annually?: Maybe<Scalars['Boolean']['output']>;
  attributes?: Maybe<Array<MediaAttribute>>;
  deactivated?: Maybe<Scalars['Boolean']['output']>;
  deeplink?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['String']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  fromDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  identification?: Maybe<Scalars['String']['output']>;
  languages?: Maybe<Array<Language>>;
  pooledMedium?: Maybe<PooledEventMedium>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
  toDate?: Maybe<Scalars['String']['output']>;
};

export type EventFilter = {
  /** Add one or more filters to the query; if you provide several items to this field, they will be combined with a logical AND. */
  and?: InputMaybe<Array<EventFilter>>;
  /** Constrain results by their categories. */
  categories?: InputMaybe<IntArrayPredicate>;
  /** Restrict results to a specific client. */
  client?: InputMaybe<ClientPredicate>;
  /** Constrain results by their contributor. */
  contributor?: InputMaybe<IntPredicate>;
  /** Constrain results by their criteria. */
  criteria?: InputMaybe<IntArrayPredicate>;
  /** Constrain results by their event date tags. */
  eventDateTags?: InputMaybe<IntArrayPredicate>;
  /** Constrain results by their details of their event location. */
  eventLocation?: InputMaybe<EventLocationFilter>;
  /** Constrain results by their date. */
  fromDate?: InputMaybe<Scalars['LocalDate']['input']>;
  /**
   * Constrain results by fulltext search query. Will be used as hard filter criterion, not as a ranking criterion.
   *
   * If you need a ranking criterion, use the 'query' parameter of the 'events' query instead.
   */
  fulltext?: InputMaybe<Scalars['String']['input']>;
  /** Constrain results by their geo location. */
  geoFilter?: InputMaybe<GeoFilter>;
  /** Constrain results by their ids. */
  id?: InputMaybe<IntPredicate>;
  /** Constrain results by their import ids. */
  importId?: InputMaybe<StringPredicate>;
  /** Deprecated: Use 'eventLocation' filter instead */
  location?: InputMaybe<IntPredicate>;
  /** Add one or more inverted filters to the query. */
  not?: InputMaybe<Array<EventFilter>>;
  /**
   * Restrict results to events that are one-offs (i.e. events that are not part of a series).
   *
   * By default all events are returned, regardless of whether they are one-offs or part of a series.
   */
  onlyOneOffs?: InputMaybe<Scalars['Boolean']['input']>;
  /** Add one or more filter to the query; if you provide several items to this field, they will be combined with a logical OR. */
  or?: InputMaybe<Array<EventFilter>>;
  /** Constrain results by exact match of permalink (within the current language). */
  permaLink?: InputMaybe<StringPredicate>;
  /** Constrain results by their pricing. */
  pricingFilter?: InputMaybe<PricingFilter>;
  /** Constrain results by its region. */
  regions?: InputMaybe<IntArrayPredicate>;
  /** Constrain results by their event series. */
  series?: InputMaybe<IntArrayPredicate>;
  /**
   * Restrict results by start time. An event is matched, if it has (at least) one event date, which matches the given start time range filter.
   *
   * Beware: if you provide either fromDate or toDate, the startTime predicate will consider the given date range as well (i.e. it will match events, which have at least one event date matching the time range within the given date range).
   */
  startTime?: InputMaybe<TimeRangeFilter>;
  /** Constrain results by their date. */
  toDate?: InputMaybe<Scalars['LocalDate']['input']>;
};

export type EventImage = {
  __typename?: 'EventImage';
  _entityType?: Maybe<Scalars['String']['output']>;
  annually?: Maybe<Scalars['Boolean']['output']>;
  attributes?: Maybe<Array<MediaAttribute>>;
  deactivated?: Maybe<Scalars['Boolean']['output']>;
  deeplink?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['String']['output']>;
  focalPointX?: Maybe<Scalars['Int']['output']>;
  focalPointY?: Maybe<Scalars['Int']['output']>;
  fromDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  imageHeight?: Maybe<Scalars['String']['output']>;
  imageType?: Maybe<ImageType>;
  imageWidth?: Maybe<Scalars['String']['output']>;
  languages?: Maybe<Array<Language>>;
  link?: Maybe<Scalars['String']['output']>;
  pooledMedium?: Maybe<PooledEventMedium>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
  toDate?: Maybe<Scalars['String']['output']>;
};

export type EventInputField = {
  __typename?: 'EventInputField';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inputFieldType?: Maybe<InputFieldType>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type EventLocationFilter = {
  /** Constraint event location by their assigned poi group. */
  group?: InputMaybe<IntArrayPredicate>;
  /** Constrain location based on the ID of an events location */
  id?: InputMaybe<IntPredicate>;
  /** Constrain location based on the referenced location ID of an events location (= "Ort") */
  location?: InputMaybe<IntPredicate>;
  /** Constrain location based on the referenced region IDs of an events location */
  regions?: InputMaybe<IntArrayPredicate>;
  /** Constrain location based on the zipcode of an events location */
  zipcode?: InputMaybe<StringPredicate>;
};

export type EventMedia = EventFile | EventImage | EventVideo;

export type EventOrder = {
  additional?: InputMaybe<EventOrderArguments>;
  field: EventSortFields;
  order?: InputMaybe<SortOrder>;
};

export type EventOrderArguments = {
  /** If provided, the results will be ordered by the event dates beginning from the provided date. */
  dateAfter?: InputMaybe<Scalars['LocalDate']['input']>;
  /** If provided results will not be ordered by exact geo distance but by distance buckets. The provided values will be the boundary distance between the buckets (in meters). */
  distanceFromPointBucketBoundaries?: InputMaybe<Array<Scalars['PositiveInt']['input']>>;
  /**
   * If provided the pseudo random order will be initialized by this value; the sort order will be the same for subsequent calls, whenn providing the same initializer value.
   * This is especially important when paging through your results.
   */
  pseudoRandomInitializer?: InputMaybe<Scalars['PositiveInt']['input']>;
};

export type EventSeries = {
  __typename?: 'EventSeries';
  _entityType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  grantedClients?: Maybe<Array<Client>>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export enum EventSortFields {
  /**
   * Sort results by distance to a point. Filters are required to contain a distanceFromPoint constraint, which point will be used for sorting.
   *
   * As the distance from point will be an absolute order (as it is very unlikely that to results have exact the same distance), you can also pass bucket boundaries, which are used to group results by distance intervals.
   * Thus you have the ability to pass a further order field after DISTANCE_FROM_POINT which actually influences the result order. Just provide interval boundaries by passing EventOrder.additional.distanceFromPointBucketBoundaries.
   */
  DistanceFromPoint = 'DISTANCE_FROM_POINT',
  /**
   * Sort results by event´s next from date.
   *
   * If dateAfter is provided in the additional arguments, the results will be ordered by the event dates beginning from the provided date.
   * Otherwise, the results will be ordered by the next upcoming event date.
   */
  Fromdate = 'FROMDATE',
  /**
   * Sort results randomly.
   *
   * To support a stable order for folliwing pagination calls, we encourage you to provide a initializer value via AddressbaseOrder.additional.pseudoRandomInitializer
   */
  PseudoRandom = 'PSEUDO_RANDOM',
  /** Sort results to the front/back, to which the tip productline is assigned */
  Tip = 'TIP',
  /** Sort results by title */
  Title = 'TITLE'
}

export type EventStartTimeDuration = {
  __typename?: 'EventStartTimeDuration';
  _entityType?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
};

export enum EventStatField {
  Date = 'DATE'
}

export type EventVideo = {
  __typename?: 'EventVideo';
  _entityType?: Maybe<Scalars['String']['output']>;
  annually?: Maybe<Scalars['Boolean']['output']>;
  attributes?: Maybe<Array<MediaAttribute>>;
  deactivated?: Maybe<Scalars['Boolean']['output']>;
  deeplink?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['String']['output']>;
  fromDate?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  languages?: Maybe<Array<Language>>;
  pooledMedium?: Maybe<PooledEventMedium>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
  toDate?: Maybe<Scalars['String']['output']>;
  videoIdentification?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type EventlocationAddressbase = {
  __typename?: 'EventlocationAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  capacity?: Maybe<EventlocationCapacity>;
  eventTypes?: Maybe<Array<EventlocationEventType>>;
  eventlocationEndowments?: Maybe<Array<EventlocationEndowment>>;
  forLanguages?: Maybe<Array<Language>>;
  gastronomy?: Maybe<EventlocationGastronomy>;
  id?: Maybe<Scalars['Int']['output']>;
  informationOrder?: Maybe<Scalars['Boolean']['output']>;
  infrastructure?: Maybe<EventlocationInfrastructure>;
  locationTypes?: Maybe<Array<ConventionLocationType>>;
  outdoorDescription?: Maybe<Scalars['String']['output']>;
  partnerMember?: Maybe<Scalars['Boolean']['output']>;
  roomDescription?: Maybe<Scalars['String']['output']>;
  rooms?: Maybe<Array<EventlocationRoom>>;
  specialEventType?: Maybe<Scalars['String']['output']>;
  specialLocationType?: Maybe<Scalars['String']['output']>;
  testimonial?: Maybe<Scalars['String']['output']>;
  topics?: Maybe<Array<EventlocationTopic>>;
};

export type EventlocationCapacity = {
  __typename?: 'EventlocationCapacity';
  _entityType?: Maybe<Scalars['String']['output']>;
  areaExhibition?: Maybe<Scalars['Int']['output']>;
  areaIndoor?: Maybe<Scalars['Int']['output']>;
  areaLargest?: Maybe<Scalars['Int']['output']>;
  areaOutdoor?: Maybe<Scalars['Int']['output']>;
  areaOverall?: Maybe<Scalars['Int']['output']>;
  numberConferenceRooms?: Maybe<Scalars['Int']['output']>;
  numberGroupRooms?: Maybe<Scalars['String']['output']>;
  numberParkspaceGarage?: Maybe<Scalars['Int']['output']>;
  numberParkspaceOutdoor?: Maybe<Scalars['Int']['output']>;
  numberParkspaceOverall?: Maybe<Scalars['Int']['output']>;
  numberPax?: Maybe<Scalars['Int']['output']>;
  numberPersonsIndoor?: Maybe<Scalars['Int']['output']>;
  numberPersonsOutdoor?: Maybe<Scalars['Int']['output']>;
  numberPersonsOverall?: Maybe<Scalars['Int']['output']>;
};

export type EventlocationDistance = {
  __typename?: 'EventlocationDistance';
  _entityType?: Maybe<Scalars['String']['output']>;
  distanceDestination?: Maybe<ConventionDistanceDestination>;
  distanceValue?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type EventlocationEndowment = {
  __typename?: 'EventlocationEndowment';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type EventlocationEventType = {
  __typename?: 'EventlocationEventType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type EventlocationGastronomy = {
  __typename?: 'EventlocationGastronomy';
  _entityType?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<ContactData>;
  gastronomyAttributes?: Maybe<Array<EventlocationGastronomyAttribute>>;
  restaurants?: Maybe<Array<EventlocationRestaurant>>;
};

export type EventlocationGastronomyAttribute = {
  __typename?: 'EventlocationGastronomyAttribute';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type EventlocationInfrastructure = {
  __typename?: 'EventlocationInfrastructure';
  _entityType?: Maybe<Scalars['String']['output']>;
  distances?: Maybe<Array<EventlocationDistance>>;
  locationAttributes?: Maybe<Array<ConventionLocationAttribute>>;
};

export type EventlocationRestaurant = {
  __typename?: 'EventlocationRestaurant';
  _entityType?: Maybe<Scalars['String']['output']>;
  classification?: Maybe<Scalars['Int']['output']>;
  classificationSuperior?: Maybe<Scalars['Boolean']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  rsize?: Maybe<Scalars['Int']['output']>;
  seats?: Maybe<Scalars['Int']['output']>;
};

export type EventlocationRoom = {
  __typename?: 'EventlocationRoom';
  _entityType?: Maybe<Scalars['String']['output']>;
  banquet?: Maybe<Scalars['Int']['output']>;
  blockSeating?: Maybe<Scalars['Int']['output']>;
  combinations?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<ImageComponent>;
  length?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  parliament?: Maybe<Scalars['Int']['output']>;
  roomAttributes?: Maybe<Array<ConventionRoomAttribute>>;
  rsize?: Maybe<Scalars['Float']['output']>;
  seatsInCircle?: Maybe<Scalars['Int']['output']>;
  seatsInRows?: Maybe<Scalars['Int']['output']>;
  standupReception?: Maybe<Scalars['Int']['output']>;
  ushape?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type EventlocationTopic = {
  __typename?: 'EventlocationTopic';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type ExtendedAttribute = {
  __typename?: 'ExtendedAttribute';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
};

export type ExtendedAttributeDefinition = {
  __typename?: 'ExtendedAttributeDefinition';
  _entityType?: Maybe<Scalars['String']['output']>;
  attribute?: Maybe<ExtendedAttribute>;
  doubleValue?: Maybe<Scalars['Float']['output']>;
  entityIdValue?: Maybe<Scalars['Int']['output']>;
  enumValue?: Maybe<ExtendedAttributeDefinitionValue>;
  i18nInformation?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  information?: Maybe<Scalars['String']['output']>;
  intValue?: Maybe<Scalars['Int']['output']>;
  validityFromDate?: Maybe<Scalars['String']['output']>;
  validityToDate?: Maybe<Scalars['String']['output']>;
};

export type ExtendedAttributeDefinitionValue = {
  __typename?: 'ExtendedAttributeDefinitionValue';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  intValue?: Maybe<Scalars['Int']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
};

export type ExternalDataProvider = {
  __typename?: 'ExternalDataProvider';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  importSource?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Specifies predicates for facet fields. Returned facets will all meet these predicates. All predicates will be combined in a logical AND fashion. */
export type FacetFilterPredicate = {
  id?: InputMaybe<IntPredicate>;
  name?: InputMaybe<StringPredicate>;
};

export type FieldStatsResult = {
  count: Scalars['NonNegativeInt']['output'];
  identifier: Scalars['String']['output'];
  value?: Maybe<FieldStatsResultValue>;
};

export type FieldStatsResultValue = FloatFieldStatsResultValue | IntFieldStatsResultValue | LocalDateFieldStatsResultValue | LocalTimeFieldStatsResultValue;

export type FloatFieldStatsResultValue = {
  __typename?: 'FloatFieldStatsResultValue';
  floatMax?: Maybe<Scalars['Float']['output']>;
  floatMin?: Maybe<Scalars['Float']['output']>;
};

export type FloatPredicate = {
  /** Constrain results by values greater than the given number. Cannot be used with `gte` at the same time! */
  gt?: InputMaybe<Scalars['Float']['input']>;
  /** Constrain results by values greater than or equal to the given number. Cannot be used with `gt` at the same time! */
  gte?: InputMaybe<Scalars['Float']['input']>;
  /** Constrain results by values less than the given number. Cannot be used with `lte` at the same time! */
  lt?: InputMaybe<Scalars['Float']['input']>;
  /** Constrain results by values less than or equal to the given number. Cannot be used with `lt` at the same time! */
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type FloatRange = {
  __typename?: 'FloatRange';
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

export type FloatRangePredicate = {
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
};

export type FulltextSearchEntityType = {
  __typename?: 'FulltextSearchEntityType';
  name: EntityType;
};

export enum FulltextSearchFacetField {
  /** Facet by category. */
  Category = 'CATEGORY',
  /** Facet by criterion. */
  Criterion = 'CRITERION',
  /** Facet by entity type. */
  EntityType = 'ENTITY_TYPE',
  /** Facet by productlines (only applicable for imx.Platform based types). */
  Productline = 'PRODUCTLINE'
}

export type FulltextSearchFacetResult = {
  __typename?: 'FulltextSearchFacetResult';
  fields?: Maybe<Array<FulltextSearchFieldFacetResult>>;
};

export type FulltextSearchFacets = {
  fields?: InputMaybe<Array<FulltextSearchFieldFacet>>;
};

export type FulltextSearchFieldFacet = {
  /** Identifies the field that should bet treated as facet. */
  field: FulltextSearchFacetField;
  /** Specifies predicates to filter out returned facets */
  filter?: InputMaybe<FacetFilterPredicate>;
  /** Provide an identifier for facet results. */
  identifier?: InputMaybe<Scalars['String']['input']>;
  /** Limits the number of returned facet counts. Default: 100 */
  limit?: Scalars['Int']['input'];
  /** Specifies the minimum count required for a facet to be included in the response. Default: 0 */
  mincount?: Scalars['Int']['input'];
};

export type FulltextSearchFieldFacetData = Category | Criterion | FulltextSearchEntityType | Productline;

export type FulltextSearchFieldFacetResult = {
  __typename?: 'FulltextSearchFieldFacetResult';
  facets: Array<FulltextSearchFieldFacetValue>;
  field: FulltextSearchFacetField;
  identifier: Scalars['String']['output'];
};

export type FulltextSearchFieldFacetValue = {
  __typename?: 'FulltextSearchFieldFacetValue';
  count: Scalars['Int']['output'];
  data: FulltextSearchFieldFacetData;
};

/** Apply constraints to fulltext search results for either only certain entity types or entity type specific filters. */
export type FulltextSearchFilter = {
  /**
   * Restrict announcement results by the following filter constraints.
   *
   * If entityType filter does not include or explicitly excludes announcements, this filter will be ignored.
   */
  announcementFilter?: InputMaybe<AnnouncementFilter>;
  /**
   * Restrict article results by the following filter constraints.
   *
   * If entityType filter does not include or explicitly excludes articles, this filter will be ignored.
   */
  articleFilter?: InputMaybe<ArticleFilter>;
  /** Restrict results by entity type. By default only objects of type Addressbase/POI, Announcement, Article, Event, EventDate or Offer are returned. */
  entityType?: InputMaybe<EntityTypePredicate>;
  /**
   * Restrict event date results by the following filter constraints.
   *
   * If entityType filter does not include or explicitly excludes event dates, this filter will be ignored.
   */
  eventDateFilter?: InputMaybe<EventDateFilter>;
  /**
   * Restrict event results by the following filter constraints.
   *
   * If entityType filter does not include or explicitly excludes events, this filter will be ignored.
   */
  eventFilter?: InputMaybe<EventFilter>;
  /**
   * Deprecated: Use 'query' parameter instead
   *
   * Query string to search in all entity types for.
   */
  fulltext?: InputMaybe<Scalars['String']['input']>;
  /**
   * Restrict offer results by the following filter constraints.
   *
   * If entityType filter does not include or explicitly excludes offers, this filter will be ignored.
   */
  offerFilter?: InputMaybe<OfferFilter>;
  /**
   * Restrict poi results by the following filter constraints.
   *
   * If entityType filter does not include or explicitly excludes addressbase, this filter will be ignored.
   */
  poiFilter?: InputMaybe<AddressbaseFilter>;
  /** Restrict Typo3 results by the following filter constraints. */
  typo3Filter?: InputMaybe<Typo3Filter>;
};

export type FulltextSearchOrder = {
  additional?: InputMaybe<FulltextSearchOrderArguments>;
  field: FulltextSearchSortFields;
  order?: InputMaybe<SortOrder>;
};

export type FulltextSearchOrderArguments = {
  /** If provided, the results will be ordered by the dates beginning from the provided date. Currently only supported for Events. */
  dateAfter?: InputMaybe<Scalars['LocalDate']['input']>;
  /**
   * If provided the pseudo random order will be initialized by this value; the sort order will be the same for subsequent calls, whenn providing the same initializer value.
   * This is especially important when paging through your results.
   */
  pseudoRandomInitializer?: InputMaybe<Scalars['PositiveInt']['input']>;
};

export type FulltextSearchResultItem = Addressbase | Announcement | Article | Event | EventDate | Offer | Typo3Document;

export enum FulltextSearchSortFields {
  /**
   * Sort results by date.
   *
   * Usage of this sort order makes only sense, when event or event dates result types are allowed. Best results are achieved, when querying only event or event dates objects, but then it may be better to use Query.events or Query.eventDates instead.
   */
  Date = 'DATE',
  /**
   * Sort results randomly.
   *
   * To support a stable order for following pagination calls, we encourage you to provide an initializer value via FulltextSearchOrder.additional.pseudoRandomInitializer.
   */
  PseudoRandom = 'PSEUDO_RANDOM',
  /**
   * Sort results by score based on the fulltext search query.
   *
   * To get the most relevant results first, we recommend using a descending sort order. Otherwise the least relevant results get returned first.
   */
  Score = 'SCORE',
  /** Sort results by title */
  Title = 'TITLE'
}

export type Gastro2Addressbase = {
  __typename?: 'Gastro2Addressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  ambience?: Maybe<Array<Gastro2Ambience>>;
  /** Returns the first attribute definition of this event. Can be filtered. */
  attributeDefinition?: Maybe<ExtendedAttributeDefinition>;
  /** Returns the attribute definitions of this event. Can be filtered. */
  attributeDefinitions?: Maybe<Array<ExtendedAttributeDefinition>>;
  awards?: Maybe<Scalars['String']['output']>;
  businessTypes?: Maybe<Array<Gastro2BusinessType>>;
  cookingStyle?: Maybe<Array<Gastro2CookingStyle>>;
  cuisineCharacteristics?: Maybe<Array<Gastro2CuisineCharacteristic>>;
  cuisineTypes?: Maybe<Array<Gastro2CuisineType>>;
  emailReservationRequests?: Maybe<Scalars['String']['output']>;
  endowments?: Maybe<Array<Gastro2Endowment>>;
  eventSeries?: Maybe<Array<Gastro2EventSerie>>;
  guestGroups?: Maybe<Array<Gastro2GuestGroup>>;
  id?: Maybe<Scalars['Int']['output']>;
  menuUrl?: Maybe<Scalars['String']['output']>;
  openingHours?: Maybe<Scalars['String']['output']>;
  operationalFeatures?: Maybe<Array<Gastro2OperationalFeature>>;
  organizations?: Maybe<Array<Gastro2Organization>>;
  paymentOptions?: Maybe<Array<Gastro2PaymentOption>>;
  priceAppetizerMax?: Maybe<Scalars['Float']['output']>;
  priceAppetizerMin?: Maybe<Scalars['Float']['output']>;
  priceAverage?: Maybe<Scalars['String']['output']>;
  priceDessertMax?: Maybe<Scalars['Float']['output']>;
  priceDessertMin?: Maybe<Scalars['Float']['output']>;
  priceMainCourseMax?: Maybe<Scalars['Float']['output']>;
  priceMainCourseMin?: Maybe<Scalars['Float']['output']>;
  priceMenuMax?: Maybe<Scalars['Float']['output']>;
  priceMenuMin?: Maybe<Scalars['Float']['output']>;
  priceSegment?: Maybe<Gastro2PriceSegment>;
  products?: Maybe<Array<Gastro2Product>>;
  reservationUrl?: Maybe<Scalars['String']['output']>;
  seatsIndoor?: Maybe<Scalars['Int']['output']>;
  seatsInformation?: Maybe<Scalars['String']['output']>;
  seatsOutdoor?: Maybe<Scalars['Int']['output']>;
  serviceTypes?: Maybe<Array<Gastro2ServiceType>>;
  starClassification?: Maybe<Scalars['String']['output']>;
  transport?: Maybe<Scalars['String']['output']>;
};


export type Gastro2AddressbaseAttributeDefinitionArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};


export type Gastro2AddressbaseAttributeDefinitionsArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};

export type Gastro2Ambience = {
  __typename?: 'Gastro2Ambience';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Gastro2BusinessType = {
  __typename?: 'Gastro2BusinessType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Gastro2CookingStyle = {
  __typename?: 'Gastro2CookingStyle';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Gastro2CuisineCharacteristic = {
  __typename?: 'Gastro2CuisineCharacteristic';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Gastro2CuisineType = {
  __typename?: 'Gastro2CuisineType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Gastro2Endowment = {
  __typename?: 'Gastro2Endowment';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Gastro2EventSerie = {
  __typename?: 'Gastro2EventSerie';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Gastro2GuestGroup = {
  __typename?: 'Gastro2GuestGroup';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Gastro2OperationalFeature = {
  __typename?: 'Gastro2OperationalFeature';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Gastro2Organization = {
  __typename?: 'Gastro2Organization';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Gastro2PaymentOption = {
  __typename?: 'Gastro2PaymentOption';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Gastro2PriceSegment = {
  __typename?: 'Gastro2PriceSegment';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Gastro2Product = {
  __typename?: 'Gastro2Product';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Gastro2ServiceType = {
  __typename?: 'Gastro2ServiceType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type GastronomyModuleFilter = {
  /**
   * Filter results by their ambience.
   *
   * Tip: Possible values are defined by the data object "Gastro2Ambience".
   */
  ambience?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their business type, e.g. restaurant, beergarden, etc.
   *
   * Tip: Possible values are defined by the data object "Gastro2BusinessType".
   */
  businessTypes?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their cooking styles.
   *
   * Tip: Possible values are defined by the data object "Gastro2CookingStyle".
   */
  cookingStyles?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their cuisine characteristics, e.g. halal, vegan, etc.
   *
   * Tip: Possible values are defined by the data object "Gastro2CuisineCharacteristic".
   */
  cuisineCharacteristics?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their cuisine types, e.g. american, greek, italian, etc.
   *
   * Tip: Possible values are defined by the data object "Gastro2CuisineType".
   */
  cuisineTypes?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their endowments, e.g. wifi, outdoor seating, etc.
   *
   * Tip: Possible values are defined by the data object "Gastro2Endowment".
   */
  endowments?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their available group sizes, e.g. less than 25 people.
   *
   * Tip: Possible values are defined by the data object "Gastro2GuestGroup".
   */
  guestGroups?: InputMaybe<IntArrayPredicate>;
  /** Filter results by the pricing of the available menu. */
  menuPrice?: InputMaybe<FloatPredicate>;
  /**
   * Deprecated: Use 'menuPrice' instead.
   *
   * Filter results by the pricing of the available menu.
   */
  menuPriceRange?: InputMaybe<FloatRangePredicate>;
  /**
   * Filter results by their operational features.
   *
   * Tip: Possible values are defined by the data object "Gastro2OperationalFeature".
   */
  operationalFeatures?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by the organizations they are partaking in, e.g. Recup, Rebowl, etc.
   *
   * Tip: Possible values are defined by the data object "Gastro2Organization".
   */
  organizations?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their usable payment options, e.g. debit card, PayPal, etc.
   *
   * Tip: Possible values are defined by the data object "PaymentOption".
   */
  paymentOptions?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their assigned price segment, e.g. low, medium, high.
   *
   * Tip: Possible values are defined by the data object "Gastro2PriceSegment".
   */
  priceSegments?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their available products.
   *
   * Tip: Possible values are defined by the data object "Gastro2Product".
   */
  products?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their assigned service types, e.g. catering, delivery service, etc.
   *
   * Tip: Possible values are defined by the data object "Gastro2ServiceType".
   */
  serviceTypes?: InputMaybe<IntArrayPredicate>;
};

export type GeoFilter = {
  /** Bounding box search of items. */
  boundingBox?: InputMaybe<BoundingBoxFilter>;
  /** Circular search around a geo point. */
  distanceFromPoint?: InputMaybe<DistanceFromPointFilter>;
};

export type GeoInfo = {
  __typename?: 'GeoInfo';
  _entityType?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<Coordinates>;
  country?: Maybe<Scalars['String']['output']>;
  /**
   * Calculates the distance of the POI to a point (unit: m).
   *
   * If point argument is not provided AddressbaseFilter.geoFilter.distanceFromPoint.point is used (if not empty).
   */
  distanceFromPoint?: Maybe<Scalars['NonNegativeFloat']['output']>;
  /** When using DISTANCE_FROM_POINT order with AddressbaseOrderArguments.distanceFromPointBucketBoundaries this field returns the index of the distance bucket. */
  distanceFromPointBucket?: Maybe<Scalars['NonNegativeInt']['output']>;
  geoRegion?: Maybe<Region>;
  geoSystems?: Maybe<GeoSystems>;
  id?: Maybe<Scalars['Int']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNo?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};


export type GeoInfoDistanceFromPointArgs = {
  point?: InputMaybe<GeoPoint>;
};

export type GeoObject = {
  __typename?: 'GeoObject';
  geoJSON?: Maybe<Scalars['JSON']['output']>;
};

export type GeoPoint = {
  /** Latitude in decimal degrees. */
  latitude: Scalars['Latitude']['input'];
  /** Longitude in decimal degrees. */
  longitude: Scalars['Longitude']['input'];
};

export type GeoSystems = {
  __typename?: 'GeoSystems';
  _entityType?: Maybe<Scalars['String']['output']>;
};

export type HamburgCardBookingItem = BookingItem & {
  __typename?: 'HamburgCardBookingItem';
  /** Validity in days of Hamburg Card. */
  duration: Scalars['PositiveInt']['output'];
  /** Date from which the booking item is valid. */
  fromDate: Scalars['LocalDate']['output'];
  /**
   * ID of booking item.
   *
   * (Hint: it is not the id of the underlying service)
   */
  id: Scalars['String']['output'];
  /** Flag indicating if the booking item is still active. */
  isActive: Scalars['Boolean']['output'];
  /** Indicates if public transit is included in the ticket. */
  isPublicTransitIncluded: Scalars['Boolean']['output'];
  /** true if booking item is in future or today. */
  isValid: Scalars['Boolean']['output'];
  /** Name of the booking item */
  name: Scalars['String']['output'];
  /** Number of Hamburg Card tickets. */
  numberOfUnits: Scalars['PositiveInt']['output'];
  /** Internal service id of the booking item. */
  serviceId: Scalars['String']['output'];
  /** State of the booking item (i.e. ACTIVE, CANCELLED, ...) */
  state: BookingState;
  /** Determines type of ticket, i.e. single or group ticket. */
  ticketType: HamburgCardTicketType;
  /** Total price of the booking item. */
  totalPrice: Scalars['Float']['output'];
};

export type HamburgCardPartnerModuleFilter = {
  /** Restrict results by their assigned hamburg cards. */
  hamburgCards?: InputMaybe<IntArrayPredicate>;
};

export type HamburgCardProduct = HhtBaseProduct & {
  __typename?: 'HamburgCardProduct';
  /** Cumulated availability of all product variants. */
  availability?: Maybe<ProductAvailability>;
  /** Minimum price of chosen hamburg card product per unit (in EUR). */
  minPrice?: Maybe<Scalars['Float']['output']>;
  /** Internal service IDs. */
  serviceIds: Array<Scalars['String']['output']>;
  /** Cheapest product variant for given configuration. */
  variant?: Maybe<HamburgCardTicket>;
  /** Product variants (vacancy checked) for the given configuration. */
  variants?: Maybe<Array<HamburgCardTicket>>;
};


export type HamburgCardProductVariantArgs = {
  filter?: InputMaybe<HamburgCardProductVariantFilter>;
};


export type HamburgCardProductVariantsArgs = {
  filter?: InputMaybe<HamburgCardProductVariantFilter>;
};

export type HamburgCardProductVariantFilter = {
  /**
   * Filter by duration (days).
   *
   * Default: allow all offered durations
   */
  duration?: InputMaybe<IntPredicate>;
  /**
   * Default: today (defined by the server, i.e. according to CET/CEST)
   *
   * Must be today or in future.
   */
  fromDate?: InputMaybe<Scalars['LocalDate']['input']>;
  /**
   * (Only needed for total price calculation)
   *
   * Default: 1
   */
  numberOfTickets?: InputMaybe<Scalars['PositiveInt']['input']>;
  /**
   * Constrain by ticket type (e.g. single or group tickets)
   *
   * Default: allow all types
   */
  ticketType?: InputMaybe<HamburgCardTicketType>;
};

export type HamburgCardTicket = {
  __typename?: 'HamburgCardTicket';
  /** Validity duration of the hamburg card in days. */
  duration: Scalars['PositiveInt']['output'];
  /** Number of units */
  numberOfUnits: Scalars['PositiveInt']['output'];
  /** price per unit/person for the current configuration (in EUR). */
  pricePerUnit: Scalars['Float']['output'];
  /** Internal service id of the hamburg card. */
  serviceId: Scalars['String']['output'];
  /** Hamburg Card Ticket Type (single or group ticket). */
  ticketType: HamburgCardTicketType;
  /** Total price (for all units/persons) for the current configuration (in EUR). */
  totalPrice: Scalars['Float']['output'];
};

export enum HamburgCardTicketType {
  GroupTicket = 'GROUP_TICKET',
  SingleTicket = 'SINGLE_TICKET'
}

export type HealthfinderAddressbase = {
  __typename?: 'HealthfinderAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  certifications?: Maybe<Array<HealthfinderCertification>>;
  competenceTypes?: Maybe<Array<HealthfinderCompetenceType>>;
  cureServices?: Maybe<Array<HealthfinderCureService>>;
  diseases?: Maybe<Array<HealthfinderDiseaseIcd10>>;
  exerciseServices?: Maybe<Array<HealthfinderExerciseService>>;
  furtherCures?: Maybe<Scalars['String']['output']>;
  healthServices?: Maybe<Array<HealthfinderHealthService>>;
  id?: Maybe<Scalars['Int']['output']>;
  infrastructureTypes?: Maybe<Array<HealthfinderInfrastructureType>>;
  localCures?: Maybe<Array<HealthfinderLocalCure>>;
  locationEndowments?: Maybe<Array<HealthfinderLocationEndowment>>;
  locationRatings?: Maybe<Array<HealthfinderLocationRating>>;
  locationSpecificCure?: Maybe<Scalars['String']['output']>;
  locations?: Maybe<Array<HealthfinderLocation>>;
  relaxationServices?: Maybe<Array<HealthfinderRelaxationService>>;
  serviceDescription?: Maybe<Scalars['String']['output']>;
  specialCompetence?: Maybe<Scalars['String']['output']>;
  thalassoServices?: Maybe<Array<HealthfinderThalassoService>>;
  wellnessBeautyServices?: Maybe<Array<HealthfinderWellnessBeautyService>>;
};

export type HealthfinderCertification = {
  __typename?: 'HealthfinderCertification';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HealthfinderCompetenceType = {
  __typename?: 'HealthfinderCompetenceType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HealthfinderCureService = {
  __typename?: 'HealthfinderCureService';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HealthfinderDiseaseIcd10 = {
  __typename?: 'HealthfinderDiseaseICD10';
  _entityType?: Maybe<Scalars['String']['output']>;
  chapter?: Maybe<Scalars['String']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
};

export type HealthfinderEffectType = {
  __typename?: 'HealthfinderEffectType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HealthfinderExerciseService = {
  __typename?: 'HealthfinderExerciseService';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HealthfinderHealthService = {
  __typename?: 'HealthfinderHealthService';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HealthfinderIndicationType = {
  __typename?: 'HealthfinderIndicationType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HealthfinderInfrastructureType = {
  __typename?: 'HealthfinderInfrastructureType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HealthfinderLocalCure = {
  __typename?: 'HealthfinderLocalCure';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HealthfinderLocation = {
  __typename?: 'HealthfinderLocation';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HealthfinderLocationEndowment = {
  __typename?: 'HealthfinderLocationEndowment';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HealthfinderLocationRating = {
  __typename?: 'HealthfinderLocationRating';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HealthfinderOffer = {
  __typename?: 'HealthfinderOffer';
  _entityType?: Maybe<Scalars['String']['output']>;
  childrenSuitable?: Maybe<Scalars['Boolean']['output']>;
  cureServices?: Maybe<Array<HealthfinderCureService>>;
  diseases?: Maybe<Array<HealthfinderDiseaseIcd10>>;
  effectTypes?: Maybe<Array<HealthfinderEffectType>>;
  exerciseServices?: Maybe<Array<HealthfinderExerciseService>>;
  furtherCures?: Maybe<Scalars['String']['output']>;
  healthServices?: Maybe<Array<HealthfinderHealthService>>;
  id?: Maybe<Scalars['Int']['output']>;
  indicationTypes?: Maybe<Array<HealthfinderIndicationType>>;
  localCures?: Maybe<Array<HealthfinderLocalCure>>;
  locationSpecificCure?: Maybe<Scalars['String']['output']>;
  locations?: Maybe<Array<HealthfinderLocation>>;
  relaxationServices?: Maybe<Array<HealthfinderRelaxationService>>;
  specialCompetence?: Maybe<Scalars['String']['output']>;
  symptomTypes?: Maybe<Array<HealthfinderSymptomType>>;
  thalassoServices?: Maybe<Array<HealthfinderThalassoService>>;
  wellnessBeautyServices?: Maybe<Array<HealthfinderWellnessBeautyService>>;
};

export type HealthfinderRelaxationService = {
  __typename?: 'HealthfinderRelaxationService';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HealthfinderSymptomType = {
  __typename?: 'HealthfinderSymptomType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HealthfinderThalassoService = {
  __typename?: 'HealthfinderThalassoService';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HealthfinderWellnessBeautyService = {
  __typename?: 'HealthfinderWellnessBeautyService';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HhtBaseProduct = {
  /** Availabilities for the product - non-vacancy checked. */
  availability?: Maybe<ProductAvailability>;
  /** Minimum price of product per unit/adult (in EUR). */
  minPrice?: Maybe<Scalars['Float']['output']>;
  /** Internal service IDs. */
  serviceIds: Array<Scalars['String']['output']>;
};

export type HhtBathAddressbase = {
  __typename?: 'HhtBathAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  bathTypes?: Maybe<Array<Maybe<HhtBathType>>>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtBathType = {
  __typename?: 'HhtBathType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HhtBookableClusterProductOffer = {
  __typename?: 'HhtBookableClusterProductOffer';
  _entityType?: Maybe<Scalars['String']['output']>;
  benefits?: Maybe<Scalars['String']['output']>;
  hamburgCards?: Maybe<Array<HhtHamburgCard>>;
  id?: Maybe<Scalars['Int']['output']>;
  tomasVacancyProfile?: Maybe<Scalars['String']['output']>;
};

export type HhtCampingAddressbase = {
  __typename?: 'HhtCampingAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtChurchAddressbase = {
  __typename?: 'HhtChurchAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  churchTypes?: Maybe<Array<Maybe<HhtChurchType>>>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<HhtHamburgCardPartnerDiscount>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtChurchType = {
  __typename?: 'HhtChurchType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HhtCinemaAddressbase = {
  __typename?: 'HhtCinemaAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtCityWalkingAddressbase = {
  __typename?: 'HhtCityWalkingAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<HhtPoiActivityCategory>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<HhtHamburgCardPartnerDiscount>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtCruiseAddressbase = {
  __typename?: 'HhtCruiseAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtEventAddrAttribute = {
  __typename?: 'HhtEventAddrAttribute';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HhtEventAddressbase = {
  __typename?: 'HhtEventAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<HhtPoiActivityCategory>>;
  advantages?: Maybe<Scalars['String']['output']>;
  attributes?: Maybe<Array<HhtEventAddrAttribute>>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<HhtHamburgCardPartnerDiscount>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtEventlocationAddressbase = {
  __typename?: 'HhtEventlocationAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  attributes?: Maybe<Array<HhtEventlocationAttribute>>;
  autoTranslated?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  excludeClients?: Maybe<Array<Client>>;
  externalNotes?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  information?: Maybe<Scalars['String']['output']>;
  mainEventCategoryId?: Maybe<Scalars['Int']['output']>;
  openingHoursInformation?: Maybe<OpeningHoursInformation>;
};

export type HhtEventlocationAttribute = {
  __typename?: 'HhtEventlocationAttribute';
  _entityType?: Maybe<Scalars['String']['output']>;
  attributeGroup?: Maybe<I18nAttributeGroup>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HhtExperienceLeasureAddressbase = {
  __typename?: 'HhtExperienceLeasureAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtFaqItem = {
  __typename?: 'HhtFaqItem';
  _entityType?: Maybe<Scalars['String']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type HhtFaqOffer = {
  __typename?: 'HhtFaqOffer';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<HhtFaqItem>>;
};

export type HhtGastronomyAddressbase = {
  __typename?: 'HhtGastronomyAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  additionalCuisineType?: Maybe<Scalars['String']['output']>;
  additionalLocationDescription?: Maybe<Scalars['String']['output']>;
  advantages?: Maybe<Scalars['String']['output']>;
  ambiance?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  businessTypes?: Maybe<Array<Maybe<Gastro2BusinessType>>>;
  cuisineTypes?: Maybe<Array<Maybe<Gastro2CuisineType>>>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  locationTypes?: Maybe<Array<Maybe<HhtGastronomyLocationType>>>;
  menuUrl?: Maybe<Scalars['String']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  priceCategory?: Maybe<HhtGastronomyPriceCategory>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  reservationUrl?: Maybe<Scalars['String']['output']>;
  selection?: Maybe<Scalars['String']['output']>;
  specialHint?: Maybe<Scalars['String']['output']>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtGastronomyLocationType = {
  __typename?: 'HhtGastronomyLocationType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HhtGastronomyPriceCategory = {
  __typename?: 'HhtGastronomyPriceCategory';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type HhtGolfCityAddressbase = {
  __typename?: 'HhtGolfCityAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtGolfCourseAddressbase = {
  __typename?: 'HhtGolfCourseAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtGolfHotelAddressbase = {
  __typename?: 'HhtGolfHotelAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtHamburgCard = {
  __typename?: 'HhtHamburgCard';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type HhtHamburgCardPartnerAddressbase = {
  __typename?: 'HhtHamburgCardPartnerAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  categories?: Maybe<Array<Maybe<HhtHamburgCardPartnerCategory>>>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtHamburgCardPartnerCategory = {
  __typename?: 'HhtHamburgCardPartnerCategory';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HhtHamburgCardPartnerDiscount = {
  __typename?: 'HhtHamburgCardPartnerDiscount';
  _entityType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  hamburgCards?: Maybe<Array<Maybe<HhtHamburgCard>>>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notice?: Maybe<Scalars['String']['output']>;
};

export type HhtHotelAddressbase = {
  __typename?: 'HhtHotelAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtMaritimAddressbase = {
  __typename?: 'HhtMaritimAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  maritimTypes?: Maybe<Array<Maybe<HhtMaritimType>>>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtMaritimType = {
  __typename?: 'HhtMaritimType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HhtMiscAddressbase = {
  __typename?: 'HhtMiscAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<HhtHamburgCardPartnerDiscount>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtMobilityAddressbase = {
  __typename?: 'HhtMobilityAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtMuseumAddressbase = {
  __typename?: 'HhtMuseumAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<HhtPoiActivityCategory>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<HhtHamburgCardPartnerDiscount>>;
  id?: Maybe<Scalars['Int']['output']>;
  museumTypes?: Maybe<Array<HhtMuseumType>>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtMuseumType = {
  __typename?: 'HhtMuseumType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HhtNightlifeAddressbase = {
  __typename?: 'HhtNightlifeAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  locationTypes?: Maybe<Array<Maybe<HhtNightlifeLocationType>>>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtNightlifeLocationType = {
  __typename?: 'HhtNightlifeLocationType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HhtParkAddressbase = {
  __typename?: 'HhtParkAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtParkingAddressbase = {
  __typename?: 'HhtParkingAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtPoiActivityCategory = {
  __typename?: 'HhtPoiActivityCategory';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HhtPoiAddressbase = {
  __typename?: 'HhtPoiAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  businessStatus?: Maybe<HhtPoiBusinessStatus>;
  id?: Maybe<Scalars['Int']['output']>;
  openingHoursFooter?: Maybe<Scalars['String']['output']>;
  openingHoursInformation?: Maybe<OpeningHoursInformation>;
  openinghoursLocked?: Maybe<Scalars['Boolean']['output']>;
};

export type HhtPoiBusinessStatus = {
  __typename?: 'HhtPoiBusinessStatus';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type HhtPoiRecommendedVisitingTime = {
  __typename?: 'HhtPoiRecommendedVisitingTime';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type HhtPoiTimeExposure = {
  __typename?: 'HhtPoiTimeExposure';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type HhtPrintEvent = {
  __typename?: 'HhtPrintEvent';
  _entityType?: Maybe<Scalars['String']['output']>;
  autoTranslated?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  print1?: Maybe<Scalars['Boolean']['output']>;
  shortDescriptionPrint1?: Maybe<Scalars['String']['output']>;
  titlePrint1?: Maybe<Scalars['String']['output']>;
};

export type HhtRouteStationAddressbase = {
  __typename?: 'HhtRouteStationAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtShoppingAddressbase = {
  __typename?: 'HhtShoppingAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  categoryAttributes?: Maybe<Array<Maybe<HhtShoppingCategoryAttribute>>>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  productTypes?: Maybe<Array<Maybe<HhtShoppingProductType>>>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  targetGroups?: Maybe<Array<Maybe<HhtShoppingTargetGroup>>>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtShoppingCategoryAttribute = {
  __typename?: 'HhtShoppingCategoryAttribute';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HhtShoppingProductType = {
  __typename?: 'HhtShoppingProductType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HhtShoppingTargetGroup = {
  __typename?: 'HhtShoppingTargetGroup';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HhtSightAddressbase = {
  __typename?: 'HhtSightAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtSportAddressbase = {
  __typename?: 'HhtSportAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtSustainabilityAddressbase = {
  __typename?: 'HhtSustainabilityAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtTheatreAddressbase = {
  __typename?: 'HhtTheatreAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  attributes?: Maybe<Array<Maybe<HhtTheatreAttribute>>>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtTheatreAttribute = {
  __typename?: 'HhtTheatreAttribute';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HhtTouristInformationAddressbase = {
  __typename?: 'HhtTouristInformationAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtToursAddressbase = {
  __typename?: 'HhtToursAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityCategories?: Maybe<Array<Maybe<HhtPoiActivityCategory>>>;
  advantages?: Maybe<Scalars['String']['output']>;
  attributes?: Maybe<Array<Maybe<HhtToursAttribute>>>;
  barrierfreeCertified?: Maybe<Scalars['Boolean']['output']>;
  barrierfreeInformation?: Maybe<Scalars['String']['output']>;
  barrierfreeUrl?: Maybe<Scalars['String']['output']>;
  foursquareHours?: Maybe<Scalars['String']['output']>;
  foursquareResponse?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  hamburgCardPartnerDiscounts?: Maybe<Array<Maybe<HhtHamburgCardPartnerDiscount>>>;
  id?: Maybe<Scalars['Int']['output']>;
  precipitationProbabilityMax?: Maybe<Scalars['Float']['output']>;
  recommendedVisitingTime?: Maybe<HhtPoiRecommendedVisitingTime>;
  subHeader?: Maybe<Scalars['String']['output']>;
  temperatureMax?: Maybe<Scalars['Float']['output']>;
  temperatureMin?: Maybe<Scalars['Float']['output']>;
  timeExposure?: Maybe<HhtPoiTimeExposure>;
  widgetHeight?: Maybe<Scalars['String']['output']>;
  widgetUrl?: Maybe<Scalars['String']['output']>;
};

export type HhtToursAttribute = {
  __typename?: 'HhtToursAttribute';
  _entityType?: Maybe<Scalars['String']['output']>;
  attributeGroup?: Maybe<I18nAttributeGroup>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type HierarchicDataObjectFilter = {
  id?: InputMaybe<IntPredicate>;
  onlyRoot?: InputMaybe<Scalars['Boolean']['input']>;
  parentId?: InputMaybe<IntPredicate>;
};

export type HourDefinitionFilter = {
  /** Only include currently valid definitions (shortcut for providing `validOn = date(now)`; overrides validOn if set). */
  current?: InputMaybe<Scalars['Boolean']['input']>;
  /** Include only definitions that are valid on provided date. */
  validOn?: InputMaybe<Scalars['LocalDate']['input']>;
};

export type HourlyWeatherForecast = {
  /** Overall weather condition. */
  condition: WeatherCondition;
  /** Date of the weather data (local time of requested location). */
  date: Scalars['LocalDate']['output'];
  /** Temperature for the hour. */
  temperature: Scalars['Float']['output'];
  /** Time of the weather data (local time of requested location). */
  time: Scalars['LocalTime']['output'];
  /** Wind conditions for the hour. */
  wind: WindCondition;
};

export type I18n = {
  __typename?: 'I18n';
  _entityType?: Maybe<Scalars['String']['output']>;
  cz?: Maybe<Scalars['String']['output']>;
  da?: Maybe<Scalars['String']['output']>;
  de?: Maybe<Scalars['String']['output']>;
  en?: Maybe<Scalars['String']['output']>;
  fr?: Maybe<Scalars['String']['output']>;
  fy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  it?: Maybe<Scalars['String']['output']>;
  lb?: Maybe<Scalars['String']['output']>;
  nl?: Maybe<Scalars['String']['output']>;
  sv?: Maybe<Scalars['String']['output']>;
};

export type I18nAttributeGroup = {
  __typename?: 'I18nAttributeGroup';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type I18nOrder = {
  field: I18nSortFields;
  order?: InputMaybe<SortOrder>;
};

export enum I18nSortFields {
  I18NName = 'I18N_NAME',
  Id = 'ID'
}

export type IbeAccService = {
  __typename?: 'IbeAccService';
  _entityType?: Maybe<Scalars['String']['output']>;
  bookable?: Maybe<Scalars['Boolean']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  featureGroups?: Maybe<Array<Maybe<IbeTomasAddressbaseFeatureGroup>>>;
  id?: Maybe<Scalars['Int']['output']>;
  longDescription?: Maybe<Scalars['String']['output']>;
  serviceClassification?: Maybe<Scalars['String']['output']>;
  serviceType?: Maybe<IbeAccServiceType>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  /** Returns a list of service images. */
  urlImages?: Maybe<Array<Maybe<IbeUrlImage>>>;
};


export type IbeAccServiceUrlImagesArgs = {
  sortOrder?: InputMaybe<SortOrder>;
};

export type IbeAccServiceType = {
  __typename?: 'IbeAccServiceType';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeBookingComAddressbase = {
  __typename?: 'IbeBookingComAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  location?: Maybe<Location>;
  services?: Maybe<Array<IbeBookingComService>>;
};

export type IbeBookingComService = {
  __typename?: 'IbeBookingComService';
  _entityType?: Maybe<Scalars['String']['output']>;
  bookable?: Maybe<Scalars['Boolean']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  facilities?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  longDescription?: Maybe<Scalars['String']['output']>;
  onDemand?: Maybe<Scalars['Boolean']['output']>;
  serviceType?: Maybe<IbeAccServiceType>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  urlImages?: Maybe<Array<IbeUrlImage>>;
};

export type IbeDeskline3AccAddressbase = {
  __typename?: 'IbeDeskline3AccAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  arrivalVoucher?: Maybe<Scalars['String']['output']>;
  availabilityCalendarEnabled?: Maybe<Scalars['Boolean']['output']>;
  checkInOutTimes?: Maybe<IbeDeskline3CheckInOutTimes>;
  classifications?: Maybe<Array<IbeDeskline3Classification>>;
  conditions?: Maybe<Scalars['String']['output']>;
  dataOwner?: Maybe<Scalars['String']['output']>;
  databaseCode?: Maybe<Scalars['String']['output']>;
  deepLink?: Maybe<Scalars['String']['output']>;
  districtId?: Maybe<Scalars['String']['output']>;
  generalTermsConditions?: Maybe<Array<IbeDeskline3GeneralTermsConditions>>;
  holidayThemes?: Maybe<Array<IbeDeskline3HolidayTheme>>;
  id?: Maybe<Scalars['Int']['output']>;
  location?: Maybe<Location>;
  marketingGroups?: Maybe<Array<IbeDeskline3MarketingGroup>>;
  /** Returns a list of services of the accommodation. The service items are representing room types by default. */
  services?: Maybe<Array<IbeDeskline3Service>>;
  stars?: Maybe<Scalars['String']['output']>;
  starsName?: Maybe<Scalars['String']['output']>;
};


export type IbeDeskline3AccAddressbaseServicesArgs = {
  sortOrder?: InputMaybe<SortOrder>;
};

export type IbeDeskline3ArtAndCultureInfrastructureAddressbase = {
  __typename?: 'IbeDeskline3ArtAndCultureInfrastructureAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  holidayThemes?: Maybe<Array<IbeDeskline3InfrastructureHolidayTheme>>;
  id?: Maybe<Scalars['Int']['output']>;
  openingHours?: Maybe<Array<IbeDeskline3OpeningHours>>;
  openingHoursInformation?: Maybe<OpeningHoursInformation>;
  priceInformation?: Maybe<Scalars['String']['output']>;
  topPoi?: Maybe<Scalars['Boolean']['output']>;
  topics1?: Maybe<Array<IbeDeskline3ArtAndCultureInfrastructureTopic1>>;
  topics2?: Maybe<Array<IbeDeskline3ArtAndCultureInfrastructureTopic2>>;
};

export type IbeDeskline3ArtAndCultureInfrastructureTopic1 = {
  __typename?: 'IbeDeskline3ArtAndCultureInfrastructureTopic1';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3ArtAndCultureInfrastructureTopic2 = {
  __typename?: 'IbeDeskline3ArtAndCultureInfrastructureTopic2';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3AssignedProduct = {
  __typename?: 'IbeDeskline3AssignedProduct';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3BasePrice = {
  __typename?: 'IbeDeskline3BasePrice';
  _entityType?: Maybe<Scalars['String']['output']>;
  additionalBed?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  occupancyFrom?: Maybe<Scalars['String']['output']>;
  occupancyTo?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

export type IbeDeskline3CancellationPaymentPeriod = {
  __typename?: 'IbeDeskline3CancellationPaymentPeriod';
  _entityType?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  start?: Maybe<Scalars['String']['output']>;
};

export type IbeDeskline3CancellationPaymentTemplate = {
  __typename?: 'IbeDeskline3CancellationPaymentTemplate';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  freeCancellation?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  periods?: Maybe<Array<IbeDeskline3CancellationPaymentPeriod>>;
  useOwnCancellationSettings?: Maybe<Scalars['Boolean']['output']>;
  validFrom?: Maybe<Scalars['String']['output']>;
};

export type IbeDeskline3CheckInOutTimes = {
  __typename?: 'IbeDeskline3CheckInOutTimes';
  _entityType?: Maybe<Scalars['String']['output']>;
  checkInFrom?: Maybe<Scalars['String']['output']>;
  checkInTo?: Maybe<Scalars['String']['output']>;
  checkOutFrom?: Maybe<Scalars['String']['output']>;
  checkOutTo?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3Classification = {
  __typename?: 'IbeDeskline3Classification';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type IbeDeskline3ExtraCost = {
  __typename?: 'IbeDeskline3ExtraCost';
  _entityType?: Maybe<Scalars['String']['output']>;
  calcRule?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  nights?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type IbeDeskline3FoodAndBeverageInfrastructureAddressbase = {
  __typename?: 'IbeDeskline3FoodAndBeverageInfrastructureAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  holidayThemes?: Maybe<Array<IbeDeskline3InfrastructureHolidayTheme>>;
  id?: Maybe<Scalars['Int']['output']>;
  openingHours?: Maybe<Array<IbeDeskline3OpeningHours>>;
  openingHoursInformation?: Maybe<OpeningHoursInformation>;
  priceInformation?: Maybe<Scalars['String']['output']>;
  seatsInside?: Maybe<Scalars['Int']['output']>;
  seatsOutside?: Maybe<Scalars['Int']['output']>;
  toPrice?: Maybe<Scalars['Float']['output']>;
  topPoi?: Maybe<Scalars['Boolean']['output']>;
  topics1?: Maybe<Array<IbeDeskline3FoodAndBeverageInfrastructureTopic1>>;
  topics2?: Maybe<Array<IbeDeskline3FoodAndBeverageInfrastructureTopic2>>;
};

export type IbeDeskline3FoodAndBeverageInfrastructureTopic1 = {
  __typename?: 'IbeDeskline3FoodAndBeverageInfrastructureTopic1';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3FoodAndBeverageInfrastructureTopic2 = {
  __typename?: 'IbeDeskline3FoodAndBeverageInfrastructureTopic2';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3GeneralTermsConditions = {
  __typename?: 'IbeDeskline3GeneralTermsConditions';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3HolidayTheme = {
  __typename?: 'IbeDeskline3HolidayTheme';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3HousePackageMastersHolidayTheme = {
  __typename?: 'IbeDeskline3HousePackageMastersHolidayTheme';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3HousePackageMastersOffer = {
  __typename?: 'IbeDeskline3HousePackageMastersOffer';
  _entityType?: Maybe<Scalars['String']['output']>;
  assignedProducts?: Maybe<Array<IbeDeskline3AssignedProduct>>;
  holidayThemes?: Maybe<Array<IbeDeskline3HousePackageMastersHolidayTheme>>;
  id?: Maybe<Scalars['Int']['output']>;
  packageDescription?: Maybe<Scalars['String']['output']>;
};

export type IbeDeskline3InfrastructureAddressbase = {
  __typename?: 'IbeDeskline3InfrastructureAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  holidayThemes?: Maybe<Array<IbeDeskline3InfrastructureHolidayTheme>>;
  id?: Maybe<Scalars['Int']['output']>;
  openingHours?: Maybe<Array<IbeDeskline3OpeningHours>>;
  openingHoursInformation?: Maybe<OpeningHoursInformation>;
  priceInformation?: Maybe<Scalars['String']['output']>;
  topPoi?: Maybe<Scalars['Boolean']['output']>;
  topics1?: Maybe<Array<IbeDeskline3InfrastructureTopic1>>;
  topics2?: Maybe<Array<IbeDeskline3InfrastructureTopic2>>;
};

export type IbeDeskline3InfrastructureHolidayTheme = {
  __typename?: 'IbeDeskline3InfrastructureHolidayTheme';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3InfrastructureTopic1 = {
  __typename?: 'IbeDeskline3InfrastructureTopic1';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3InfrastructureTopic2 = {
  __typename?: 'IbeDeskline3InfrastructureTopic2';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3MarketingGroup = {
  __typename?: 'IbeDeskline3MarketingGroup';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3MobilityAndTrafficInfrastructureAddressbase = {
  __typename?: 'IbeDeskline3MobilityAndTrafficInfrastructureAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  holidayThemes?: Maybe<Array<IbeDeskline3InfrastructureHolidayTheme>>;
  id?: Maybe<Scalars['Int']['output']>;
  openingHours?: Maybe<Array<IbeDeskline3OpeningHours>>;
  openingHoursInformation?: Maybe<OpeningHoursInformation>;
  priceInformation?: Maybe<Scalars['String']['output']>;
  topPoi?: Maybe<Scalars['Boolean']['output']>;
  topics1?: Maybe<Array<IbeDeskline3MobilityAndTrafficInfrastructureTopic1>>;
  topics2?: Maybe<Array<IbeDeskline3MobilityAndTrafficInfrastructureTopic2>>;
};

export type IbeDeskline3MobilityAndTrafficInfrastructureTopic1 = {
  __typename?: 'IbeDeskline3MobilityAndTrafficInfrastructureTopic1';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3MobilityAndTrafficInfrastructureTopic2 = {
  __typename?: 'IbeDeskline3MobilityAndTrafficInfrastructureTopic2';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3NamedExtraCost = {
  __typename?: 'IbeDeskline3NamedExtraCost';
  _entityType?: Maybe<Scalars['String']['output']>;
  extraCost?: Maybe<IbeDeskline3ExtraCost>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type IbeDeskline3Occupancy = {
  __typename?: 'IbeDeskline3Occupancy';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  standard?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3OpeningHours = {
  __typename?: 'IbeDeskline3OpeningHours';
  _entityType?: Maybe<Scalars['String']['output']>;
  dateFrom?: Maybe<Scalars['String']['output']>;
  dateTo?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type IbeDeskline3Period = {
  __typename?: 'IbeDeskline3Period';
  _entityType?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  start?: Maybe<Scalars['String']['output']>;
};

export type IbeDeskline3PoiInfrastructureAddressbase = {
  __typename?: 'IbeDeskline3PoiInfrastructureAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  holidayThemes?: Maybe<Array<IbeDeskline3InfrastructureHolidayTheme>>;
  id?: Maybe<Scalars['Int']['output']>;
  openingHours?: Maybe<Array<IbeDeskline3OpeningHours>>;
  openingHoursInformation?: Maybe<OpeningHoursInformation>;
  priceInformation?: Maybe<Scalars['String']['output']>;
  topPoi?: Maybe<Scalars['Boolean']['output']>;
  topics1?: Maybe<Array<IbeDeskline3PoiInfrastructureTopic1>>;
  topics2?: Maybe<Array<IbeDeskline3PoiInfrastructureTopic2>>;
};

export type IbeDeskline3PoiInfrastructureTopic1 = {
  __typename?: 'IbeDeskline3PoiInfrastructureTopic1';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3PoiInfrastructureTopic2 = {
  __typename?: 'IbeDeskline3PoiInfrastructureTopic2';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3Price = {
  __typename?: 'IbeDeskline3Price';
  _entityType?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  pax?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['Float']['output']>;
};

export type IbeDeskline3PriceTemplate = {
  __typename?: 'IbeDeskline3PriceTemplate';
  _entityType?: Maybe<Scalars['String']['output']>;
  basePrices?: Maybe<Array<IbeDeskline3BasePrice>>;
  bedLinen?: Maybe<IbeDeskline3ExtraCost>;
  defaultMealCode?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  extras?: Maybe<Array<IbeDeskline3NamedExtraCost>>;
  finalCleaning?: Maybe<IbeDeskline3ExtraCost>;
  id?: Maybe<Scalars['Int']['output']>;
  isBestPrice?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  periods?: Maybe<Array<IbeDeskline3Period>>;
  surcharges?: Maybe<Array<IbeDeskline3Surcharge>>;
  validFrom?: Maybe<Scalars['String']['output']>;
};

export type IbeDeskline3RouteAndTourInfrastructureAddressbase = {
  __typename?: 'IbeDeskline3RouteAndTourInfrastructureAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  holidayThemes?: Maybe<Array<IbeDeskline3InfrastructureHolidayTheme>>;
  id?: Maybe<Scalars['Int']['output']>;
  openingHours?: Maybe<Array<IbeDeskline3OpeningHours>>;
  openingHoursInformation?: Maybe<OpeningHoursInformation>;
  priceInformation?: Maybe<Scalars['String']['output']>;
  topPoi?: Maybe<Scalars['Boolean']['output']>;
  topics1?: Maybe<Array<IbeDeskline3RouteAndTourInfrastructureTopic1>>;
  topics2?: Maybe<Array<IbeDeskline3RouteAndTourInfrastructureTopic2>>;
};

export type IbeDeskline3RouteAndTourInfrastructureTopic1 = {
  __typename?: 'IbeDeskline3RouteAndTourInfrastructureTopic1';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3RouteAndTourInfrastructureTopic2 = {
  __typename?: 'IbeDeskline3RouteAndTourInfrastructureTopic2';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3SalesRulePeriod = {
  __typename?: 'IbeDeskline3SalesRulePeriod';
  _entityType?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  start?: Maybe<Scalars['String']['output']>;
};

export type IbeDeskline3SalesRuleTemplate = {
  __typename?: 'IbeDeskline3SalesRuleTemplate';
  _entityType?: Maybe<Scalars['String']['output']>;
  bookable?: Maybe<Scalars['Boolean']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  offerable?: Maybe<Scalars['Boolean']['output']>;
  periods?: Maybe<Array<IbeDeskline3SalesRulePeriod>>;
  validFrom?: Maybe<Scalars['String']['output']>;
};

export type IbeDeskline3Service = {
  __typename?: 'IbeDeskline3Service';
  _entityType?: Maybe<Scalars['String']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  bedrooms?: Maybe<Scalars['String']['output']>;
  cancellationPaymentTemplates?: Maybe<Array<IbeDeskline3CancellationPaymentTemplate>>;
  externalId?: Maybe<Scalars['String']['output']>;
  facilities?: Maybe<Array<IbeServiceFacility>>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  longDescription?: Maybe<Scalars['String']['output']>;
  occupancyAdults?: Maybe<IbeDeskline3Occupancy>;
  occupancyBeds?: Maybe<IbeDeskline3Occupancy>;
  priceFromTo?: Maybe<Scalars['String']['output']>;
  priceInclVT?: Maybe<Scalars['String']['output']>;
  priceNights?: Maybe<Scalars['String']['output']>;
  priceRule?: Maybe<Scalars['String']['output']>;
  priceStandardMealCode?: Maybe<Scalars['String']['output']>;
  priceTemplates?: Maybe<Array<IbeDeskline3PriceTemplate>>;
  prices?: Maybe<Array<IbeDeskline3Price>>;
  productType?: Maybe<Scalars['String']['output']>;
  rooms?: Maybe<Scalars['String']['output']>;
  salesRuleTemplates?: Maybe<Array<IbeDeskline3SalesRuleTemplate>>;
  serviceClassification?: Maybe<Scalars['String']['output']>;
  serviceClassificationName?: Maybe<Scalars['String']['output']>;
  serviceType?: Maybe<IbeAccServiceType>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
  /** Returns a list of (deskline) service images. */
  urlImages?: Maybe<Array<IbeUrlImage>>;
};


export type IbeDeskline3ServiceUrlImagesArgs = {
  sortOrder?: InputMaybe<SortOrder>;
};

export type IbeDeskline3SportAndLeisureInfrastructureAddressbase = {
  __typename?: 'IbeDeskline3SportAndLeisureInfrastructureAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  holidayThemes?: Maybe<Array<IbeDeskline3InfrastructureHolidayTheme>>;
  id?: Maybe<Scalars['Int']['output']>;
  openingHours?: Maybe<Array<IbeDeskline3OpeningHours>>;
  openingHoursInformation?: Maybe<OpeningHoursInformation>;
  priceInformation?: Maybe<Scalars['String']['output']>;
  topPoi?: Maybe<Scalars['Boolean']['output']>;
  topics1?: Maybe<Array<IbeDeskline3SportAndLeisureInfrastructureTopic1>>;
  topics2?: Maybe<Array<IbeDeskline3SportAndLeisureInfrastructureTopic2>>;
};

export type IbeDeskline3SportAndLeisureInfrastructureTopic1 = {
  __typename?: 'IbeDeskline3SportAndLeisureInfrastructureTopic1';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3SportAndLeisureInfrastructureTopic2 = {
  __typename?: 'IbeDeskline3SportAndLeisureInfrastructureTopic2';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3Surcharge = {
  __typename?: 'IbeDeskline3Surcharge';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  occupancyFrom?: Maybe<Scalars['String']['output']>;
  occupancyTo?: Maybe<Scalars['String']['output']>;
  stayTo?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type IbeDeskline3WellnessAndHealthInfrastructureAddressbase = {
  __typename?: 'IbeDeskline3WellnessAndHealthInfrastructureAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  holidayThemes?: Maybe<Array<IbeDeskline3InfrastructureHolidayTheme>>;
  id?: Maybe<Scalars['Int']['output']>;
  openingHours?: Maybe<Array<IbeDeskline3OpeningHours>>;
  openingHoursInformation?: Maybe<OpeningHoursInformation>;
  priceInformation?: Maybe<Scalars['String']['output']>;
  topPoi?: Maybe<Scalars['Boolean']['output']>;
  topics1?: Maybe<Array<IbeDeskline3WellnessAndHealthInfrastructureTopic1>>;
  topics2?: Maybe<Array<IbeDeskline3WellnessAndHealthInfrastructureTopic2>>;
};

export type IbeDeskline3WellnessAndHealthInfrastructureTopic1 = {
  __typename?: 'IbeDeskline3WellnessAndHealthInfrastructureTopic1';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeDeskline3WellnessAndHealthInfrastructureTopic2 = {
  __typename?: 'IbeDeskline3WellnessAndHealthInfrastructureTopic2';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeImageType = {
  __typename?: 'IbeImageType';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeServiceFacility = {
  __typename?: 'IbeServiceFacility';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  valueType?: Maybe<Scalars['String']['output']>;
};

export type IbeTomasAccAddressbase = {
  __typename?: 'IbeTomasAccAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  basePrice?: Maybe<Scalars['Float']['output']>;
  bookable?: Maybe<Scalars['Boolean']['output']>;
  descriptions?: Maybe<Array<Maybe<IbeTomasDescription>>>;
  featureGroups?: Maybe<Array<Maybe<IbeTomasAddressbaseFeatureGroup>>>;
  id?: Maybe<Scalars['Int']['output']>;
  services?: Maybe<Array<Maybe<IbeAccService>>>;
};

export type IbeTomasAccLeanAddressbase = {
  __typename?: 'IbeTomasAccLeanAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  addressbaseId?: Maybe<Scalars['Int']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offerServices?: Maybe<Array<Maybe<IbeTomasAccOfferService>>>;
};

export type IbeTomasAccOffer = {
  __typename?: 'IbeTomasAccOffer';
  _entityType?: Maybe<Scalars['String']['output']>;
  basePrice?: Maybe<Scalars['Float']['output']>;
  bookable?: Maybe<Scalars['Boolean']['output']>;
  categorizedPrices?: Maybe<Array<Maybe<IbeTomasCategorizedPrice>>>;
  descriptions?: Maybe<Array<Maybe<IbeTomasDescription>>>;
  id?: Maybe<Scalars['Int']['output']>;
  leanAddressbasePrices?: Maybe<Array<Maybe<IbeTomasLeanAddressbasePrice>>>;
  leanAddressbases?: Maybe<Array<Maybe<IbeTomasAccLeanAddressbase>>>;
  onDemand?: Maybe<Scalars['Boolean']['output']>;
};

export type IbeTomasAccOfferService = {
  __typename?: 'IbeTomasAccOfferService';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeTomasAddressbaseFeatureGroup = {
  __typename?: 'IbeTomasAddressbaseFeatureGroup';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  features?: Maybe<Array<Maybe<IbeTomasFeature>>>;
  id?: Maybe<Scalars['Int']['output']>;
  numberLabel?: Maybe<Scalars['String']['output']>;
  supportDescriptions?: Maybe<Scalars['Boolean']['output']>;
  supportNumbers?: Maybe<Scalars['Boolean']['output']>;
};

export type IbeTomasCategorizedPrice = {
  __typename?: 'IbeTomasCategorizedPrice';
  _entityType?: Maybe<Scalars['String']['output']>;
  basePrice?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  priceCategory?: Maybe<IbeTomasPriceCategory>;
};

export type IbeTomasDateComponentAddressbase = {
  __typename?: 'IbeTomasDateComponentAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  bookable?: Maybe<Scalars['Boolean']['output']>;
  descriptions?: Maybe<Array<Maybe<IbeTomasDescription>>>;
  featureGroups?: Maybe<Array<Maybe<IbeTomasAddressbaseFeatureGroup>>>;
  id?: Maybe<Scalars['Int']['output']>;
  services?: Maybe<Array<Maybe<IbeAccService>>>;
};

export type IbeTomasDateComponentOffer = {
  __typename?: 'IbeTomasDateComponentOffer';
  _entityType?: Maybe<Scalars['String']['output']>;
  basePrice?: Maybe<Scalars['Float']['output']>;
  bookable?: Maybe<Scalars['Boolean']['output']>;
  descriptions?: Maybe<Array<Maybe<IbeTomasDescription>>>;
  id?: Maybe<Scalars['Int']['output']>;
  leanAddressbases?: Maybe<Array<Maybe<IbeTomasAccLeanAddressbase>>>;
  onDemand?: Maybe<Scalars['Boolean']['output']>;
};

export type IbeTomasDescription = {
  __typename?: 'IbeTomasDescription';
  _entityType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionPlainText?: Maybe<Scalars['String']['output']>;
  descriptionType?: Maybe<IbeTomasDescriptionType>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeTomasDescriptionType = {
  __typename?: 'IbeTomasDescriptionType';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeTomasFeature = {
  __typename?: 'IbeTomasFeature';
  _entityType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  featureDefinition?: Maybe<IbeTomasFeatureDefinition>;
  id?: Maybe<Scalars['Int']['output']>;
  numberValue?: Maybe<Scalars['Float']['output']>;
};

export type IbeTomasFeatureDefinition = {
  __typename?: 'IbeTomasFeatureDefinition';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeTomasLeanAddressbasePrice = {
  __typename?: 'IbeTomasLeanAddressbasePrice';
  _entityType?: Maybe<Scalars['String']['output']>;
  basePrice?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  leanAddressbaseExternalId?: Maybe<Scalars['String']['output']>;
  priceCategory?: Maybe<IbeTomasPriceCategory>;
};

export type IbeTomasPriceCategory = {
  __typename?: 'IbeTomasPriceCategory';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IbeUrlImage = {
  __typename?: 'IbeUrlImage';
  _entityType?: Maybe<Scalars['String']['output']>;
  copyright?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  imageType?: Maybe<IbeImageType>;
  mediaLicense?: Maybe<MediaLicense>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type IdPredicate = {
  /** Predicate matching the id. */
  id: IntArrayPredicate;
};

export type IdTitleAddressbase = {
  __typename?: 'IdTitleAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ImageComponent = {
  __typename?: 'ImageComponent';
  _entityType?: Maybe<Scalars['String']['output']>;
  copyright?: Maybe<Scalars['String']['output']>;
  deeplink?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ImageType = {
  __typename?: 'ImageType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type ImportInfo = {
  __typename?: 'ImportInfo';
  _entityType?: Maybe<Scalars['String']['output']>;
  externalDataInfo?: Maybe<Scalars['String']['output']>;
  externalDataProvider?: Maybe<ExternalDataProvider>;
  externalSystemCreationTime?: Maybe<Scalars['String']['output']>;
  externalSystemLastChangeTime?: Maybe<Scalars['String']['output']>;
  externalSystemPublishTime?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  importIdentifier?: Maybe<Scalars['String']['output']>;
  importRun?: Maybe<ImportRun>;
  mediaLicense?: Maybe<MediaLicense>;
  originId?: Maybe<Scalars['String']['output']>;
  originSystem?: Maybe<Scalars['String']['output']>;
};

export type ImportRun = {
  __typename?: 'ImportRun';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  importPurpose?: Maybe<Scalars['String']['output']>;
  importSource?: Maybe<Scalars['String']['output']>;
  importTime?: Maybe<Scalars['String']['output']>;
};

export type InputFieldFilter = {
  productline?: InputMaybe<IntArrayPredicate>;
  type?: InputMaybe<IntPredicate>;
};

export type InputFieldType = {
  __typename?: 'InputFieldType';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type IntArrayPredicate = {
  allOf?: InputMaybe<Array<Scalars['Int']['input']>>;
  noneOf?: InputMaybe<Array<Scalars['Int']['input']>>;
  oneOf?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntFieldStatsResultValue = {
  __typename?: 'IntFieldStatsResultValue';
  intMax?: Maybe<Scalars['Int']['output']>;
  intMin?: Maybe<Scalars['Int']['output']>;
};

export type IntPredicate = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  noneOf?: InputMaybe<Array<Scalars['Int']['input']>>;
  not?: InputMaybe<Scalars['Int']['input']>;
  oneOf?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntegrityEntityClassification = {
  __typename?: 'IntegrityEntityClassification';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Interest = {
  __typename?: 'Interest';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type LandingpageOffer = {
  __typename?: 'LandingpageOffer';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  text1?: Maybe<Scalars['String']['output']>;
  text2?: Maybe<Scalars['String']['output']>;
};

export type Language = {
  __typename?: 'Language';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type LatLon = {
  __typename?: 'LatLon';
  _entityType?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

/** Can be used to limit returned list of elements. */
export type Limits = {
  /** The maximum number of elements to return. */
  maxElements: Scalars['PositiveInt']['input'];
  /** The number of elements to skip. Defaults to 0. */
  offset?: InputMaybe<Scalars['NonNegativeInt']['input']>;
};

export type LinkTarget = {
  __typename?: 'LinkTarget';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  openInNewPage?: Maybe<Scalars['Boolean']['output']>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
  targetUrl?: Maybe<Scalars['String']['output']>;
};

export type LocalDateFieldStatsResultValue = {
  __typename?: 'LocalDateFieldStatsResultValue';
  dateMax?: Maybe<Scalars['LocalDate']['output']>;
  dateMin?: Maybe<Scalars['LocalDate']['output']>;
};

export type LocalTimeFieldStatsResultValue = {
  __typename?: 'LocalTimeFieldStatsResultValue';
  timeMax?: Maybe<Scalars['LocalTime']['output']>;
  timeMin?: Maybe<Scalars['LocalTime']['output']>;
};

export type Location = {
  __typename?: 'Location';
  _entityType?: Maybe<Scalars['String']['output']>;
  alias1?: Maybe<Scalars['String']['output']>;
  alias2?: Maybe<Scalars['String']['output']>;
  communityCode?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<Coordinates>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  regions?: Maybe<Array<Region>>;
  zipcodes?: Maybe<Array<LocationZipcode>>;
};

export type LocationDefinition = {
  __typename?: 'LocationDefinition';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  withInfo?: Maybe<Scalars['Boolean']['output']>;
};

export type LocationZipcode = {
  __typename?: 'LocationZipcode';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type LocationsAddressbaseFilter = {
  definition?: InputMaybe<IntPredicate>;
};

export type LodgeAdditionalFeature = {
  __typename?: 'LodgeAdditionalFeature';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type LodgeAddressbase = {
  __typename?: 'LodgeAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  additionalFeatures?: Maybe<Array<LodgeAdditionalFeature>>;
  altitude?: Maybe<Scalars['Int']['output']>;
  approachAltitude?: Maybe<Scalars['Int']['output']>;
  approachLocation?: Maybe<Scalars['String']['output']>;
  approachWalkingTime?: Maybe<Scalars['Float']['output']>;
  arrivalByBus?: Maybe<Scalars['String']['output']>;
  arrivalByCar?: Maybe<Scalars['String']['output']>;
  arrivalByTrain?: Maybe<Scalars['String']['output']>;
  beds?: Maybe<Scalars['Int']['output']>;
  contactLodgeHost?: Maybe<Scalars['String']['output']>;
  contactLodgeWart?: Maybe<Scalars['String']['output']>;
  dormitories?: Maybe<Scalars['Int']['output']>;
  emergencyRoom?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  makeshiftBeds?: Maybe<Scalars['Int']['output']>;
  parking?: Maybe<Scalars['String']['output']>;
  pathInformation?: Maybe<Scalars['String']['output']>;
  referenceMeridian?: Maybe<Scalars['String']['output']>;
  region?: Maybe<LodgeRegion>;
  suitabilities?: Maybe<Array<LodgeSuitability>>;
  surroundingInformation?: Maybe<Scalars['String']['output']>;
  tourInformation?: Maybe<Scalars['String']['output']>;
  tours?: Maybe<Array<IdTitleAddressbase>>;
  types?: Maybe<Array<LodgeType>>;
  utmEast?: Maybe<Scalars['Float']['output']>;
  utmNorth?: Maybe<Scalars['Float']['output']>;
  winterRoom?: Maybe<Scalars['String']['output']>;
};

export type LodgeModuleFilter = {
  /** Filter results by their additional features (e.g. mobile phone reception, family friendly, etc.) */
  additionalFeatures?: InputMaybe<IntArrayPredicate>;
  /** Filter results by their altitude in meters. (e.g. 1000 or 1000-2000) */
  altitude?: InputMaybe<IntPredicate>;
  /** Filter results by their region (e.g. Kaisergebirge, Zillertal, etc.) */
  region?: InputMaybe<IntPredicate>;
  /** Filter results by their suitabilities (e.g. suitable for families, seniors, etc.) */
  suitabilities?: InputMaybe<IntArrayPredicate>;
};

export type LodgeRegion = {
  __typename?: 'LodgeRegion';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type LodgeSuitability = {
  __typename?: 'LodgeSuitability';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type LodgeType = {
  __typename?: 'LodgeType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type MaptoolkitCurrentWeather = CurrentWeather & {
  __typename?: 'MaptoolkitCurrentWeather';
  /** Current overall weather condition. */
  condition: MaptoolkitWeatherCondition;
  /** Date of the weather data (local time of requested location). */
  date: Scalars['LocalDate']['output'];
  /** Precipitation in mm/h. */
  precipitation: Scalars['Float']['output'];
  /** Temperature in °C. */
  temperature: Scalars['Float']['output'];
  /** Time of the weather data (local time of requested location). */
  time: Scalars['LocalTime']['output'];
  /** Current wind conditions. */
  wind: MaptoolkitWindCondition;
};

export type MaptoolkitDailyWeatherForecast = DailyWeatherForecast & {
  __typename?: 'MaptoolkitDailyWeatherForecast';
  /** Overall weather condition. */
  condition: MaptoolkitWeatherCondition;
  /** Date of the weather data (local time of requested location). */
  date: Scalars['LocalDate']['output'];
  /** Temperature range for the day. */
  temperature: MaptoolkitTemperatureRange;
  /** Predominant Wind conditions for the day. */
  wind: MaptoolkitWindCondition;
};

export type MaptoolkitHourlyWeatherForecast = HourlyWeatherForecast & {
  __typename?: 'MaptoolkitHourlyWeatherForecast';
  /** Overall weather condition. */
  condition: MaptoolkitWeatherCondition;
  /** Date of the weather data (local time of requested location). */
  date: Scalars['LocalDate']['output'];
  /** Precipitation conditions for the hour. */
  precipitation: MaptoolkitPrecipitationCondition;
  /** Temperature for the hour. */
  temperature: Scalars['Float']['output'];
  /** Time of the weather data (local time of requested location). */
  time: Scalars['LocalTime']['output'];
  /** Wind conditions for the hour. */
  wind: MaptoolkitWindCondition;
};

export type MaptoolkitPrecipitationCondition = {
  __typename?: 'MaptoolkitPrecipitationCondition';
  /** Precipitation in mm/h. */
  amount: Scalars['Float']['output'];
};

export type MaptoolkitTemperatureRange = TemperatureRange & {
  __typename?: 'MaptoolkitTemperatureRange';
  /** Maximum temperature in °C. */
  max: Scalars['Float']['output'];
  /** Minimum temperature in °C. */
  min: Scalars['Float']['output'];
};

export type MaptoolkitWeather = Weather & {
  __typename?: 'MaptoolkitWeather';
  /** Retrieve current weather conditions. */
  current?: Maybe<MaptoolkitCurrentWeather>;
  /** Retrieve weather forecast. The available time range depends on the underlying weather source. */
  forecast?: Maybe<MaptoolkitWeatherForecast>;
};

export type MaptoolkitWeatherCondition = WeatherCondition & {
  __typename?: 'MaptoolkitWeatherCondition';
  /**
   * Weather code according to WMO code table FM 94 BUFR/FM 95 CREX code table 0 20 003 – present weather.
   *
   * This code may be used as a sort value, when you want to sort weather conditions by severity, e.g. DWD displays
   * the icon of the most severe weather condition in the forecast for a specific day or time range.
   *
   * API may not return all possible codes of the mentioned code table, but only codes which are assigned with possible weather type.
   */
  code: Scalars['NonNegativeInt']['output'];
  /** Textual weather condition summary in the requested language. */
  summary?: Maybe<Scalars['String']['output']>;
  /** Enumerated type of weather condition. */
  type: WeatherConditionType;
};

export type MaptoolkitWeatherForecast = WeatherForecast & {
  __typename?: 'MaptoolkitWeatherForecast';
  /** Returns a daily forecast for the next days. */
  daily: Array<MaptoolkitDailyWeatherForecast>;
  /** Returns an hourly forecast for the next days. */
  hourly: Array<MaptoolkitHourlyWeatherForecast>;
};

export type MaptoolkitWindCondition = WindCondition & {
  __typename?: 'MaptoolkitWindCondition';
  /** Wind direction in degrees. */
  direction?: Maybe<Scalars['Float']['output']>;
  /** Wind gusts in m/s. */
  gusts?: Maybe<Scalars['Float']['output']>;
  /** Wind speed in m/s. */
  speed: Scalars['Float']['output'];
};

export type MediaAttribute = {
  __typename?: 'MediaAttribute';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MediaContent = {
  __typename?: 'MediaContent';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type MediaFilter = {
  /** Combine multiple filters with an "AND" semantic. */
  and?: InputMaybe<Array<MediaFilter>>;
  /**
   * Enables the filter on publication state of media (i.e. fromDate, toDate, annually and deactivated flags).
   *
   * Default: true
   */
  applyPublicationFilter?: InputMaybe<Scalars['Boolean']['input']>;
  /** Allow only files with the file type in result. */
  fileType?: InputMaybe<StringPredicate>;
  /** Allow only media with the image type in result. */
  imageType?: InputMaybe<IntPredicate>;
  /** Allow only media with the media content type in result. */
  mediaContent?: InputMaybe<IntPredicate>;
  /** Allow only media with the given media type in result. */
  mediaType?: InputMaybe<Array<MediaTypeEnum>>;
  /** Exclude items matching the given filter(s). */
  not?: InputMaybe<Array<MediaFilter>>;
  /** Combine multiple filters with an "OR" semantic. */
  or?: InputMaybe<Array<MediaFilter>>;
  /** Allow only media in results which meets the given productline predicate. */
  productlines?: InputMaybe<IntArrayPredicate>;
  /**
   * Allow only media in results which does not have any productline context.
   * Will only be considered, if field "productlines" is not given (i.e. null).
   *
   * Default: false
   */
  withoutProductlines?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MediaLicense = {
  __typename?: 'MediaLicense';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type MediaProvider = {
  __typename?: 'MediaProvider';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MediaType = {
  __typename?: 'MediaType';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum MediaTypeEnum {
  File = 'FILE',
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type MetainfoAddressbase = {
  __typename?: 'MetainfoAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  data?: Maybe<MetainfoAddressbaseData>;
  id?: Maybe<Scalars['Int']['output']>;
  productline?: Maybe<Productline>;
  productlineInfo?: Maybe<ProductlineInfo>;
};

export type MetainfoAddressbaseData = AccommodationAddressbase | BeachAddressbase | CenterAddressbase | CertificationAddressbase | CharterAddressbase | CnvHotelAddressbase | EventlocationAddressbase | Gastro2Addressbase | HealthfinderAddressbase | HhtBathAddressbase | HhtCampingAddressbase | HhtChurchAddressbase | HhtCinemaAddressbase | HhtCityWalkingAddressbase | HhtCruiseAddressbase | HhtEventAddressbase | HhtEventlocationAddressbase | HhtExperienceLeasureAddressbase | HhtGastronomyAddressbase | HhtGolfCityAddressbase | HhtGolfCourseAddressbase | HhtGolfHotelAddressbase | HhtHamburgCardPartnerAddressbase | HhtHotelAddressbase | HhtMaritimAddressbase | HhtMiscAddressbase | HhtMobilityAddressbase | HhtMuseumAddressbase | HhtNightlifeAddressbase | HhtParkAddressbase | HhtParkingAddressbase | HhtPoiAddressbase | HhtRouteStationAddressbase | HhtShoppingAddressbase | HhtSightAddressbase | HhtSportAddressbase | HhtSustainabilityAddressbase | HhtTheatreAddressbase | HhtTouristInformationAddressbase | HhtToursAddressbase | IbeDeskline3AccAddressbase | IbeDeskline3ArtAndCultureInfrastructureAddressbase | IbeDeskline3FoodAndBeverageInfrastructureAddressbase | IbeDeskline3InfrastructureAddressbase | IbeDeskline3MobilityAndTrafficInfrastructureAddressbase | IbeDeskline3PoiInfrastructureAddressbase | IbeDeskline3RouteAndTourInfrastructureAddressbase | IbeDeskline3SportAndLeisureInfrastructureAddressbase | IbeDeskline3WellnessAndHealthInfrastructureAddressbase | IbeTomasAccAddressbase | IbeTomasDateComponentAddressbase | OpeningHoursAddressbase | ParticularConditionAddressbase | PriceListAddressbase | ProducerAddressbase | QrAnnouncementAddressbase | SailingAddressbase | SailingSchoolAddressbase | SurfKiteAddressbase | TashBeachAddressbase | TourAddressbase;

export type MetainfoAddressbaseFilter = {
  /**
   * Only fetch metainfos for the specified productlines.
   *
   * Tip: Possible values are defined by the data object "Productline".
   */
  productlines?: InputMaybe<IntPredicate>;
};

export type MetainfoArticle = {
  __typename?: 'MetainfoArticle';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  productline?: Maybe<Productline>;
  productlineInfo?: Maybe<ProductlineInfo>;
};

export type MetainfoArticleFilter = {
  productlines?: InputMaybe<IntPredicate>;
};

export type MetainfoOffer = {
  __typename?: 'MetainfoOffer';
  _entityType?: Maybe<Scalars['String']['output']>;
  data?: Maybe<MetainfoOfferData>;
  id?: Maybe<Scalars['Int']['output']>;
  productline?: Maybe<Productline>;
  productlineInfo?: Maybe<ProductlineInfo>;
};

export type MetainfoOfferData = HealthfinderOffer | HhtBookableClusterProductOffer | HhtFaqOffer | IbeTomasAccOffer | IbeTomasDateComponentOffer | LandingpageOffer | NatureExperienceOffer;

export type MetainfoOfferFilter = {
  /** Constrain returned metainfos by productline. */
  productlines?: InputMaybe<IntPredicate>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Add a checkpoint for ryddle tickets. */
  addRyddleTicketCheckpoint: AddRyddleTicketCheckpointResponse;
};


export type MutationAddRyddleTicketCheckpointArgs = {
  ticket: RyddleTicket;
};

export type NameAndI18nOrder = {
  field: NameAndI18nSortFields;
  order?: InputMaybe<SortOrder>;
};

export enum NameAndI18nSortFields {
  I18NName = 'I18N_NAME',
  Id = 'ID',
  Name = 'NAME'
}

export type NameOrder = {
  field: NameSortFields;
  order?: InputMaybe<SortOrder>;
};

export enum NameSortFields {
  Id = 'ID',
  Name = 'NAME'
}

export type NatureExperienceOffer = {
  __typename?: 'NatureExperienceOffer';
  _entityType?: Maybe<Scalars['String']['output']>;
  categories?: Maybe<Array<NatureExperienceOfferCategory>>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type NatureExperienceOfferCategory = {
  __typename?: 'NatureExperienceOfferCategory';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type NeosDocument = {
  __typename?: 'NeosDocument';
  description?: Maybe<Scalars['String']['output']>;
  permaLink?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};

export type OccupancySensor = {
  __typename?: 'OccupancySensor';
  _entityType?: Maybe<Scalars['String']['output']>;
  capacity?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  info?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  thresholdAlert?: Maybe<Scalars['Int']['output']>;
  thresholdWarn?: Maybe<Scalars['Int']['output']>;
};

export type Offer = {
  __typename?: 'Offer';
  _entityType?: Maybe<Scalars['String']['output']>;
  additionalInfo?: Maybe<Scalars['String']['output']>;
  addressbase?: Maybe<Addressbase>;
  alternateLanguage?: Maybe<Scalars['String']['output']>;
  appearances?: Maybe<Array<Appearance>>;
  /** Returns the first attribute definition of this offer. Can be filtered. */
  attributeDefinition?: Maybe<ExtendedAttributeDefinition>;
  /** Returns the attribute definitions of this offer. Can be filtered. */
  attributeDefinitions?: Maybe<Array<ExtendedAttributeDefinition>>;
  author?: Maybe<Scalars['String']['output']>;
  availabilities?: Maybe<Array<Availability>>;
  bookingLink?: Maybe<Scalars['String']['output']>;
  bookingLinkText?: Maybe<Scalars['String']['output']>;
  canonicalUrl?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  contactAddressbases?: Maybe<Array<OfferContact>>;
  /**
   * Returns the first contactList of this object. Can be filtered.
   *
   * The returned nodes will be filtered by the given language and appearance or fall back to the default filters of the api key (which may define a certain delivery channel for all your requests).
   */
  contactList?: Maybe<EntityListContact>;
  /**
   * Returns the contactLists of this object. Can be filtered.
   *
   * The returned nodes will be filtered by the given language and appearance or fall back to the default filters of the api key (which may define a certain delivery channel for all your requests).
   */
  contactLists?: Maybe<Array<EntityListContact>>;
  contentScore?: Maybe<Scalars['Float']['output']>;
  creationTime?: Maybe<Scalars['String']['output']>;
  deliveryChannels?: Maybe<Array<DeliveryChannel>>;
  duration?: Maybe<Scalars['Int']['output']>;
  entityState?: Maybe<EntityState>;
  geoCodeDataFromAddressbase?: Maybe<Scalars['Boolean']['output']>;
  geoInfo?: Maybe<GeoInfo>;
  htmlHeadMetaDescription?: Maybe<Scalars['String']['output']>;
  htmlHeadTitle?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  importIdentifier?: Maybe<Scalars['String']['output']>;
  includedServices?: Maybe<Scalars['String']['output']>;
  /** Returns the first inputfield of this offer. Can be filtered and sorted. */
  inputField?: Maybe<OfferInputField>;
  /** Returns the inputfields of this offer. Can be filtered and sorted. */
  inputFields?: Maybe<Array<OfferInputField>>;
  interests?: Maybe<Array<Interest>>;
  languages?: Maybe<Array<Language>>;
  lastChangeTime?: Maybe<Scalars['String']['output']>;
  longDescription?: Maybe<Scalars['String']['output']>;
  /** Returns the assigned media for this offer. */
  media?: Maybe<Array<OfferMedia>>;
  mediaLicense?: Maybe<MediaLicense>;
  /** Returns the first assigned medium for this offer. Can be filtered and sorted. */
  medium?: Maybe<OfferMedia>;
  /** Checks if metainfos of this offer meet the provided productline predicate. */
  meetsProductlinePredicate: Scalars['Boolean']['output'];
  /** Returns the first metainfo of this offer. Can be filtered. */
  metainfo?: Maybe<MetainfoOffer>;
  /** Returns the metainfos of this offer. Can be filtered. */
  metainfos?: Maybe<Array<MetainfoOffer>>;
  /** Return the permaLink of the offer. */
  permaLink?: Maybe<Scalars['String']['output']>;
  pricing?: Maybe<Pricing>;
  /** Access to the IBE based details of a product (like dynamic current min price, actual availability, etc.). */
  product?: Maybe<HhtBaseProduct>;
  promotionOffer?: Maybe<Scalars['Boolean']['output']>;
  proposalStates?: Maybe<Array<ProposalState>>;
  regions?: Maybe<Array<Region>>;
  /**
   * Get a schema.org representation.
   *
   * By using the optional context parameter you are able to get a specific schema.org representation. By default only a generic schema.org (e.g. "Offer") representation is returned.
   *
   * Hint: As this is an expensive operation, do not use it in a list query. Use it only for a single object request.
   */
  schemaOrg: SchemaOrgRepresentation;
  searchKeywords?: Maybe<Scalars['String']['output']>;
  seasonAutumn?: Maybe<Scalars['Boolean']['output']>;
  seasonSpring?: Maybe<Scalars['Boolean']['output']>;
  seasonSummer?: Maybe<Scalars['Boolean']['output']>;
  seasonWinter?: Maybe<Scalars['Boolean']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  subTitle?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Tag>>;
  title?: Maybe<Scalars['String']['output']>;
};


export type OfferAttributeDefinitionArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};


export type OfferAttributeDefinitionsArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};


export type OfferContactListArgs = {
  filter?: InputMaybe<ContactListFilter>;
};


export type OfferContactListsArgs = {
  filter?: InputMaybe<ContactListFilter>;
};


export type OfferInputFieldArgs = {
  filter?: InputMaybe<InputFieldFilter>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type OfferInputFieldsArgs = {
  filter?: InputMaybe<InputFieldFilter>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type OfferMediaArgs = {
  fallback?: InputMaybe<Array<MediaFilter>>;
  filter?: InputMaybe<MediaFilter>;
  limit?: InputMaybe<Limits>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type OfferMediumArgs = {
  fallback?: InputMaybe<Array<MediaFilter>>;
  filter?: InputMaybe<MediaFilter>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type OfferMeetsProductlinePredicateArgs = {
  predicate: ProductlinePredicate;
};


export type OfferMetainfoArgs = {
  filter?: InputMaybe<MetainfoOfferFilter>;
};


export type OfferMetainfosArgs = {
  filter?: InputMaybe<MetainfoOfferFilter>;
};


export type OfferPermaLinkArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};


export type OfferSchemaOrgArgs = {
  context?: InputMaybe<SchemaOrgOfferContext>;
};

export type OfferContact = {
  __typename?: 'OfferContact';
  _entityType?: Maybe<Scalars['String']['output']>;
  contactAddressbase?: Maybe<ContactAddressbase>;
  contextProductline?: Maybe<Productline>;
  id?: Maybe<Scalars['Int']['output']>;
  purpose?: Maybe<ContactPurpose>;
};

export enum OfferFacetField {
  /** Build facets for assigned location entity. */
  Location = 'LOCATION',
  /** Build facets for productlines. */
  Productline = 'PRODUCTLINE',
  /** Build facets for assigned regions. */
  Region = 'REGION'
}

export type OfferFacetResult = {
  __typename?: 'OfferFacetResult';
  fields?: Maybe<Array<OfferFieldFacetResult>>;
};

export type OfferFacets = {
  fields?: InputMaybe<Array<OfferFieldFacet>>;
};

export type OfferFieldFacet = {
  /** Identifies the field that should bet treated as facet. */
  field: OfferFacetField;
  /** Specifies predicates to filter out returned facets */
  filter?: InputMaybe<FacetFilterPredicate>;
  /** Provide an identifier for facet results. */
  identifier?: InputMaybe<Scalars['String']['input']>;
  /** Limits the number of returned facet counts. Default: 100 */
  limit?: Scalars['Int']['input'];
  /** Specifies the minimum count required for a facet to be included in the response. Default: 0 */
  mincount?: Scalars['Int']['input'];
};

export type OfferFieldFacetData = Location | Productline | Region;

export type OfferFieldFacetResult = {
  __typename?: 'OfferFieldFacetResult';
  facets: Array<OfferFieldFacetValue>;
  field: OfferFacetField;
  identifier: Scalars['String']['output'];
};

export type OfferFieldFacetValue = {
  __typename?: 'OfferFieldFacetValue';
  count: Scalars['Int']['output'];
  data: OfferFieldFacetData;
};

export type OfferFile = {
  __typename?: 'OfferFile';
  _entityType?: Maybe<Scalars['String']['output']>;
  annually?: Maybe<Scalars['Boolean']['output']>;
  attributes?: Maybe<Array<MediaAttribute>>;
  deactivated?: Maybe<Scalars['Boolean']['output']>;
  deeplink?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['String']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  fromDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  identification?: Maybe<Scalars['String']['output']>;
  languages?: Maybe<Array<Language>>;
  pooledMedium?: Maybe<PooledAddressbaseMedium>;
  productlines?: Maybe<Array<Productline>>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
  toDate?: Maybe<Scalars['String']['output']>;
};

export type OfferFilter = {
  and?: InputMaybe<Array<OfferFilter>>;
  /** Constrain offer results by offer availability. Offers must meet the range constraint (i.e. an offer must be valid within the range) */
  availability?: InputMaybe<DateRangePredicate>;
  /** Restrict results to a specific client. */
  client?: InputMaybe<ClientPredicate>;
  /**
   * Constrain results by fulltext search query. Will be used as hard filter criterion, not as a ranking criterion.
   *
   * If you need a ranking criterion, use the 'query' parameter of the 'offers' query instead.
   */
  fulltext?: InputMaybe<Scalars['String']['input']>;
  /** Constrain results by their geo location. */
  geoFilter?: InputMaybe<GeoFilter>;
  /** Constrain results by their ids. */
  id?: InputMaybe<IntPredicate>;
  /** Constrain results by their import ids. */
  importId?: InputMaybe<StringPredicate>;
  /** Constrain location based on the referenced location ID of an offers addressbase (= "Ort") */
  location?: InputMaybe<IntPredicate>;
  /** Filter results by module specific criteria (e.g. accommodation or tour  specific filters). */
  moduleFilter?: InputMaybe<OfferModuleFilter>;
  not?: InputMaybe<Array<OfferFilter>>;
  or?: InputMaybe<Array<OfferFilter>>;
  /** Constrain results by exact match of permalink (within the current language). */
  permaLink?: InputMaybe<StringPredicate>;
  /** Constrain results by their owner pois. */
  poiId?: InputMaybe<IntArrayPredicate>;
  /** Constrain results by their pricing. */
  pricingFilter?: InputMaybe<PricingFilter>;
  /** Constrain results by their productline assignments. */
  productlines?: InputMaybe<IntArrayPredicate>;
  /** Constrain location based on the referenced region IDs of an offers addressbase */
  regions?: InputMaybe<IntArrayPredicate>;
};

export type OfferImage = {
  __typename?: 'OfferImage';
  _entityType?: Maybe<Scalars['String']['output']>;
  annually?: Maybe<Scalars['Boolean']['output']>;
  attributes?: Maybe<Array<MediaAttribute>>;
  deactivated?: Maybe<Scalars['Boolean']['output']>;
  deeplink?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['String']['output']>;
  focalPointX?: Maybe<Scalars['Int']['output']>;
  focalPointY?: Maybe<Scalars['Int']['output']>;
  fromDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  imageHeight?: Maybe<Scalars['String']['output']>;
  imageType?: Maybe<ImageType>;
  imageWidth?: Maybe<Scalars['String']['output']>;
  languages?: Maybe<Array<Language>>;
  link?: Maybe<Scalars['String']['output']>;
  pooledMedium?: Maybe<PooledAddressbaseMedium>;
  productlines?: Maybe<Array<Productline>>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
  toDate?: Maybe<Scalars['String']['output']>;
};

export type OfferInputField = {
  __typename?: 'OfferInputField';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inputFieldType?: Maybe<InputFieldType>;
  productline?: Maybe<Productline>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type OfferMedia = OfferFile | OfferImage | OfferVideo;

/** Filter for module specific criteria. */
export type OfferModuleFilter = {
  bookableClusterProduct?: InputMaybe<BookableClusterProductModuleFilter>;
};

export type OfferOrder = {
  additional?: InputMaybe<OfferOrderArguments>;
  field: OfferSortFields;
  order?: InputMaybe<SortOrder>;
};

export type OfferOrderArguments = {
  /**
   * If provided the pseudo random order will be initialized by this value; the sort order will be the same for subsequent calls, whenn providing the same initializer value.
   * This is especially important when paging through your results.
   */
  pseudoRandomInitializer?: InputMaybe<Scalars['PositiveInt']['input']>;
};

export type OfferProcessing = {
  applyProductlineContext?: InputMaybe<Array<Scalars['PositiveInt']['input']>>;
};

export enum OfferSortFields {
  /**
   * Sort results randomly.
   *
   * To support a stable order for folliwing pagination calls, we encourage you to provide a initializer value via AddressbaseOrder.additional.pseudoRandomInitializer
   */
  PseudoRandom = 'PSEUDO_RANDOM',
  /** Sort results by title */
  Title = 'TITLE'
}

export type OfferVideo = {
  __typename?: 'OfferVideo';
  _entityType?: Maybe<Scalars['String']['output']>;
  annually?: Maybe<Scalars['Boolean']['output']>;
  attributes?: Maybe<Array<MediaAttribute>>;
  deeplink?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['String']['output']>;
  fromDate?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  languages?: Maybe<Array<Language>>;
  pooledMedium?: Maybe<PooledAddressbaseMedium>;
  productlines?: Maybe<Array<Productline>>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
  toDate?: Maybe<Scalars['String']['output']>;
  videoIdentification?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

/**
 * Filter opening hour information by productline.
 *
 * Example usages:
 * - only default opening hours: { productlines: { lt: 0 }  }
 * - only opening hours of any productlines: { includeDefault: false }
 * - only productline 1 opening hours: { productlines: { eq: 1 }, includeDefault: false }
 * - only productline 1 or 2 and default opening hours: { productlines: { oneOf: [1, 2] } }
 */
export type OpeningHourInformationFilter = {
  /**
   * Specify if the default opening hours should be included in the result. Default: true
   *
   * If false, the default opening hours are excluded from the result.
   */
  includeDefault?: Scalars['Boolean']['input'];
  /** Only include opening hours information for the specified productlines. */
  productlines?: InputMaybe<IntPredicate>;
};

export type OpeningHoursAddressbase = {
  __typename?: 'OpeningHoursAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  closedFrom?: Maybe<Scalars['String']['output']>;
  closedTo?: Maybe<Scalars['String']['output']>;
  descriptionClosedDays?: Maybe<Scalars['String']['output']>;
  descriptionOpeningHours?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  openedFrom?: Maybe<Scalars['String']['output']>;
  openedTo?: Maybe<Scalars['String']['output']>;
};

export type OpeningHoursDefinition = {
  __typename?: 'OpeningHoursDefinition';
  _entityType?: Maybe<Scalars['String']['output']>;
  additionalInformation?: Maybe<Scalars['String']['output']>;
  dateFrom?: Maybe<Scalars['String']['output']>;
  dateTo?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /**
   * A list of time intervals for the opening hour definition. There may be more intervals for a single day.
   *
   * The list is sorted by weekday and timeFrom by default.
   */
  timeIntervals?: Maybe<Array<TimeIntervalDefinition>>;
  yearly?: Maybe<Scalars['Boolean']['output']>;
};


export type OpeningHoursDefinitionTimeIntervalsArgs = {
  filter?: InputMaybe<TimeIntervalDefinitionFilter>;
};

export type OpeningHoursFilter = {
  /**
   * If true, constrain results to those which have no opening hours.
   *
   * If false (= default), no constraint is applied.
   */
  noOpeningHours?: InputMaybe<Scalars['Boolean']['input']>;
  /** Constrain results to those with opening hours at given time stamp. Timestamp must be within next 3 days. */
  openAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Constrain results to those with opening hours at given time range of day. Only the next 7 days are checked. */
  openAtTimeOfDay?: InputMaybe<TimeRangePredicate>;
  /** Constrain results to those with opening hours somewhen the specified weekdays. Only the next 7 days are checked. */
  openOn?: InputMaybe<WeekdayArrayPredicate>;
  /**
   * If true, constrain results to those with with opening hours somewhen today (today is defined by CET/CEST).
   *
   * If false (= default), no constraint is applied.
   */
  openToday?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * If true, constrain results to those which are permanently open.
   *
   * If false (= default), no constraint is applied.
   */
  permanentlyOpen?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OpeningHoursInformation = {
  __typename?: 'OpeningHoursInformation';
  _entityType?: Maybe<Scalars['String']['output']>;
  /** A list of closed hour definitions (where usually only one is valid at a time). */
  closedHourDefinitions?: Maybe<Array<ClosedHoursDefinition>>;
  descriptionClosedDays?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  noOpeningHours?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Returns if POI is open at provided time. If no time is provided, it checks if it is open now (i.e. now considering CET/CEST time). Time should be provided as UTC.
   * It respects temporarily closed and permanently opened flags (while the first overrules the second).
   *
   * Returns null, if one of the following is given:
   * - no opening times are defined
   * - poi is open only on request (and no other opening times are defined)
   * - or has no opening hours
   */
  openAt?: Maybe<Scalars['Boolean']['output']>;
  openOnRequest?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Returns if POI is open somewhen today (today is defined by CET/CEST).
   * It respects temporarily/permanently closed and permanently opened flags (while the first overrule the second).
   *
   * Returns null, if one of the following is given:
   * - no opening times are defined
   * - poi is open only on request (and no other opening times are defined)
   * - or has no opening hours
   */
  openToday?: Maybe<Scalars['Boolean']['output']>;
  /** A list of opening hour definitions (where usually only one is valid at a time). */
  openingHourDefinitions?: Maybe<Array<OpeningHoursDefinition>>;
  openingHours?: Maybe<Scalars['String']['output']>;
  permanentlyClosed?: Maybe<Scalars['Boolean']['output']>;
  permanentlyOpen?: Maybe<Scalars['Boolean']['output']>;
  temporarilyClosed?: Maybe<Scalars['Boolean']['output']>;
};


export type OpeningHoursInformationClosedHourDefinitionsArgs = {
  filter?: InputMaybe<HourDefinitionFilter>;
};


export type OpeningHoursInformationOpenAtArgs = {
  time?: InputMaybe<Scalars['DateTime']['input']>;
};


export type OpeningHoursInformationOpeningHourDefinitionsArgs = {
  filter?: InputMaybe<HourDefinitionFilter>;
};

export type PaginatedAddressbase = {
  __typename?: 'PaginatedAddressbase';
  facets: AddressbaseFacetResult;
  nodes: Array<Addressbase>;
  pagination: PaginationMetadata;
};


export type PaginatedAddressbaseNodesArgs = {
  processing?: InputMaybe<AddressbaseProcessing>;
};

export type PaginatedAnnouncement = {
  __typename?: 'PaginatedAnnouncement';
  nodes?: Maybe<Array<Announcement>>;
  pagination: PaginationMetadata;
};

export type PaginatedArticle = {
  __typename?: 'PaginatedArticle';
  facets: ArticleFacetResult;
  nodes: Array<Article>;
  pagination: PaginationMetadata;
};


export type PaginatedArticleNodesArgs = {
  processing?: InputMaybe<ArticleProcessing>;
};

export type PaginatedEntityList = {
  __typename?: 'PaginatedEntityList';
  nodes: Array<EntityListNodeTypes>;
  pagination: PaginationMetadata;
};

export type PaginatedEvent = {
  __typename?: 'PaginatedEvent';
  facets: EventFacetResult;
  nodes: Array<Event>;
  pagination: PaginationMetadata;
};

export type PaginatedEventDate = {
  __typename?: 'PaginatedEventDate';
  facets: EventDateFacetResult;
  nodes: Array<EventDate>;
  pagination: PaginationMetadata;
};

export type PaginatedFulltextSearchResult = {
  __typename?: 'PaginatedFulltextSearchResult';
  /** Metadata about the structure of the result set. */
  facets: FulltextSearchFacetResult;
  /** Search result items. */
  nodes: Array<FulltextSearchResultItem>;
  /** Metadata about results size and pagination. */
  pagination: PaginationMetadata;
};

export type PaginatedOffer = {
  __typename?: 'PaginatedOffer';
  facets: OfferFacetResult;
  nodes: Array<Offer>;
  pagination: PaginationMetadata;
};


export type PaginatedOfferNodesArgs = {
  processing?: InputMaybe<OfferProcessing>;
};

/** Can be used to limit returned list of elements. */
export type Pagination = {
  /** The page number to return. Defaults to 1. (1-based) */
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  /** The number of elements to return per page. Defaults to 10. */
  pageSize?: InputMaybe<Scalars['PositiveInt']['input']>;
};

/** Represents the metadata of a paginated list. */
export type PaginationMetadata = {
  __typename?: 'PaginationMetadata';
  /** Returns the current page number. (1-based) */
  currentPage: Scalars['NonNegativeInt']['output'];
  /** Returns the number of elements per page. */
  pageSize: Scalars['NonNegativeInt']['output'];
  /** Returns the total number of pages. */
  totalPages: Scalars['NonNegativeInt']['output'];
  /** Returns the total number of elements. */
  totalRecords: Scalars['NonNegativeInt']['output'];
};

export type ParticularConditionAddressbase = {
  __typename?: 'ParticularConditionAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  /** Returns the first attribute definition of this offer. Can be filtered. */
  attributeDefinition?: Maybe<ExtendedAttributeDefinition>;
  /** Returns the attribute definitions of this offer. Can be filtered. */
  attributeDefinitions?: Maybe<Array<ExtendedAttributeDefinition>>;
  dateFrom?: Maybe<Scalars['String']['output']>;
  dateTo?: Maybe<Scalars['String']['output']>;
  generalInformation?: Maybe<Scalars['String']['output']>;
  generalInformationTitle?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  openingHours?: Maybe<Scalars['String']['output']>;
  openingHoursLink?: Maybe<Scalars['String']['output']>;
  preventiveMeasures?: Maybe<Scalars['String']['output']>;
  reservation?: Maybe<Scalars['String']['output']>;
  reservationLink?: Maybe<Scalars['String']['output']>;
  securityMeasures?: Maybe<Scalars['String']['output']>;
  visitorInformation?: Maybe<Scalars['String']['output']>;
  waitingTime?: Maybe<Scalars['String']['output']>;
};


export type ParticularConditionAddressbaseAttributeDefinitionArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};


export type ParticularConditionAddressbaseAttributeDefinitionsArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};

export type PaymentOption = {
  __typename?: 'PaymentOption';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type PersonBasedUpsellBookingItem = BookingItem & {
  __typename?: 'PersonBasedUpsellBookingItem';
  /** Validity in days of upsell item. */
  duration: Scalars['PositiveInt']['output'];
  /** Date from which the booking item is valid. */
  fromDate: Scalars['LocalDate']['output'];
  /**
   * ID of booking item.
   *
   * (Hint: it is not the id of the underlying service)
   */
  id: Scalars['String']['output'];
  /** Flag indicating if the booking item is still active. */
  isActive: Scalars['Boolean']['output'];
  /** true if booking item is in future or today. */
  isValid: Scalars['Boolean']['output'];
  /** Name of the booking item */
  name: Scalars['String']['output'];
  /** Number of adults served by booking item. */
  numberOfAdults: Scalars['NonNegativeInt']['output'];
  /** Number of children served by booking item. */
  numberOfChildren: Scalars['NonNegativeInt']['output'];
  /** Number of persons served by booking item (adults + children). */
  numberOfPersons: Scalars['PositiveInt']['output'];
  /** Internal service id of the booking item. */
  serviceId: Scalars['String']['output'];
  /** State of the booking item (i.e. ACTIVE, CANCELLED, ...) */
  state: BookingState;
  /** Total price of the booking item. */
  totalPrice: Scalars['Float']['output'];
};

export type PersonBasedUpsellProduct = HhtBaseProduct & {
  __typename?: 'PersonBasedUpsellProduct';
  /** Cumulated availability of all product variants. */
  availability?: Maybe<ProductAvailability>;
  /** Min price (per adult) of the cheapest offer (in EUR). */
  minPrice?: Maybe<Scalars['Float']['output']>;
  /** Internal service IDs. */
  serviceIds: Array<Scalars['String']['output']>;
  /** Product variants (vacancy checked) for the given configuration. */
  variants?: Maybe<Array<PersonBasedUpsellProductOffer>>;
};


export type PersonBasedUpsellProductVariantsArgs = {
  filter?: InputMaybe<PersonBasedUpsellProductVariantFilter>;
};

export type PersonBasedUpsellProductOffer = {
  __typename?: 'PersonBasedUpsellProductOffer';
  /** Validity duration of the hamburg card in days. */
  duration: Scalars['PositiveInt']['output'];
  /** Number of adults included in the offer. */
  numberOfAdults: Scalars['NonNegativeInt']['output'];
  /** Number of Children included in the offer. */
  numberOfChildren: Scalars['NonNegativeInt']['output'];
  /** Total number of persons in the offer. */
  numberOfPersons: Scalars['PositiveInt']['output'];
  /** average price per person for the offer (in EUR). */
  pricePerUnit: Scalars['Float']['output'];
  /** Internal service id of the hamburg card. */
  serviceIds: Array<Scalars['String']['output']>;
  /** Services of product. */
  services: Array<PersonBasedUpsellProductOfferService>;
  /** Total price (for all units/persons) for the offer (in EUR). */
  totalPrice: Scalars['Float']['output'];
};

export type PersonBasedUpsellProductOfferService = {
  __typename?: 'PersonBasedUpsellProductOfferService';
  /** Total amount of occurrences of this product service. */
  amount: Scalars['PositiveInt']['output'];
  /** Name of the product service. */
  name: Scalars['String']['output'];
  /** Total number of persons over all product services. */
  numberOfPersons: Scalars['PositiveInt']['output'];
  /** Internal service id of the product service. */
  serviceId: Scalars['String']['output'];
  /** Total price over all product services. */
  totalPrice: Scalars['Float']['output'];
  /** Internal service id of the product. */
  type?: Maybe<PersonCategory>;
};

export type PersonBasedUpsellProductVariantFilter = {
  /**
   * Specify children by passing an array childrens´ ages (0 - 17).
   *
   * Default: none
   */
  childAges?: InputMaybe<Array<Scalars['NonNegativeInt']['input']>>;
  /**
   * Filter by duration (days).
   *
   * Default: 1 day
   */
  duration?: Scalars['PositiveInt']['input'];
  /**
   * Default: today (defined by the server, i.e. according to CET/CEST)
   *
   * Must be today or in future.
   */
  fromDate?: InputMaybe<Scalars['LocalDate']['input']>;
  /**
   * Number of adults to be booked.
   *
   * Default: 1
   */
  numberOfAdults?: InputMaybe<Scalars['PositiveInt']['input']>;
};

export enum PersonCategory {
  Adult = 'ADULT',
  Child = 'CHILD',
  Other = 'OTHER',
  Teen = 'TEEN'
}

export type PooledAddressbaseMedium = {
  __typename?: 'PooledAddressbaseMedium';
  _entityType?: Maybe<Scalars['String']['output']>;
  altText?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  authorUrl?: Maybe<Scalars['String']['output']>;
  copyright?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  identification?: Maybe<Scalars['String']['output']>;
  mediaContent?: Maybe<MediaContent>;
  mediaLicense?: Maybe<Scalars['String']['output']>;
  mediaProvider?: Maybe<MediaProvider>;
  mediaType?: Maybe<MediaType>;
  sourceUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PooledDataObjectMedium = {
  __typename?: 'PooledDataObjectMedium';
  _entityType?: Maybe<Scalars['String']['output']>;
  altText?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  authorUrl?: Maybe<Scalars['String']['output']>;
  copyright?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  identification?: Maybe<Scalars['String']['output']>;
  mediaContent?: Maybe<MediaContent>;
  mediaLicense?: Maybe<Scalars['String']['output']>;
  mediaProvider?: Maybe<MediaProvider>;
  mediaType?: Maybe<MediaType>;
  sourceUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PooledEventMedium = {
  __typename?: 'PooledEventMedium';
  _entityType?: Maybe<Scalars['String']['output']>;
  altText?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  authorUrl?: Maybe<Scalars['String']['output']>;
  copyright?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  identification?: Maybe<Scalars['String']['output']>;
  mediaContent?: Maybe<MediaContent>;
  mediaLicense?: Maybe<Scalars['String']['output']>;
  mediaProvider?: Maybe<MediaProvider>;
  mediaType?: Maybe<MediaType>;
  sourceUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PriceCategory = {
  __typename?: 'PriceCategory';
  _entityType?: Maybe<Scalars['String']['output']>;
  bookable?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  priceCategoryType?: Maybe<PriceCategoryType>;
  toPrice?: Maybe<Scalars['Float']['output']>;
};

export type PriceCategoryType = {
  __typename?: 'PriceCategoryType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type PriceCurrency = {
  __typename?: 'PriceCurrency';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type PriceDefinition = {
  __typename?: 'PriceDefinition';
  _entityType?: Maybe<Scalars['String']['output']>;
  attributes?: Maybe<Array<PriceDefinitionAttribute>>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  priceItems?: Maybe<Array<PriceItem>>;
};

export type PriceDefinitionAttribute = {
  __typename?: 'PriceDefinitionAttribute';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type PriceItem = {
  __typename?: 'PriceItem';
  _entityType?: Maybe<Scalars['String']['output']>;
  ageFrom?: Maybe<Scalars['Int']['output']>;
  ageTo?: Maybe<Scalars['Int']['output']>;
  amountPeopleFrom?: Maybe<Scalars['Int']['output']>;
  amountPeopleTo?: Maybe<Scalars['Int']['output']>;
  categoryDescription?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<PriceCurrency>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  priceItemCategory?: Maybe<PriceItemCategory>;
  priceValues?: Maybe<Array<PriceValue>>;
};

export type PriceItemCategory = {
  __typename?: 'PriceItemCategory';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type PriceList = {
  __typename?: 'PriceList';
  _entityType?: Maybe<Scalars['String']['output']>;
  annually?: Maybe<Scalars['Boolean']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  priceDefinition?: Maybe<PriceDefinition>;
  validFrom?: Maybe<Scalars['String']['output']>;
  validUntil?: Maybe<Scalars['String']['output']>;
};

export type PriceListAddressbase = {
  __typename?: 'PriceListAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  /** Returns the first attribute definition of this offer. Can be filtered. */
  attributeDefinition?: Maybe<ExtendedAttributeDefinition>;
  /** Returns the attribute definitions of this offer. Can be filtered. */
  attributeDefinitions?: Maybe<Array<ExtendedAttributeDefinition>>;
  id?: Maybe<Scalars['Int']['output']>;
  paymentInformation?: Maybe<Scalars['String']['output']>;
  paymentOptions?: Maybe<Array<PaymentOption>>;
  priceLists?: Maybe<Array<PriceList>>;
};


export type PriceListAddressbaseAttributeDefinitionArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};


export type PriceListAddressbaseAttributeDefinitionsArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};

export type PriceValue = {
  __typename?: 'PriceValue';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<PriceValueType>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type PriceValueType = {
  __typename?: 'PriceValueType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Pricing = {
  __typename?: 'Pricing';
  _entityType?: Maybe<Scalars['String']['output']>;
  absolutePrice?: Maybe<Scalars['Float']['output']>;
  freeOfCharge?: Maybe<Scalars['Boolean']['output']>;
  fromPrice?: Maybe<Scalars['Float']['output']>;
  priceBasis?: Maybe<Scalars['String']['output']>;
  priceDescription?: Maybe<Scalars['String']['output']>;
  toPrice?: Maybe<Scalars['Float']['output']>;
};

export type PricingFilter = {
  /**
   * Include only items in result, which are free of charge.
   *
   * Caution: This will only be considered if the value is "true" and then the "range" field will be ignored.
   */
  freeOfCharge?: InputMaybe<Scalars['Boolean']['input']>;
  /** Restrict results to items, which have pricing in the given range. */
  range?: InputMaybe<FloatPredicate>;
};

export type PrioLevel = {
  __typename?: 'PrioLevel';
  _entityType?: Maybe<Scalars['String']['output']>;
  deliveryChannels?: Maybe<Array<DeliveryChannel>>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  usedForAddressbases?: Maybe<Scalars['Boolean']['output']>;
  usedForOffers?: Maybe<Scalars['Boolean']['output']>;
};

export type ProducerAddressbase = {
  __typename?: 'ProducerAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  additionalInformation?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  producerTypes?: Maybe<Array<ProducerType>>;
  productGroups?: Maybe<Array<ProductGroup>>;
  seasonInfoAndHoursOfOpening?: Maybe<Scalars['String']['output']>;
  specialExperienceInfo?: Maybe<Scalars['String']['output']>;
  specialExperiences?: Maybe<Array<SpecialExperience>>;
};

export type ProducerType = {
  __typename?: 'ProducerType';
  _entityType?: Maybe<Scalars['String']['output']>;
  attributeDef?: Maybe<ProducerTypeDefinition>;
  id?: Maybe<Scalars['Int']['output']>;
  info?: Maybe<Scalars['String']['output']>;
};

export type ProducerTypeDefinition = {
  __typename?: 'ProducerTypeDefinition';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  withInfo?: Maybe<Scalars['Boolean']['output']>;
};

export type ProductAvailability = {
  __typename?: 'ProductAvailability';
  /** Configured availabilities (non-vacancy checked) for the product. */
  availableDates: Array<AvailableDate>;
  /** Min/Max dates of availability. The product may not be available on all dates within this range. It just indicates the maximum date bounds. */
  dateRange: DateRange;
  /** Min/Max price range of the product per unit&adult (in EUR). */
  priceRange: FloatRange;
};

export type ProductGroup = {
  __typename?: 'ProductGroup';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Productline = {
  __typename?: 'Productline';
  _entityType?: Maybe<Scalars['String']['output']>;
  addressbaseDetails?: Maybe<ProductlineDetail>;
  articleDetails?: Maybe<ProductlineDetail>;
  asPortalContext?: Maybe<Scalars['Boolean']['output']>;
  eventDetails?: Maybe<ProductlineDetail>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offerDetails?: Maybe<ProductlineDetail>;
  parent?: Maybe<Productline>;
  plKey?: Maybe<Scalars['String']['output']>;
};

export type ProductlineDetail = {
  __typename?: 'ProductlineDetail';
  _entityType?: Maybe<Scalars['String']['output']>;
  enableAdditionalDescriptions?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type ProductlineInfo = {
  __typename?: 'ProductlineInfo';
  _entityType?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  homepage?: Maybe<Scalars['String']['output']>;
  homepageLinkText?: Maybe<Scalars['String']['output']>;
  htmlHeadMetaDescription?: Maybe<Scalars['String']['output']>;
  htmlHeadTitle?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  longDesc?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  searchKeywords?: Maybe<Scalars['String']['output']>;
  shortDesc?: Maybe<Scalars['String']['output']>;
  subTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ProductlinePredicate = {
  /** Predicate matching the productline id. */
  id: IntArrayPredicate;
};

export type ProposalState = {
  __typename?: 'ProposalState';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type QrAnnouncementAddressbase = {
  __typename?: 'QrAnnouncementAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  pin?: Maybe<Scalars['String']['output']>;
};

/** Main entry point to imx.Platform GraphQL API. */
export type Query = {
  __typename?: 'Query';
  announcement?: Maybe<Announcement>;
  announcements: PaginatedAnnouncement;
  /**
   * Retrieve an article by its id or permalink.
   *
   * Ensure that at least one of id or permaLink argument is provided. If both are provided, the id will be used.
   */
  article?: Maybe<Article>;
  /** Retrieve a list of articles. */
  articles: PaginatedArticle;
  /** Get booking details for a specific booking identifier. The identifier must include either a (processId AND a lastname) OR an access token. */
  booking?: Maybe<Booking>;
  /** Access dynamic, configuration data of current imx.Platform instance. */
  dataObjects: DataObjects;
  /**
   * Retrieve an event by its id or permalink.
   *
   * Ensure that at least one of id or permaLink argument is provided. If both are provided, the id will be used.
   */
  event?: Maybe<Event>;
  /** Retrieve an event date by its id. */
  eventDate?: Maybe<EventDate>;
  /** Retrieve a list of event dates. */
  eventDates?: Maybe<PaginatedEventDate>;
  /** Retrieve a list of events. */
  events?: Maybe<PaginatedEvent>;
  /**
   * Searches across all entities for a given string.
   *
   * If orderBy parameter is not specified results are ordered by their score descending (best matches first).
   */
  fulltextsearch?: Maybe<PaginatedFulltextSearchResult>;
  /**
   * Retrieve an offer by its id or permalink.
   *
   * Ensure that at least one of id or permaLink argument is provided. If both are provided, the id will be used.
   */
  offer?: Maybe<Offer>;
  /** Retrieve a list of offers. */
  offers: PaginatedOffer;
  /** Simple test method, which only replies with a simple string. */
  ping: Scalars['String']['output'];
  /**
   * Retrieve a poi by its id or permalink.
   *
   * Ensure that at least one of id or permaLink argument is provided. If both are provided, the id will be used.
   */
  poi?: Maybe<Addressbase>;
  /** Retrieve a list of pois. */
  pois: PaginatedAddressbase;
  /** Get a single vessel by its ID. */
  vessel?: Maybe<Vessel>;
  /**
   * Get all vessels within the hamburg harbour.
   *
   * Marine Traffic API is used as data source and data may be cached.
   */
  vessels: Array<Vessel>;
  /**
   * Get weather data by coordinates. The weather data is retrieved from an external source, which may be selected by providing the source parameter. It depends
   * on the customer´s configuration, if there are multiple sources available.
   *
   * Some weather sources may extend the WeatherLocationInput with additional parameters (e.g. by weather station id).
   */
  weather?: Maybe<Weather>;
  /** Get a imx.Platform Whitelabel Widgets configuration by its id or permalink. */
  widgetConfig?: Maybe<WidgetConfig>;
};


/** Main entry point to imx.Platform GraphQL API. */
export type QueryAnnouncementArgs = {
  id: Scalars['PositiveInt']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
};


/** Main entry point to imx.Platform GraphQL API. */
export type QueryAnnouncementsArgs = {
  filter?: InputMaybe<AnnouncementFilter>;
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<AnnouncementOrder>>;
  pagination?: InputMaybe<Pagination>;
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Main entry point to imx.Platform GraphQL API. */
export type QueryArticleArgs = {
  appearance?: InputMaybe<AppearanceFilter>;
  id?: InputMaybe<Scalars['PositiveInt']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  permaLink?: InputMaybe<Scalars['String']['input']>;
  processing?: InputMaybe<ArticleProcessing>;
};


/** Main entry point to imx.Platform GraphQL API. */
export type QueryArticlesArgs = {
  appearance?: InputMaybe<AppearanceFilter>;
  facets?: InputMaybe<ArticleFacets>;
  filter?: InputMaybe<ArticleFilter>;
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<ArticleOrder>>;
  pagination?: InputMaybe<Pagination>;
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Main entry point to imx.Platform GraphQL API. */
export type QueryBookingArgs = {
  identifier: BookingIdentifier;
  language?: InputMaybe<Scalars['String']['input']>;
};


/** Main entry point to imx.Platform GraphQL API. */
export type QueryEventArgs = {
  appearance?: InputMaybe<AppearanceFilter>;
  id?: InputMaybe<Scalars['PositiveInt']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  permaLink?: InputMaybe<Scalars['String']['input']>;
};


/** Main entry point to imx.Platform GraphQL API. */
export type QueryEventDateArgs = {
  appearance?: InputMaybe<AppearanceFilter>;
  id: Scalars['PositiveInt']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
};


/** Main entry point to imx.Platform GraphQL API. */
export type QueryEventDatesArgs = {
  appearance?: InputMaybe<AppearanceFilter>;
  facets?: InputMaybe<EventDateFacets>;
  filter?: InputMaybe<EventDateFilter>;
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<EventDateOrder>>;
  pagination?: InputMaybe<Pagination>;
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Main entry point to imx.Platform GraphQL API. */
export type QueryEventsArgs = {
  appearance?: InputMaybe<AppearanceFilter>;
  facets?: InputMaybe<EventFacets>;
  filter?: InputMaybe<EventFilter>;
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<EventOrder>>;
  pagination?: InputMaybe<Pagination>;
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Main entry point to imx.Platform GraphQL API. */
export type QueryFulltextsearchArgs = {
  appearance?: InputMaybe<AppearanceFilter>;
  facets?: InputMaybe<FulltextSearchFacets>;
  filter?: InputMaybe<FulltextSearchFilter>;
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<FulltextSearchOrder>>;
  pagination?: InputMaybe<Pagination>;
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Main entry point to imx.Platform GraphQL API. */
export type QueryOfferArgs = {
  appearance?: InputMaybe<AppearanceFilter>;
  id?: InputMaybe<Scalars['PositiveInt']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  permaLink?: InputMaybe<Scalars['String']['input']>;
  processing?: InputMaybe<OfferProcessing>;
};


/** Main entry point to imx.Platform GraphQL API. */
export type QueryOffersArgs = {
  appearance?: InputMaybe<AppearanceFilter>;
  facets?: InputMaybe<OfferFacets>;
  filter?: InputMaybe<OfferFilter>;
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<OfferOrder>>;
  pagination?: InputMaybe<Pagination>;
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Main entry point to imx.Platform GraphQL API. */
export type QueryPoiArgs = {
  appearance?: InputMaybe<AppearanceFilter>;
  id?: InputMaybe<Scalars['PositiveInt']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  permaLink?: InputMaybe<Scalars['String']['input']>;
  processing?: InputMaybe<AddressbaseProcessing>;
};


/** Main entry point to imx.Platform GraphQL API. */
export type QueryPoisArgs = {
  appearance?: InputMaybe<AppearanceFilter>;
  facets?: InputMaybe<AddressbaseFacets>;
  filter?: InputMaybe<AddressbaseFilter>;
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<AddressbaseOrder>>;
  pagination?: InputMaybe<Pagination>;
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Main entry point to imx.Platform GraphQL API. */
export type QueryVesselArgs = {
  id: Scalars['Int']['input'];
};


/** Main entry point to imx.Platform GraphQL API. */
export type QueryWeatherArgs = {
  language?: Scalars['String']['input'];
  location: WeatherLocationInput;
  source?: WeatherSource;
};


/** Main entry point to imx.Platform GraphQL API. */
export type QueryWidgetConfigArgs = {
  id?: InputMaybe<Scalars['PositiveInt']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
};

export type RfaModuleFilter = {
  /** Filter results by rfa category ids */
  categories?: InputMaybe<IntArrayPredicate>;
  /** Filter results by having a valid classification check for the given date */
  classificationAt?: InputMaybe<Scalars['LocalDate']['input']>;
  /** Filter results by rfa criterion ids */
  criteria?: InputMaybe<IntArrayPredicate>;
  /** Filter results by rfa by having information for accessibility. TRUE = having information, FALSE = no information */
  information?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter results by rfa beeing accessibility checked. TRUE = having beeing checked, FALSE = not have beeing checked */
  validityChecked?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Region = {
  __typename?: 'Region';
  _entityType?: Maybe<Scalars['String']['output']>;
  children?: Maybe<Array<Region>>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  parent?: Maybe<Region>;
};

export type RegiondoCategory = {
  __typename?: 'RegiondoCategory';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type RegiondoOffer = {
  __typename?: 'RegiondoOffer';
  _entityType?: Maybe<Scalars['String']['output']>;
  appointmentTypes?: Maybe<Scalars['String']['output']>;
  asGift?: Maybe<Scalars['Boolean']['output']>;
  bookingNoticePeriod?: Maybe<Scalars['Int']['output']>;
  categories?: Maybe<Array<RegiondoCategory>>;
  durationType?: Maybe<Scalars['String']['output']>;
  durationValues?: Maybe<Scalars['String']['output']>;
  faqCustomerRequirements?: Maybe<Scalars['String']['output']>;
  faqNotIncluded?: Maybe<Scalars['String']['output']>;
  faqOtherInfo?: Maybe<Scalars['String']['output']>;
  faqParticipants?: Maybe<Scalars['String']['output']>;
  faqSpectators?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  importantInfo?: Maybe<Scalars['String']['output']>;
  infoPdfs?: Maybe<Array<SimpleString>>;
  languages?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  parkingOptionsComment?: Maybe<Scalars['String']['output']>;
  productSupplierId?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  publicTransportComment?: Maybe<Scalars['String']['output']>;
  skuDataProduct?: Maybe<Scalars['String']['output']>;
};

export type Relevance = {
  __typename?: 'Relevance';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ReliabilityEntityClassification = {
  __typename?: 'ReliabilityEntityClassification';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RfaAddressbase = {
  __typename?: 'RfaAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  categories?: Maybe<Array<RfaCategory>>;
  classificationFromDate?: Maybe<Scalars['String']['output']>;
  classificationToDate?: Maybe<Scalars['String']['output']>;
  criteria?: Maybe<Array<RfaCriterion>>;
  guestGroupCognitiveText?: Maybe<Scalars['String']['output']>;
  guestGroupHearingText?: Maybe<Scalars['String']['output']>;
  guestGroupMobilityText?: Maybe<Scalars['String']['output']>;
  guestGroupVisualText?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  information?: Maybe<Scalars['Boolean']['output']>;
  partner?: Maybe<RfaPartner>;
  stages?: Maybe<Array<RfaStage>>;
  taskNumber?: Maybe<Scalars['String']['output']>;
  urlDeaf?: Maybe<Scalars['String']['output']>;
  urlMental?: Maybe<Scalars['String']['output']>;
  urlVisual?: Maybe<Scalars['String']['output']>;
  urlWalking?: Maybe<Scalars['String']['output']>;
  urlWheelchair?: Maybe<Scalars['String']['output']>;
  validityChecked?: Maybe<Scalars['Boolean']['output']>;
};

export type RfaCategory = {
  __typename?: 'RfaCategory';
  _entityType?: Maybe<Scalars['String']['output']>;
  attributeGroup?: Maybe<RfaCategoryGroup>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type RfaCategoryGroup = {
  __typename?: 'RfaCategoryGroup';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type RfaCriterion = {
  __typename?: 'RfaCriterion';
  _entityType?: Maybe<Scalars['String']['output']>;
  attributeGroup?: Maybe<RfaCriterionGroup>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type RfaCriterionGroup = {
  __typename?: 'RfaCriterionGroup';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type RfaPartner = {
  __typename?: 'RfaPartner';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type RfaStage = {
  __typename?: 'RfaStage';
  _entityType?: Maybe<Scalars['String']['output']>;
  attributeGroup?: Maybe<RfaStageGroup>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type RfaStageGroup = {
  __typename?: 'RfaStageGroup';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type RyddleTicket = {
  /** Chat or device identifier: as one booking may contain multiple tickets, this is the identifier of the ticket to be invalidated. */
  identifier: Scalars['String']['input'];
  /** Process id (tomas) of booking. */
  processId: Scalars['String']['input'];
};

export type SailingAddressbase = {
  __typename?: 'SailingAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  area?: Maybe<SailingArea>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type SailingArea = {
  __typename?: 'SailingArea';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type SailingCourse = {
  __typename?: 'SailingCourse';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type SailingSchoolAddressbase = {
  __typename?: 'SailingSchoolAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  certificates?: Maybe<Array<SailingSchoolCertificate>>;
  courses?: Maybe<Array<SailingCourse>>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type SailingSchoolCertificate = {
  __typename?: 'SailingSchoolCertificate';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** Define which productlines should be included in the result. */
export type SchemaOrgAddressbaseContext = {
  /** Define the productline context to be included. */
  productlines?: InputMaybe<SchemaOrgProductlineContext>;
};

/** Define which productlines should be included in the result. */
export type SchemaOrgOfferContext = {
  /** Define the productline context to be included. */
  productlines?: InputMaybe<SchemaOrgProductlineContext>;
};

export type SchemaOrgProcessing = {
  url?: InputMaybe<SchemaOrgUrlProcessing>;
};

export type SchemaOrgProductlineContext = {
  /**
   * Include all productline specific representations.
   *
   * If "true", the "allOf" field is ignored.
   */
  includeAll?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Include only the specified productline specific representations.
   *
   * Caution: will only be considered, if "includeAll" is "false" or empty.
   */
  oneOf?: InputMaybe<Array<Scalars['PositiveInt']['input']>>;
};

export type SchemaOrgRepresentation = {
  __typename?: 'SchemaOrgRepresentation';
  jsonLd: Scalars['JSON']['output'];
};


export type SchemaOrgRepresentationJsonLdArgs = {
  processing?: InputMaybe<SchemaOrgProcessing>;
};

export type SchemaOrgUrlProcessing = {
  replaceWith?: InputMaybe<Scalars['String']['input']>;
};

export type SimpleString = {
  __typename?: 'SimpleString';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type SocialMedia = {
  __typename?: 'SocialMedia';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  identification?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<SocialMediaProvider>;
};

export type SocialMediaProvider = {
  __typename?: 'SocialMediaProvider';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** Enum with possible values for sorting order. */
export enum SortOrder {
  /** Ascending order. */
  Asc = 'ASC',
  /** Descending order. */
  Desc = 'DESC'
}

export type SpecialExperience = {
  __typename?: 'SpecialExperience';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type SpecificEventDate = {
  __typename?: 'SpecificEventDate';
  _entityType?: Maybe<Scalars['String']['output']>;
  bookingLink?: Maybe<Scalars['String']['output']>;
  cancelled?: Maybe<Scalars['Boolean']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  modificationType?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  soldout?: Maybe<Scalars['Boolean']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
};

export type Story = {
  __typename?: 'Story';
  _entityType?: Maybe<Scalars['String']['output']>;
  detailedDescription?: Maybe<Scalars['String']['output']>;
  generalDescription?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  sortValue?: Maybe<Scalars['Int']['output']>;
  storyContacts?: Maybe<Array<StoryContact>>;
  storyImages?: Maybe<Array<StoryImage>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StoryArticle = {
  __typename?: 'StoryArticle';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  stories?: Maybe<Array<Story>>;
};

export type StoryContact = {
  __typename?: 'StoryContact';
  _entityType?: Maybe<Scalars['String']['output']>;
  contactAddressPoi?: Maybe<ContactAddressPoi>;
  contextProductline?: Maybe<Productline>;
  id?: Maybe<Scalars['Int']['output']>;
  purpose?: Maybe<ContactPurpose>;
  sortValue?: Maybe<Scalars['Int']['output']>;
};

export type StoryImage = {
  __typename?: 'StoryImage';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type StringPredicate = {
  eq?: InputMaybe<Scalars['String']['input']>;
  noneOf?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<Scalars['String']['input']>;
  oneOf?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SurfKiteAdditional = {
  __typename?: 'SurfKiteAdditional';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type SurfKiteAddressbase = {
  __typename?: 'SurfKiteAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  additionals?: Maybe<Array<SurfKiteAdditional>>;
  certificates?: Maybe<Array<SurfKiteCertificate>>;
  courses?: Maybe<Array<SurfKiteCourse>>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type SurfKiteCertificate = {
  __typename?: 'SurfKiteCertificate';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type SurfKiteCourse = {
  __typename?: 'SurfKiteCourse';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Tag = {
  __typename?: 'Tag';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TargetGroup = {
  __typename?: 'TargetGroup';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type TashBeachAddressbase = {
  __typename?: 'TashBeachAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  /** Returns the first attribute definition of this accommodation addressbase. Can be filtered. */
  attributeDefinition?: Maybe<ExtendedAttributeDefinition>;
  /** Returns the attribute definitions of this accommodation addressbase. Can be filtered. */
  attributeDefinitions?: Maybe<Array<ExtendedAttributeDefinition>>;
  id?: Maybe<Scalars['Int']['output']>;
};


export type TashBeachAddressbaseAttributeDefinitionArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};


export type TashBeachAddressbaseAttributeDefinitionsArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};

export type TemperatureRange = {
  /** Maximum temperature in °C. */
  max: Scalars['Float']['output'];
  /** Minimum temperature in °C. */
  min: Scalars['Float']['output'];
};

export type TicketOnline = {
  __typename?: 'TicketOnline';
  _entityType?: Maybe<Scalars['String']['output']>;
  contributorClient?: Maybe<Scalars['String']['output']>;
  index?: Maybe<Scalars['String']['output']>;
  locationNumber?: Maybe<Scalars['String']['output']>;
  search?: Maybe<Scalars['String']['output']>;
  showTicketlink?: Maybe<Scalars['Boolean']['output']>;
};

export type TimeIntervalDefinition = {
  __typename?: 'TimeIntervalDefinition';
  _entityType?: Maybe<Scalars['String']['output']>;
  day?: Maybe<DayDefinition>;
  id?: Maybe<Scalars['Int']['output']>;
  timeFrom?: Maybe<Scalars['String']['output']>;
  timeTo?: Maybe<Scalars['String']['output']>;
};

export type TimeIntervalDefinitionFilter = {
  /** Only include currently valid time intervals (shortcut for providing `weekday.oneOf = [weekdayOf(now)]` and `validAt = time(now)`; overrides weekday and validAt if set). */
  current?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only include time intervals that are valid today (shortcut for providing `weekday.oneOf = [weekdayOf(now)]`; overrides weekday). */
  today?: InputMaybe<Scalars['Boolean']['input']>;
  /** Include only time intervals, which include provided time. */
  validAt?: InputMaybe<Scalars['LocalTime']['input']>;
  /** Include only time intervals in results that are valid for provided weekday predicate. */
  weekday?: InputMaybe<WeekdayPredicate>;
};

export type TimeRangeFilter = {
  from?: InputMaybe<Scalars['LocalTime']['input']>;
  to?: InputMaybe<Scalars['LocalTime']['input']>;
};

export type TimeRangePredicate = {
  /** Minimum time to match within the range (i.e. inclusive lower bound). */
  from?: InputMaybe<Scalars['LocalTime']['input']>;
  /** Maximum time to match within the range (i.e. inclusive upper bound). */
  to?: InputMaybe<Scalars['LocalTime']['input']>;
};

export type Topic = {
  __typename?: 'Topic';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type TourAddressbase = {
  __typename?: 'TourAddressbase';
  _entityType?: Maybe<Scalars['String']['output']>;
  additionalInformation?: Maybe<Scalars['String']['output']>;
  /** Returns the first attribute definition of this offer. Can be filtered. */
  attributeDefinition?: Maybe<ExtendedAttributeDefinition>;
  /** Returns the attribute definitions of this offer. Can be filtered. */
  attributeDefinitions?: Maybe<Array<ExtendedAttributeDefinition>>;
  attributes?: Maybe<Array<TourAttribute>>;
  categories?: Maybe<Array<TourCategory>>;
  condition?: Maybe<TourCondition>;
  destination?: Maybe<Scalars['String']['output']>;
  difficulties?: Maybe<Array<TourDifficulty>>;
  directions?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  durationDays?: Maybe<Scalars['Int']['output']>;
  durationDownhill?: Maybe<Scalars['Int']['output']>;
  elevation?: Maybe<TourElevation>;
  equipment?: Maybe<Scalars['String']['output']>;
  expositions?: Maybe<Array<TourExposition>>;
  geometry?: Maybe<Scalars['String']['output']>;
  gettingThere?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  length?: Maybe<Scalars['Float']['output']>;
  literature?: Maybe<Scalars['String']['output']>;
  locations?: Maybe<Array<Location>>;
  maps?: Maybe<Scalars['String']['output']>;
  months?: Maybe<Array<TourMonth>>;
  objective?: Maybe<Scalars['String']['output']>;
  parking?: Maybe<Scalars['String']['output']>;
  publicTransit?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<TourRating>;
  riskPotential?: Maybe<Scalars['Float']['output']>;
  routeNumber?: Maybe<Scalars['String']['output']>;
  routeType?: Maybe<Scalars['String']['output']>;
  safetyGuidelines?: Maybe<Scalars['String']['output']>;
  stages?: Maybe<Scalars['String']['output']>;
  startingPointDescr?: Maybe<Scalars['String']['output']>;
  tip?: Maybe<Scalars['String']['output']>;
  trackSignage?: Maybe<Scalars['String']['output']>;
  trackTypes?: Maybe<Array<TourTrackType>>;
};


export type TourAddressbaseAttributeDefinitionArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};


export type TourAddressbaseAttributeDefinitionsArgs = {
  filter?: InputMaybe<AttributeDefinitionsFilter>;
};

export type TourAttribute = {
  __typename?: 'TourAttribute';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TourCategory = {
  __typename?: 'TourCategory';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<TourCategory>;
};

export type TourCondition = {
  __typename?: 'TourCondition';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type TourDifficulty = {
  __typename?: 'TourDifficulty';
  _entityType?: Maybe<Scalars['String']['output']>;
  attributeGroup?: Maybe<TourDifficultyGroup>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type TourDifficultyGroup = {
  __typename?: 'TourDifficultyGroup';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
};

export type TourElevation = {
  __typename?: 'TourElevation';
  _entityType?: Maybe<Scalars['String']['output']>;
  ascent?: Maybe<Scalars['Int']['output']>;
  descent?: Maybe<Scalars['Int']['output']>;
  maxAltitude?: Maybe<Scalars['Int']['output']>;
  minAltitude?: Maybe<Scalars['Int']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
};

export type TourElevationFilter = {
  /** Ascent in meters. */
  ascent?: InputMaybe<IntPredicate>;
  /** Descent in meters. */
  descent?: InputMaybe<IntPredicate>;
};

export type TourExposition = {
  __typename?: 'TourExposition';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TourModuleFilter = {
  /**
   * Filter results by their attributes, e.g. family friendly, barrierfree, etc.
   *
   * Tip: Possible values are defined by the data object "TourAttribute".
   */
  attributes?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their categories, e.g. hiking, biking, etc.
   *
   * Tip: Possible values are defined by the data object "TourCategory".
   */
  categories?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their track condition, e.g. opened, closed, etc.
   *
   * Tip: Possible values are defined by the data object "TourCondition".
   */
  condition?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their assigned difficulty.
   *
   * Tip: Possible values are defined by the data object "TourDifficulty".
   */
  difficulties?: InputMaybe<IntArrayPredicate>;
  /** Filter results by their duration in minutes. */
  duration?: InputMaybe<IntPredicate>;
  /** Filter results by their elevation in meters. */
  elevation?: InputMaybe<TourElevationFilter>;
  /** Filter results by their length in meters. */
  length?: InputMaybe<IntPredicate>;
  /** Filter results by recommended months for tour. (1 = January, 2 = February, .... 12 = December) */
  months?: InputMaybe<IntArrayPredicate>;
  /**
   * Filter results by their track types.
   *
   * Tip: Possible values are defined by the data object "TourTrackType".
   */
  trackTypes?: InputMaybe<IntArrayPredicate>;
};

export type TourMonth = {
  __typename?: 'TourMonth';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TourRating = {
  __typename?: 'TourRating';
  _entityType?: Maybe<Scalars['String']['output']>;
  communityRating?: Maybe<Scalars['Float']['output']>;
  communityRatingCount?: Maybe<Scalars['Int']['output']>;
  condition?: Maybe<Scalars['Float']['output']>;
  difficulty?: Maybe<Scalars['Float']['output']>;
  landscape?: Maybe<Scalars['Float']['output']>;
  qualityLevel?: Maybe<Scalars['Float']['output']>;
  qualityOfExperience?: Maybe<Scalars['Float']['output']>;
  technique?: Maybe<Scalars['Float']['output']>;
};

export type TourTrackType = {
  __typename?: 'TourTrackType';
  _entityType?: Maybe<Scalars['String']['output']>;
  i18nName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type TravelArguments = {
  /** The mode of transport to use for calculating travel data. */
  mode: TravelMode;
  /** The point from which to calculate travel data. */
  point: GeoPoint;
};

export type TravelData = {
  __typename?: 'TravelData';
  /**
   * The calculated distance in meters. This won't get calculated if the mode is `TravelTimeMode.PUBLIC_TRANSPORT` and won't be available if out of range or the
   * object has no coordinate.
   */
  distance?: Maybe<Scalars['NonNegativeInt']['output']>;
  /** The calculated time in seconds. Won't be available if out of range or the object has no coordinate. */
  time?: Maybe<Scalars['NonNegativeInt']['output']>;
};

export enum TravelMode {
  /** Travel by cycling (including ferries). */
  Cycling = 'CYCLING',
  /** Travel by car (including ferries). */
  Driving = 'DRIVING',
  /** Travel by public transport. */
  PublicTransport = 'PUBLIC_TRANSPORT',
  /** Travel by walking (including ferries). */
  Walking = 'WALKING'
}

export type Typo3Category = {
  __typename?: 'Typo3Category';
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type Typo3Document = {
  __typename?: 'Typo3Document';
  author?: Maybe<Scalars['String']['output']>;
  authorEmail?: Maybe<Scalars['String']['output']>;
  authorUid?: Maybe<Scalars['Int']['output']>;
  categories?: Maybe<Array<Maybe<Typo3Category>>>;
  customDate?: Maybe<Scalars['LocalDate']['output']>;
  geoInfo?: Maybe<Typo3GeoInfo>;
  image?: Maybe<Typo3Image>;
  longDescription?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  uid: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type Typo3Filter = {
  and?: InputMaybe<Array<Typo3Filter>>;
  /** Article author (numeric ID) */
  author?: InputMaybe<IntPredicate>;
  /** Contrain typo3 contents by category ids. */
  categories?: InputMaybe<IntArrayPredicate>;
  /** Contrain typo3 contents by fulltext search query. */
  fulltext?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<Array<Typo3Filter>>;
  or?: InputMaybe<Array<Typo3Filter>>;
  uid?: InputMaybe<IntPredicate>;
};

export type Typo3GeoInfo = {
  __typename?: 'Typo3GeoInfo';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

export type Typo3Image = {
  __typename?: 'Typo3Image';
  copyright?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type UnitBasedUpsellBookingItem = BookingItem & {
  __typename?: 'UnitBasedUpsellBookingItem';
  /** Validity in days of upsell item. */
  duration: Scalars['PositiveInt']['output'];
  /** Date from which the booking item is valid. */
  fromDate: Scalars['LocalDate']['output'];
  /**
   * ID of booking item.
   *
   * (Hint: it is not the id of the underlying service)
   */
  id: Scalars['String']['output'];
  /** Flag indicating if the booking item is still active. */
  isActive: Scalars['Boolean']['output'];
  /** true if booking item is in future or today. */
  isValid: Scalars['Boolean']['output'];
  /** Name of the booking item */
  name: Scalars['String']['output'];
  /** Number of upselled tickets. */
  numberOfUnits: Scalars['PositiveInt']['output'];
  /** Internal service id of the booking item. */
  serviceId: Scalars['String']['output'];
  /** State of the booking item (i.e. ACTIVE, CANCELLED, ...) */
  state: BookingState;
  /** Determines type of ticket, i.e. single or group ticket. */
  ticketType?: Maybe<HamburgCardTicketType>;
  /** Total price of the booking item. */
  totalPrice: Scalars['Float']['output'];
};

export type UnitBasedUpsellProduct = HhtBaseProduct & {
  __typename?: 'UnitBasedUpsellProduct';
  /** Cumulated availability of all product variants. */
  availability?: Maybe<ProductAvailability>;
  /** Min price of the cheapest offer (in EUR). */
  minPrice?: Maybe<Scalars['Float']['output']>;
  /** Internal service IDs. */
  serviceIds: Array<Scalars['String']['output']>;
  /** Product variants (vacancy checked) for the given configuration. */
  variants?: Maybe<Array<UnitBasedUpsellProductOffer>>;
};


export type UnitBasedUpsellProductVariantsArgs = {
  filter?: InputMaybe<UnitBasedUpsellProductVariantFilter>;
};

export type UnitBasedUpsellProductOffer = {
  __typename?: 'UnitBasedUpsellProductOffer';
  /** Validity duration of the hamburg card in days. */
  duration: Scalars['PositiveInt']['output'];
  numberOfUnits: Scalars['PositiveInt']['output'];
  /** price per unit/person for the current configuration (in EUR). */
  pricePerUnit: Scalars['Float']['output'];
  /** Internal service id of the hamburg card. */
  serviceId: Scalars['String']['output'];
  /** Hamburg Card Ticket Type (single or group ticket). */
  ticketType: HamburgCardTicketType;
  /** Total price (for all units/persons) for the current configuration (in EUR). */
  totalPrice: Scalars['Float']['output'];
};

export type UnitBasedUpsellProductVariantFilter = {
  /**
   * Filter by duration (days).
   *
   * Default: allow all offered durations
   */
  duration?: InputMaybe<IntPredicate>;
  /**
   * Default: today (defined by the server, i.e. according to CET/CEST)
   *
   * Must be today or in future.
   */
  fromDate?: InputMaybe<Scalars['LocalDate']['input']>;
  /**
   * Number of tickets/units to be booked.
   *
   * Default: 1
   */
  numberOfUnits?: InputMaybe<Scalars['PositiveInt']['input']>;
  /**
   * Constrain by hamburg card ticket type (e.g. single or group tickets)
   *
   * Default: allow all types
   */
  ticketType?: InputMaybe<HamburgCardTicketType>;
};

export type User = {
  __typename?: 'User';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Vessel = {
  __typename?: 'Vessel';
  /** Further explanation of the SHIPTYPE ID */
  aisTypeSummary?: Maybe<Scalars['String']['output']>;
  /** The average speed calculated for the subject vessel during the latest voyage (port to port) */
  avgSpeed?: Maybe<Scalars['String']['output']>;
  /** A uniquely designated identifier for the vessel's transmitter station */
  callsign?: Maybe<Scalars['String']['output']>;
  /** The course (in degrees) that the subject vessel is reporting according to AIS transmissions */
  course?: Maybe<Scalars['String']['output']>;
  /** The name of the Port the subject vessel is currently in (NULL if the vessel is underway) */
  currentPort?: Maybe<Scalars['String']['output']>;
  /** The Country that the Current Port is located at */
  currentPortCountry?: Maybe<Scalars['String']['output']>;
  /** A uniquely assigned ID by MarineTraffic for the Current Port */
  currentPortId?: Maybe<Scalars['String']['output']>;
  /** A uniquely assigned ID by United Nations for the Current Port */
  currentPortUnlocode?: Maybe<Scalars['String']['output']>;
  /** The Destination of the subject vessel according to the AIS transmissions */
  destination?: Maybe<Scalars['String']['output']>;
  /** The Remaining Distance (in NM) for the subject vessel to reach the reported Destination */
  distanceToGo?: Maybe<Scalars['String']['output']>;
  /** The Distance (in NM) that the subject vessel has travelled since departing from Last Port */
  distanceTravelled?: Maybe<Scalars['String']['output']>;
  /** The Draught (in metres x10) of the subject vessel according to the AIS transmissions */
  draught?: Maybe<Scalars['String']['output']>;
  /** Describes whether the transmitted AIS message was received by a terrestrial (TER), satellite (SAT) or roaming (ROAM) AIS antenna. */
  dsrc?: Maybe<Scalars['String']['output']>;
  /** Deadweight - a measure (in metric tons) of how much weight a vessel can safely carry (excluding the vessel's own weight) */
  dwt?: Maybe<Scalars['String']['output']>;
  /** The Estimated Time of Arrival to Destination of the subject vessel according to the AIS transmissions */
  eta?: Maybe<Scalars['String']['output']>;
  /** The Estimated Time of Arrival to Destination of the subject vessel according to the MarineTraffic calculations */
  etaCalc?: Maybe<Scalars['String']['output']>;
  /** The date and time (in UTC) that the ETA was calculated by MarineTraffic */
  etaUpdated?: Maybe<Scalars['String']['output']>;
  /** The flag of the subject vessel according to AIS transmissions */
  flag?: Maybe<Scalars['String']['output']>;
  /** Gross Tonnage - unitless measure that calculates the moulded volume of all enclosed spaces of a ship */
  grt?: Maybe<Scalars['String']['output']>;
  /** The heading (in degrees) that the subject vessel is reporting according to AIS transmissions */
  heading?: Maybe<Scalars['String']['output']>;
  /** International Maritime Organisation number - a seven-digit number that uniquely identifies vessels */
  imo?: Maybe<Scalars['String']['output']>;
  /** The relative distance from the AIS station of the vessel to the foremost of it (front / bow) */
  lFore?: Maybe<Scalars['String']['output']>;
  /** The Name of the Last Port the vessel has visited */
  lastPort?: Maybe<Scalars['String']['output']>;
  /** The Country that the Last Port is located at */
  lastPortCountry?: Maybe<Scalars['String']['output']>;
  /** A uniquely assigned ID by MarineTraffic for the Last Port */
  lastPortId?: Maybe<Scalars['String']['output']>;
  /** The Date and Time (in UTC) that the subject vessel departed from the Last Port */
  lastPortTime?: Maybe<Scalars['String']['output']>;
  /** A uniquely assigned ID by United Nations for the Last Port */
  lastPortUnlocode?: Maybe<Scalars['String']['output']>;
  /** Latitude - a geographic coordinate that specifies the north-south position of the vessel on the Earth's surface */
  lat?: Maybe<Scalars['String']['output']>;
  /** The overall Length (in metres) of the subject vessel */
  length?: Maybe<Scalars['String']['output']>;
  /** Longitude - a geographic coordinate that specifies the east-west position of the vessel on the Earth's surface */
  lon?: Maybe<Scalars['String']['output']>;
  /** Vessel's commercial market */
  market?: Maybe<Scalars['String']['output']>;
  /** The maximum speed reported by the subject vessel during the latest voyage (port to port) */
  maxSpeed?: Maybe<Scalars['String']['output']>;
  /** Maritime Mobile Service Identity - a nine-digit number sent in digital form over a radio frequency that identifies the vessel's transmitter station */
  mmsi?: Maybe<Scalars['String']['output']>;
  /** The Country that the Next Port is located at */
  nextPortCountry?: Maybe<Scalars['String']['output']>;
  /** A uniquely assigned ID by MarineTraffic for the Next Port */
  nextPortId?: Maybe<Scalars['String']['output']>;
  /** The Name of the Next Port as derived by MarineTraffic based on the subject vessel's reported Destination */
  nextPortName?: Maybe<Scalars['String']['output']>;
  /** A uniquely assigned ID by United Nations for the Next Port */
  nextPortUnlocode?: Maybe<Scalars['String']['output']>;
  /** Rate of Turn */
  rot?: Maybe<Scalars['String']['output']>;
  /** Vessel's class based on commercial market, capacity and/or dimensions */
  shipClass?: Maybe<Scalars['String']['output']>;
  /** The vessel's country */
  shipCountry?: Maybe<Scalars['String']['output']>;
  /** A uniquely assigned ID by MarineTraffic for the subject vessel */
  shipId?: Maybe<Scalars['String']['output']>;
  /** The Shipname of the subject vessel */
  shipname?: Maybe<Scalars['String']['output']>;
  /** The Shiptype of the subject vessel according to AIS transmissions */
  shiptype?: Maybe<Scalars['String']['output']>;
  /** The speed (in knots x10) that the subject vessel is reporting according to AIS transmissions */
  speed?: Maybe<Scalars['String']['output']>;
  /** The AIS Navigational Status of the subject vessel as input by the vessel's crew. There might be discrepancies with the vessel's detail page when vessel speed is near zero (0) knots */
  status?: Maybe<Scalars['String']['output']>;
  /** The date and time (in UTC) that the subject vessel's position or event was recorded by MarineTraffic */
  timestamp?: Maybe<Scalars['String']['output']>;
  /** The MarineTraffic ship type of the vessel */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The time slot that the subject vessel uses to transmit information */
  utcSeconds?: Maybe<Scalars['String']['output']>;
  /** The relative distance from the AIS station of the vessel to the leftmost of it (left side / port) */
  wLeft?: Maybe<Scalars['String']['output']>;
  /** The Breadth (in metres) of the subject vessel */
  width?: Maybe<Scalars['String']['output']>;
  /** The year that the subject vessel was built */
  yearBuilt?: Maybe<Scalars['String']['output']>;
};

export type Weather = {
  /** Retrieve current weather conditions. */
  current?: Maybe<CurrentWeather>;
  /** Retrieve weather forecast. The available time range depends on the underlying weather source. */
  forecast?: Maybe<WeatherForecast>;
};

export type WeatherCondition = {
  /**
   * Weather code according to WMO code table FM 94 BUFR/FM 95 CREX code table 0 20 003 – present weather.
   *
   * This code may be used as a sort value, when you want to sort weather conditions by severity, e.g. DWD displays
   * the icon of the most severe weather condition in the forecast for a specific day or time range.
   *
   * API may not return all possible codes of the mentioned code table, but only codes which are assigned with possible weather type.
   */
  code: Scalars['NonNegativeInt']['output'];
  /** Textual weather condition summary in the requested language. */
  summary?: Maybe<Scalars['String']['output']>;
  /** Enumerated type of weather condition. */
  type: WeatherConditionType;
};

export enum WeatherConditionType {
  /** Clear sky. WMO code 0. */
  ClearSky = 'CLEAR_SKY',
  /** Cloudy/overcast. WMO code 3. */
  Cloudy = 'CLOUDY',
  /** Slight drizzle. WMO code 51. */
  Drizzle = 'DRIZZLE',
  /** Fog. WMO code 45. */
  Fog = 'FOG',
  /** Drizzle, freezing, moderate or heavy. WMO code 57. */
  FreezingDrizzle = 'FREEZING_DRIZZLE',
  /** Rain, freezing, moderate or heavy. WMO code 67. */
  FreezingRain = 'FREEZING_RAIN',
  /** Heavy drizzle. WMO code 55. */
  HeavyDrizzle = 'HEAVY_DRIZZLE',
  /** Heavy rain, not freezing. WMO code 65. */
  HeavyRain = 'HEAVY_RAIN',
  /** Heavy fall of snowflakes. WMO code 75. */
  HeavySnow = 'HEAVY_SNOW',
  /** Thunderstorm with hail, or heavy thunderstorm. WMO code 96. */
  HeavyThunderstorm = 'HEAVY_THUNDERSTORM',
  /** Mainly clear. WMO code 1. */
  MainlyClear = 'MAINLY_CLEAR',
  /** Moderate rain, not freezing. WMO code 63. */
  ModerateRain = 'MODERATE_RAIN',
  /** Moderate fall of snowflakes. WMO code 73. */
  ModerateSnow = 'MODERATE_SNOW',
  /** Partly/generally cloudy. WMO code 2. */
  PartlyCloudy = 'PARTLY_CLOUDY',
  /** Rain shower(s), moderate or heavy. WMO code 81. */
  RainShower = 'RAIN_SHOWER',
  /** Drizzle, freezing, slight. WMO code 56. */
  SlightFreezingDrizzle = 'SLIGHT_FREEZING_DRIZZLE',
  /** Rain, freezing, slight. WMO code 66. */
  SlightFreezingRain = 'SLIGHT_FREEZING_RAIN',
  /** Slight rain, not freezing. WMO code 61. */
  SlightRain = 'SLIGHT_RAIN',
  /** Rain shower(s), slight. WMO code 80. */
  SlightRainShower = 'SLIGHT_RAIN_SHOWER',
  /** Slight fall of snowflakes. WMO code 71. */
  SlightSnow = 'SLIGHT_SNOW',
  /** Snow shower(s), slight. WMO code 85. */
  SlightSnowShower = 'SLIGHT_SNOW_SHOWER',
  /** Snow grains. WMO code 77. */
  SnowGrains = 'SNOW_GRAINS',
  /** Snow shower(s), moderate or heavy. WMO code 86. */
  SnowShower = 'SNOW_SHOWER',
  /** Thunderstorm, slight or moderate. WMO code 95. */
  Thunderstorm = 'THUNDERSTORM',
  /** Rain shower(s), violent. WMO code 82. */
  ViolentRainShower = 'VIOLENT_RAIN_SHOWER'
}

export type WeatherForecast = {
  /**
   * Returns a daily forecast for the next days.
   *
   * It depends on the underlying weather source, if daily forecasts are available. Also the time range depends on the underlying weather source.
   */
  daily: Array<DailyWeatherForecast>;
  /**
   * Returns an hourly forecast for the next days.
   *
   * It depends on the underlying weather source, if hourly forecasts are available. Also the time range and the provided intervals depend on the underlying weather source.
   */
  hourly: Array<HourlyWeatherForecast>;
};

export type WeatherLocationInput = {
  /** Coordinates of the location, you want the weather data for. */
  point?: InputMaybe<GeoPoint>;
};

export enum WeatherSource {
  /** Using a default weather source. The default source is defined the customers configuration. */
  Default = 'DEFAULT',
  /** Weather source for MapToolkit, which uses DWD internally as weather source. */
  Maptoolkit = 'MAPTOOLKIT'
}

export enum Weekday {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type WeekdayArrayPredicate = {
  allOf?: InputMaybe<Array<Weekday>>;
  noneOf?: InputMaybe<Array<Weekday>>;
  oneOf?: InputMaybe<Array<Weekday>>;
};

export type WeekdayPredicate = {
  eq?: InputMaybe<Weekday>;
  noneOf?: InputMaybe<Array<Weekday>>;
  not?: InputMaybe<Weekday>;
  oneOf?: InputMaybe<Array<Weekday>>;
};

export type WidgetConfig = {
  __typename?: 'WidgetConfig';
  _entityType?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  contactName?: Maybe<Scalars['String']['output']>;
  creationTime?: Maybe<Scalars['String']['output']>;
  defaultEntityType?: Maybe<Scalars['String']['output']>;
  deliveryChannel?: Maybe<DeliveryChannel>;
  email?: Maybe<Scalars['String']['output']>;
  /** Return a list of links, which should be displayed in the footer of an imx.Platform Whitelabel Widget. */
  footerLinkTargets?: Maybe<Array<LinkTarget>>;
  footerLogoFileName?: Maybe<Scalars['String']['output']>;
  footerLogoInfoUrl?: Maybe<Scalars['String']['output']>;
  footerLogoUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  imprintUrl?: Maybe<Scalars['String']['output']>;
  includeSubClients?: Maybe<Scalars['Boolean']['output']>;
  lastChangeTime?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use 'WidgetConfigTypeDefs.header' instead */
  locationLabel?: Maybe<Scalars['String']['output']>;
  logoFileName?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  privacyUrl?: Maybe<Scalars['String']['output']>;
  quickAccessList?: Maybe<Scalars['Boolean']['output']>;
  showFooterLogo?: Maybe<Scalars['Boolean']['output']>;
  showLogo?: Maybe<Scalars['Boolean']['output']>;
  showSearchResultHeader?: Maybe<Scalars['Boolean']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNo?: Maybe<Scalars['String']['output']>;
  styling?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  typeDefs?: Maybe<Array<WidgetConfigTypeDefs>>;
  usageForEntityType?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};


export type WidgetConfigFooterLinkTargetsArgs = {
  sortOrder?: InputMaybe<SortOrder>;
};

export type WidgetConfigCategory = {
  __typename?: 'WidgetConfigCategory';
  _entityType?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Category>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
};

export type WidgetConfigEntryViewType = {
  __typename?: 'WidgetConfigEntryViewType';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type WidgetConfigEventDef = {
  __typename?: 'WidgetConfigEventDef';
  _entityType?: Maybe<Scalars['String']['output']>;
  categoryFilterCategories?: Maybe<Array<Category>>;
  categoryFilterEnabled?: Maybe<Scalars['Boolean']['output']>;
  coordinates?: Maybe<LatLon>;
  criterionFilterCriteria?: Maybe<Array<Criterion>>;
  criterionFilterEnabled?: Maybe<Scalars['Boolean']['output']>;
  dateSearchType?: Maybe<Scalars['Int']['output']>;
  /** Return a list of group items (defined by event categories), which should be displayed in the event entry page of an imx.Platform Whitelabel Widget. */
  entryPageCategories?: Maybe<Array<WidgetConfigCategory>>;
  entryViewType?: Maybe<WidgetConfigEntryViewType>;
  filterEnabled?: Maybe<Scalars['Boolean']['output']>;
  fixedEndDate?: Maybe<Scalars['String']['output']>;
  fixedStartDate?: Maybe<Scalars['String']['output']>;
  freeEntryEvents?: Maybe<Scalars['Boolean']['output']>;
  freeEntryEventsEnabled?: Maybe<Scalars['Boolean']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  intervalFrom?: Maybe<Scalars['String']['output']>;
  intervalTo?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Location>;
  locationFilterEnabled?: Maybe<Scalars['Boolean']['output']>;
  locationSearchType?: Maybe<Scalars['Int']['output']>;
  locations?: Maybe<Array<AddressPoi>>;
  oneOffEvents?: Maybe<Scalars['Boolean']['output']>;
  oneOffEventsEnabled?: Maybe<Scalars['Boolean']['output']>;
  partnerClientUrl?: Maybe<Scalars['String']['output']>;
  poiGroups?: Maybe<Array<AddressPoiGroup>>;
  preselectedCategories?: Maybe<Array<Category>>;
  preselectedCoordinates?: Maybe<LatLon>;
  preselectedCriteria?: Maybe<Array<Criterion>>;
  preselectedDateType?: Maybe<Scalars['Int']['output']>;
  preselectedDayTimeType?: Maybe<Scalars['Int']['output']>;
  preselectedFixedEndDate?: Maybe<Scalars['String']['output']>;
  preselectedFixedStartDate?: Maybe<Scalars['String']['output']>;
  preselectedFreeEntryEvents?: Maybe<Scalars['Boolean']['output']>;
  preselectedIntervalFrom?: Maybe<Scalars['String']['output']>;
  preselectedIntervalTo?: Maybe<Scalars['String']['output']>;
  preselectedLocation?: Maybe<Location>;
  preselectedOneOffEvents?: Maybe<Scalars['Boolean']['output']>;
  preselectedRadius?: Maybe<Scalars['Int']['output']>;
  preselectedUpcomingWeekend?: Maybe<Scalars['Boolean']['output']>;
  radius?: Maybe<Scalars['Int']['output']>;
  region?: Maybe<Region>;
  searchCategories?: Maybe<Array<Category>>;
  searchCriteria?: Maybe<Array<Criterion>>;
  series?: Maybe<Array<EventSeries>>;
  showMapInList?: Maybe<Scalars['Boolean']['output']>;
  showSearchFilter?: Maybe<Scalars['Boolean']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<EventDateTag>>;
  type?: Maybe<Scalars['String']['output']>;
  upcomingWeekend?: Maybe<Scalars['Boolean']['output']>;
  viewTypes?: Maybe<Array<WidgetConfigViewType>>;
  whatSearchEnabled?: Maybe<Scalars['Boolean']['output']>;
  whenSearchEnabled?: Maybe<Scalars['Boolean']['output']>;
  zipcodes?: Maybe<Scalars['String']['output']>;
};


export type WidgetConfigEventDefEntryPageCategoriesArgs = {
  sortOrder?: InputMaybe<SortOrder>;
};

export type WidgetConfigPoiDef = {
  __typename?: 'WidgetConfigPoiDef';
  _entityType?: Maybe<Scalars['String']['output']>;
  addressbases?: Maybe<Array<Addressbase>>;
  categoryFilterEnabled?: Maybe<Scalars['Boolean']['output']>;
  categoryFilterProductlines?: Maybe<Array<Productline>>;
  coordinates?: Maybe<LatLon>;
  criterionFilterEnabled?: Maybe<Scalars['Boolean']['output']>;
  criterionFilterProductlines?: Maybe<Array<Productline>>;
  /** Return a list of group items (defined by productlines), which should be displayed in the poi entry page of an imx.Platform Whitelabel Widget. */
  entryPageProductlines?: Maybe<Array<WidgetConfigProductline>>;
  entryViewType?: Maybe<WidgetConfigEntryViewType>;
  filterEnabled?: Maybe<Scalars['Boolean']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  locationFilterEnabled?: Maybe<Scalars['Boolean']['output']>;
  locations?: Maybe<Array<Location>>;
  openingHoursFilterEnabled?: Maybe<Scalars['Boolean']['output']>;
  partnerClientUrl?: Maybe<Scalars['String']['output']>;
  preselectedCategoryFilteredProductlines?: Maybe<Array<Productline>>;
  preselectedCoordinates?: Maybe<LatLon>;
  preselectedCriterionFilterProductlines?: Maybe<Array<Productline>>;
  preselectedLocation?: Maybe<Location>;
  preselectedOpeningHoursType?: Maybe<Scalars['Int']['output']>;
  preselectedRadius?: Maybe<Scalars['Int']['output']>;
  productlinesAnd?: Maybe<Array<Productline>>;
  productlinesIgnore?: Maybe<Array<Productline>>;
  productlinesOr?: Maybe<Array<Productline>>;
  radius?: Maybe<Scalars['Int']['output']>;
  regions?: Maybe<Array<Region>>;
  showSearchFilter?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  viewTypes?: Maybe<Array<WidgetConfigViewType>>;
  whatSearchEnabled?: Maybe<Scalars['Boolean']['output']>;
  zipcodes?: Maybe<Scalars['String']['output']>;
};


export type WidgetConfigPoiDefEntryPageProductlinesArgs = {
  sortOrder?: InputMaybe<SortOrder>;
};

export type WidgetConfigProductline = {
  __typename?: 'WidgetConfigProductline';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  productline?: Maybe<Productline>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
};

export type WidgetConfigTourCategory = {
  __typename?: 'WidgetConfigTourCategory';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  sortingValue?: Maybe<Scalars['Int']['output']>;
  tourCategory?: Maybe<TourCategory>;
};

export type WidgetConfigTourDef = {
  __typename?: 'WidgetConfigTourDef';
  _entityType?: Maybe<Scalars['String']['output']>;
  activityFilterTourCategories?: Maybe<Array<TourCategory>>;
  addressbases?: Maybe<Array<Addressbase>>;
  attributeFilterTourAttributes?: Maybe<Array<TourAttribute>>;
  categoryFilterEnabled?: Maybe<Scalars['Boolean']['output']>;
  coordinates?: Maybe<LatLon>;
  difficultyLevelFilterEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Return a list of group items (defined by tour categories), which should be displayed in the tour entry page of an imx.Platform Whitelabel Widget. */
  entryPageTourCategories?: Maybe<Array<WidgetConfigTourCategory>>;
  entryViewType?: Maybe<WidgetConfigEntryViewType>;
  filterEnabled?: Maybe<Scalars['Boolean']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lengthDurationFilterEnabled?: Maybe<Scalars['Boolean']['output']>;
  locationFilterEnabled?: Maybe<Scalars['Boolean']['output']>;
  locations?: Maybe<Array<Location>>;
  partnerClientUrl?: Maybe<Scalars['String']['output']>;
  preselectedActivityFilterTourAttributes?: Maybe<Array<TourAttribute>>;
  preselectedCoordinates?: Maybe<LatLon>;
  preselectedDifficultyLevel?: Maybe<Scalars['Int']['output']>;
  preselectedLocation?: Maybe<Location>;
  preselectedMaxDuration?: Maybe<Scalars['Int']['output']>;
  preselectedMaxLength?: Maybe<Scalars['Float']['output']>;
  preselectedMinDuration?: Maybe<Scalars['Int']['output']>;
  preselectedMinLength?: Maybe<Scalars['Float']['output']>;
  preselectedRadius?: Maybe<Scalars['Int']['output']>;
  preselectedTourCategories?: Maybe<Array<TourCategory>>;
  productlines?: Maybe<Array<Productline>>;
  radius?: Maybe<Scalars['Int']['output']>;
  regions?: Maybe<Array<Region>>;
  showSearchFilter?: Maybe<Scalars['Boolean']['output']>;
  tourCategories?: Maybe<Array<TourCategory>>;
  type?: Maybe<Scalars['String']['output']>;
  viewTypes?: Maybe<Array<WidgetConfigViewType>>;
  whatSearchEnabled?: Maybe<Scalars['Boolean']['output']>;
  zipcodes?: Maybe<Scalars['String']['output']>;
};


export type WidgetConfigTourDefEntryPageTourCategoriesArgs = {
  sortOrder?: InputMaybe<SortOrder>;
};

export type WidgetConfigTypeDefs = WidgetConfigEventDef | WidgetConfigPoiDef | WidgetConfigTourDef;

export type WidgetConfigViewType = {
  __typename?: 'WidgetConfigViewType';
  _entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type WindCondition = {
  /** Wind direction in degrees. */
  direction?: Maybe<Scalars['Float']['output']>;
  /** Wind speed in m/s. */
  speed: Scalars['Float']['output'];
};
